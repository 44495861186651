.title {
  // padding: 110px 0 53px;
  font-size: 16px;
  line-height: 2.55;
  letter-spacing: -0.24px;
  text-align: center;
  .plans-text {
    text-align: center;
    color: var(--grey-light);
    display: block;
  }
}
.shop-plans-container {
  .plans-cart {
    background-color: var(--white);
    padding: 32px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 18px;
    height: 100%;
    &.active {
      border: solid 2px #ffd102;
    }
    .plans {
      width: 100%;
      .package-texts {
        border-bottom: 1px solid #f3f4f6;
        .packages {
          color: var(--white);
          font-size: 11px;
          font-weight: bold;
          line-height: 1.18;
          letter-spacing: 0.28px;
          font-family: var(--title);
          padding: 5px 10px;
          border-radius: 12px;
        }
        .validity-period {
          font-size: 12px;
          line-height: 1.75;
          text-align: center;
          color: #4a6cfa;
        }
        .package-price{
          font-size: 24px;
          font-weight: 900;
          line-height: 1.21;
          color: var(--black);
          margin: 0;
        }
      }
      .services {
        font-size: 14px;
        font-weight: 500;
        color: var(--blue-gray);
        padding: 30px 0 0;
        display: inline-block;
        p {
          position: relative;
          padding-bottom: 0;
          text-align: left;
          &.disabled {
            color: var(--red);
            position: relative;
            &:before {
              position: absolute;
              line-height: 1.5;
              content: "";
              background-image: url("../../../../resources/img/icons/check-mark-light.svg");
              background-repeat: no-repeat;
              background-position: center;
              padding: 8px;
              background-size: 13px;
              left: -30px;
              top: 3px;
            }
          }
          &.different {
            text-align: center;
            color: #454b57;
            line-height: 1.57;
            &:before {
              display: none;
            }
          }
          &:before {
            position: absolute;
            line-height: 1.5;
            content: "";
            background-image: url("../../../../resources/img/icons/check-mark.svg");
            background-repeat: no-repeat;
            background-position: center;
            padding: 8px;
            background-size: 13px;
            left: -30px;
            top: 3px;
          }
        }

      }
      .btn {
        &.border-2 {
          color: #ffbf00;
          border: solid 2px #ffbf008f;
          background-color: white;
        }
      }
    }
  }
}

.subscriptionInfo {
  position: absolute;
  top: 10px;
  right: 25px;
}

.subscriptionInfo {
  .tooltip-info {
    position: absolute;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
    background: $white;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 3px 6px 0px rgba(39,42,55,0.26);
    -webkit-box-shadow: 0px 3px 6px 0px rgba(39,42,55,0.26);
    -moz-box-shadow: 0px 3px 6px 0px rgba(39,42,55,0.26);
    opacity: 0;
    pointer-events: none;
    z-index: 999;
    min-width: 100px;
    &::after {
        content: '';
        width: 0; 
        height: 0; 
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid white;
        position: absolute;
        left: 48%;
        transform: translateX(-50%);
        bottom: -5px;
    }

}
&:hover {
  .tooltip-info {
    opacity: 1;
}
}
}

.package-old-price {
  position: relative;
  display: inline-flex;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    opacity: 0.25;
    border-radius: 2px;
    background-color: #272a37;
    top: 50%;
    left: -3px;
    transform: translateY(-50%);
  }
}
// .subscriptions-wrapper {
//   background-image: url('../../../img/businessClub.png');
//   background-repeat: repeat;
//   position: relative;
//   .business-info {
//     background-color: rgba($white, .35);
//     border-radius: 35px;
//     transition: .5s ease-in-out;
//     @media (max-width:767px) {
//       width: 100%;
//     }
//     &:hover {
//       background-color: rgba($white, .2);
//     }
//     &.business-info-payment {
//       background-color: rgba(#6482FA, .1);
//       &:hover {
//         background-color: rgba(#6482FA, .1);
//       }
//     }
//   }

// }