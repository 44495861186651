.table-list {
	.tr {
		border-bottom: 1px solid #f3f4f6;

		.th {
			font-weight: 500;
		}
		.product-col {
			width: 130px;
			min-width: initial;
			display: flex;
			justify-content: flex-start;
			// @media (min-width: 1600px) {
			// 	max-width: 185px;
			// }
			// @media (max-width: 767px) {
			// 	max-width: 200px;
			// }
			&.img {
				width: 162px;
			}
			.item-img {
				width: 48px;
				height: 48px;
				margin-right: 12px;
				img {
					border-radius: 12px;
				}
			}
			.item-number {
				color: #575963;
			}
		}
	}
	.tbody .tr:last-child {
		border-bottom: 0;
	}
}
.ps-border {
	border-bottom: 1px solid #e4e7ed;
}
.shop-prs-filter {
	position: relative;
	&.my-product-filter-position {
		position: initial;
		padding: 0;
	}
	@media (max-width: 767px) {
		position: absolute;
		padding: 0 12px;
		left: 0;
		right: 0;
		top: 0;
		width: 100%;
		justify-content: flex-end;
		.form-group {
			display: flex;
			justify-content: flex-end;
			width: 100%;
		}
	}
	button {
		height: 36px;
	}
	input {
		width: 36px;
		height: 36px;
		transition: 0.5s ease-in-out;
		@media (max-width: 767px) {
			transition: none;
			width: 100%;
		}
		&:hover {
			width: 200px;
			@media (max-width: 767px) {
				width: 100%;
			}
		}
		&:focus {
			width: 200px;
			@media (max-width: 767px) {
				width: 100%;
			}
		}
	}
	.filter-search-i {
		position: absolute;
		top: 50%;
		right: 15px;
		transform: translateY(-50%);
		pointer-events: none;
		transition: 0.5s ease-in-out;
	}
	input {
		transition: 0.3s ease-in-out;
		&:hover,
		&:focus {
			& ~ .filter-search-i {
				// left: calc(100% - 24px);
				// transform: translate(0%,-50%);
			}
		}
	}
}
.my-products-head {
	.sorting {
		position: relative;
		display: flex;
		cursor: pointer;
		@media (max-width: 767px) {
			padding-right: 0;
		}
		&:after {
			margin-left: 7px;
			display: flex;
			position: initial;
			content: "";
			display: block;
			width: 7px;
			height: 12px;
			background-image: url("../../../img/icons/sort-i.svg");
			background-repeat: no-repeat;
			background-position: center center;
			@media (max-width: 767px) {
				display: none;
			}
		}
		&.sorting-up:after {
			background-image: url("../../../img/icons/sort-up-i.svg");
		}
		&.sorting-down:after {
			background-image: url("../../../img/icons/sort-down-i.svg");
		}
	}
}

.shop-inner {
	.row {
		margin: 0 0 0 40px;
	}
}
.shop-top-box {
	padding: 0 0 0 17px;
	#breadcrums {
		.breadcrumb {
			padding: 0 0 6px;
		}
	}
	h1 {
		margin: 0 0 26px;

		font-size: 24px;
		font-weight: bold;
		color: #272a37;
		float: left;
	}
	.shop-actions {
		position: relative;
		float: right;
		.shop-top-btn {
			position: relative;
			float: left;
			height: 36px;
			width: 36px;
			border-radius: 10px;
			border: solid 1px #e4e7ed;
			background-color: #ffffff;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			cursor: pointer;
		}
		.shop-search {
			// background-image: url("../../../img/shop/search.svg");
			margin: 0 10px 0 0;
		}
		.shop-filters {
			// background-image: url("../../../img/shop/filters.svg");
		}
	}
}
.shop-bottom-box {
	border-radius: 16px;
	border: solid 1px #e4e7ed;
	background-color: #ffffff;
	.sl-tabs {
		nav {
			border-bottom: 1px solid #e4e7ed;
			&.nav-tabs {
				padding: 24px 18px 12px;
				.nav-link {
					border: 0;

					font-size: 12px;
					font-weight: normal;
					color: #8996ae;
					display: inline-block;
					flex: none;
					padding: 0 20px 0 0;
					position: relative;
					&.active {
						border: 0;

						font-weight: bold;
						color: #272a37;
						&:before {
							content: "";
							position: absolute;
							bottom: -14px;
							left: 0;
							width: 16px;
							height: 5px;
							border-radius: 3px;
							background-color: #f25777;
						}
					}
				}
			}
		}
		.tab-content {
			.row {
				margin: 0;
			}
			.table {
				margin: 0;
				.sl-table-container {
					border-bottom: 1px solid #f1f3f6;
					&.sl-table-header {
						vertical-align: middle;
						border-bottom: 1px solid #f1f3f6;
		
						font-size: 12px;
						letter-spacing: normal;
						font-weight: 500;
						color: #8996ae;
						.col {
							padding: 25px 5px 12px;
							&:first-child {
								padding: 25px 5px 12px 5px;
								.sg-label-checkbox {
									margin: 0 auto 10px auto;
								}
							}
						}
					}
					.sl-table-col {
						display: -ms-flexbox;
						display: -webkit-flex;
						display: flex;
						-ms-flex-align: center;
						-webkit-align-items: center;
						-webkit-box-align: center;
						align-items: center;
						padding: 15px 5px 15px;

						font-size: 14px;
						color: #575963;
						&:first-child {
							padding: 15px 5px 15px 5px;
							.sg-label-checkbox {
								margin: 0 auto 10px auto;
							}
						}
					}
					&[data-style="true"] {
						background-color: #f1f3f6;
						border-bottom: 1px solid #e4e7ed;
						.sl-table-col {
							padding: 9px 5px 10px;
							.sl-show-subs-spec {
								display: none;
							}
							.sl-title {
								margin: 0;
							}
							.sl-show-subs {
								// background-image: url('../../../img/shop/arrow-up-bold.svg');
							}
						}
					}
				}
				.sl-subs {
					.sl-table-container {
						border-bottom: 1px solid #e4e7ed;
						background-color: rgba(243, 244, 246, 0.5);
						&:first-child {
						}
					}
				}
			}
			.sl-pagination {
				padding: 28px 15px 20px;
			}
			.sl-page-filter {
				padding: 32px 15px 24px;
				text-align: center;
		
				font-size: 12px;
				text-align: center;
				color: #8996ae;
			}
		}
	}
	.sl-show-subs {
		width: 16px;
		height: 10px;
		margin-left: 25px;
		cursor: pointer;
		background-color: transparent;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: contain;
	}
	p {
		margin: 0;
		.sl-code {

			font-size: 14px;
			color: #575963;
			display: block;
		}
		.sl-id {

			font-size: 12px;
			color: #8996ae;
			display: block;
		}
	}

	.sl-title {
		float: left;
		margin: 0 16px 0 18px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		font-size: 14px;
		font-weight: bold;
		color: #272a37;
	}
	.sl-img {
		width: 48px;
		height: 48px;
		margin: 0 0;
		object-fit: contain;
		border-radius: 12px;
		overflow: hidden;
		float: left;
		img {
			width: 48px;
		}
	}
	.sl-actions {
		position: relative;
		.sl-action-list-controll {
			cursor: pointer;
			width: 36px;
			height: 36px;
			border-radius: 50%;
			// background-image: url('../../../img/shop/3-dots.svg');
			background-repeat: no-repeat;
			background-position: 50% 50%;
			&:hover {
				background-color: #f1f3f6;
			}
			&:after {
				display: none;
			}
			&:focus {
				outline: none;
				box-shadow: none;
			}
		}
		.sl-actions-list {
			padding: 11px 0px;
			border-radius: 10px;
			box-shadow: 0 3px 6px 0 rgba(39, 42, 55, 0.1);
			border: solid 1px #e4e7ed;
			background-color: #ffffff;
			a {
				padding: 5px 20px 5px 14px;

				font-size: 12px;
				color: #272a37;
				&:hover {
					text-decoration: none;
					color: #4a6cfa;
					cursor: pointer;
					background: rgba(215, 220, 228, 0.2);
				}
				&.sl-action-delete {
					color: #ff665e;
				}
			}
		}
	}
}

// ----------
.w-check {
	width: 20px;
	padding-left: 0;
	padding-right: 0;
	margin-right: 12px;
}
.w-img {
	width: 48px;
	padding-left: 0;
	padding-right: 0;
}
.w-title {
	max-width: 284px;
	@media (max-width: 767px) {
		max-width: 135px;
	}
}
.pr-title {
	max-width: 200px;
	text-overflow: ellipsis;
	overflow: hidden;
	line-height: 18px;
	display: block;
	white-space: nowrap;
}
.t-icon {
	width: 9px;
	height: 7px;
	margin-right: 8px;
}
.package {
	height: 36px;
	border-radius: 8px !important;
	padding: 0 18px 0 10px !important;
}
.pr-price-col {
	width: 100%;
	max-width: 90px;
	margin: 0 12px;
	flex-shrink: 0;
	&.pr-stock-input {
		max-width: 75px;
	}
	@media (max-width: 1200px) {
		margin: 0 8px;
	}
}

.views-col {
	width: 100%;
	max-width: 80px;
	margin: 0 12px;
	@media (max-width: 1365px) {
		max-width: 50px;
		margin: 0 8px;
	}
	@media (max-width: 767px) {
		display: none;
	}
}
// .promitions-btn {
// 	width: 100%;
// 	max-width: 110px;
// 	margin-left: 10px;
// 	margin-right: 24px;
// 	@media (max-width: 1365px) {
// 		max-width: 110px;
// 		margin: 0 12px;
// 	}
// 	.btn {
// 		&:disabled {
// 			pointer-events: none;
// 			opacity: 0.5;
// 		}
// 	}
// }
.tr {
	&.tr-padding {
		> td {
			padding-bottom: 48px;
		}
		.limit-time {
			margin-top: 20px;
			transform: translateX(-65px);
		}
	}
}
.statement-action-btn {
	min-width: 124px;
	border-radius: 18px !important;
	color: #325afa;
	border: solid 1px rgba(50, 90, 250, 0.25) !important;
}
.disabled-statement {
	display: none;
}
.filter-swal {
	animation: none !important;
	@media (min-width: 900px) {
		width: 840px;
		max-width: 840px;
	}
	max-width: 840px;
	.form-line {
		width: 12px;
		height: 2px;
		background-color: #d7dce4;
	}
	.popup-content {
		max-height: 540px;
		overflow-y: auto;

		/* width */
		&::-webkit-scrollbar {
			width: 2px;
			background: none;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			background: #ffffff;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: #d7dce4;
			width: 2px;
		}
	}
	.dropdown {
		button {
			padding: 12px !important;
		}
		.dropdown-menu {
			left: 0 !important;
		}
	}
}
.discount-swal {
	max-width: 624px;
}
.shop-prs-filter {
	@media (max-width: 767px) {
		&.active {
			.backdrop {
				pointer-events: all;
				opacity: 1;
			}
		}
	}
}
.backdrop {
	&.active {
		@media (max-width: 767px) {
			pointer-events: all;
			opacity: 1;
		}
	}
}
.react-datepicker-popper {
	z-index: 100 !important;
}
.orders-header-group {
	background-color: red;
	input {
		&::placeholder {
			font-size: 12px;
		}
	}
	.react-datepicker-wrapper {
		width: 140px;
		margin: 0;
		input {
			width: 100%;
			&::placeholder {
				font-size: 12px;
			}
		}
		&:nth-child(1) {
			margin-right: 16px;
		}
	}
	@include media-breakpoint-down(sm) {
		position: fixed;
		width: 100%;
		height: 70vh;
		overflow-y: auto;
		bottom: -100%;
		opacity: 0;
		transition: 1s;
		z-index: 999;
		display: flex;
		flex-direction: column;
		justify-content: flex-start !important;
		align-items: flex-start !important;
		border-top-left-radius: 18px !important;
		border-top-right-radius: 18px !important;
		border-bottom-left-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
		padding: 40px 30px 0 30px !important;
		&.no-transition {
			transition: none !important;
			top: 30vh;
			bottom:initial;
		}
		&.active {
			bottom: 0;
			opacity: 1;
			&:before {
				content: "";
				position: absolute;
				top: -20px;
				left: 50%;
				transform: translateX(-50%);
				width: 39px;
				height: 6px;
				border-radius: 4px;
				background-color: $white;
			}
		}
		.date-picker-box {
			width: 100%;
			.react-datepicker-wrapper {
				width: 100%;
				input {
					width: 100%;
					// border: none!important;
				}
				&:nth-child(1) {
					margin-right: 8px;
				}
			}
		}

		.filter-price {
			margin: 30px 0 0 0 !important;
		}
		.filter-cats {
			margin-top: 30px;
		}
	}
}
.pr-header-top {
	position: relative;
	@media (max-width: 767px) {
		display: flex;
	}
	.pr-header {
		@media (max-width: 767px) {
			flex-direction: column;
			align-items: flex-start !important;
			.promotion-info {
				margin-left: 0 !important;
				margin-top: 24px;
			}
		}
	}
}
.td {
	&.pr-price-col {
		@media (max-width: 767px) {
			justify-content: center !important;
			max-width: 80px;
			.item-number {
				display: flex;
				flex-direction: column;
				.divider-line {
					opacity: 0;
					margin: 4px 0;
				}
			}
		}
	}
	&.item-code {
		@media (max-width: 767px) {
			flex-direction: column;
			align-items: flex-start !important;
		}
	}
	&.sl-actions-list {
		@media (max-width: 767px) {
			// position: absolute;
			// right: 5px;
			// top: 10px;
		}
	}
	&.active-status {
		@media (max-width: 767px) {
			display: none;
		}
	}
}
.my-products-box {
	.ps-tabs {
		@media (max-width: 767px) {
			border-radius: 0 !important;
		}
	}
}
.filter-mobile-show {
	display: flex;
	@media (min-width: 768px) {
		display: none;
	}
}
.blocked-reason {
	background-color: rgba($color: #8996ae, $alpha: 0.2);
	position: relative;
	// &::after {
	// 	content: '';
	// 	position: absolute;
	// 	width: 2px;
	// 	height: 12px;
	// 	top: 50%;
	// 	left: -1px;
	// 	transform: translateY(-50%);
	// 	background-color: #FF3B30;
	// 	border-radius: 3px;
	// }
}
.free-limits {
	top: 44px;
	z-index: 100;
	left: 0;
	box-shadow: 0 2px 6px 0 rgba(39, 42, 55, 0.1);
	border: solid 1px #e4e7ed;
}
.show-more {
	top: 72px;
	z-index: 10;
	width: 100%;
	max-width: 307px;
	left: 0;
	box-shadow: 0 2px 6px 0 rgba(39, 42, 55, 0.1);
	border: solid 1px #e4e7ed;
}
.buy-pack-box {
	top: 44px;
	z-index: 10;
	width: 100%;
	left: 0;
	box-shadow: 0 2px 6px 0 rgba(39, 42, 55, 0.1);
	border: solid 1px #e4e7ed;
	min-width: 224px;
}
.sg-selectbox__menu {
	* {
		white-space: pre-wrap !important;
	}
}
.my-pr-filter {
	.sg-selectbox__control {
		height: 42px !important;
	}
}

.discount-head {
	@media (max-width: 1200px) {
		// margin-right: 24px !important;
	}
}
.my-product-old-price {
	&::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 1px;
		background-color: $blue-gray;
		top: 55%;
		left: 0;
		transform: translateY(-50%);
	}
}


