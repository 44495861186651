.radius-32 {
  border-radius: 32px!important;
}
.radius-32-3-32-32 {
  border-radius: 32px 3px 32px 32px!important;
  letter-spacing: -0.33px;
}
.right-border-0 {
  border-top-right-radius: 0!important;
  border-bottom-right-radius: 0!important;
}
.left-border-0 {
  border-top-left-radius: 0!important;
  border-bottom-left-radius: 0!important;
}

.map-img-pin {
  animation: shadow-pulse 1s infinite;
  top: 15%;
  left: 55%;
}
@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(254, 201, 0, 0.51);
    border-radius: 50%;
  }
  100% {
    box-shadow: 0 0 0 35px rgba(242, 87, 119, 0);
    border-radius: 50%;
  }
}
.captcha-box  {
  .input-group-text {
    width: 100%;
    max-width: 160px;
    border-right: none !important;
    &:focus {
      border-right: none;
    }
  }
  .captcha-img {
    border-radius: 32px;
  }
  .code-input {
    border-left: none !important;
    border-radius: 32px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    transition: .3s ease-in-out;
    &:focus {
      border-left: none;
      
    }
  }
  .input-group-text {
    transition: .3s ease-in-out;
  }
  .input-group {
    display: flex;
    flex-direction: row-reverse;
    .code-input {
      &:focus {
        & ~ .input-group-prepend  {
          
          .input-group-text {
            border-width: 1px;
            border-color: #9aadfc!important;
          }
        }
      }
    }
  }
  &.error {
    .input-group  {
      .code-input {
        border-color: #ff3b30 !important;
    
      }
      .input-group-text {
        border-color: #ff3b30 !important;
      }
    }
    .input-group {
      display: flex;
      flex-direction: row-reverse;
      .code-input {
        &:focus {
          & ~ .input-group-prepend  {
            
            .input-group-text {
              border-width: 1px;
              border-color: #ff3b30 !important;
            }
          }
        }
      }
    }
  }

}
.map-col {
  @media (min-width:1200px) {
    max-width: 478px;
    margin-left: 132px;
  }
}
.contact-information {
  ul {
    li {
      &:hover {
        color: #454b57 !important;
        span {
          color: #454b57 !important;
        }
      }
    }
  }
}