.review-content {
    .nav-tabs {
        border-bottom: 1px solid transparent;
        .nav-link {
            border-color: transparent;
            padding: 0;
            margin-right: 40px;
            color: $blue-gray !important;
            font-family: $font-family-bold-caps;
            font-size: 14px;
            font-weight: bold;
            &.active {
                color: $dark !important;
            }
        }
    }
    // button {
    //     border: solid 2px rgba(137, 150, 174, 0.1);
    // }

}
.review-list {
    background-color: rgba(137, 150, 174, 0.05);
    margin-bottom: 10px;
    .review-img {
        width: 40px;
        height: 40px;
        img {
            width: 40px;
            height: 40px;
        }
    }
    .review-list-img {
        width: 100%;
        height: 75px;
        img {
            margin-right: 4px;
            border-radius: 4px;
        }
    }
}

