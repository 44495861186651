.TextEditor-root {
    background: #fff;
    border: solid 1px #e4e7ed;
    font-family: 'Georgia', serif;
    font-size: 14px;
    padding: 15px;
    border-radius: 10px;
}

.TextEditor-editor {
    border-top: 1px solid #ddd;
    cursor: text;
    font-size: 16px;
    margin-top: 10px;
}

.TextEditor-editor .public-DraftEditorPlaceholder-root,
.TextEditor-editor .public-DraftEditor-content {
    margin: 0 -15px -15px;
    padding: 15px;
}

.TextEditor-editor .public-DraftEditor-content {
    min-height: 100px;
    max-height: 500px;
    overflow: auto;
    word-break: break-all;
}

.TextEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
}

.TextEditor-editor .TextEditor-blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: 'Hoefler Text', 'Georgia', serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
}

.TextEditor-editor .public-DraftStyleDefault-pre {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
    font-size: 16px;
    padding: 20px;
}

.TextEditor-controls {
    font-size: 14px;
    margin-bottom: 5px;
    user-select: none;
}

.TextEditor-styleButton {
    color: #999;
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
}

.TextEditor-activeButton {
    color: #5890ff;
}