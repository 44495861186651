.member-arrow {
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
}
.invite-btn {
    height: 48px;
}
.descr-box  {
    position: relative;
    .icon {
        margin-right: 12px;
        &::after {
            content: '';
            position: absolute;
            width: 2px;
            height: 12px;
            left: 22px;
            background-color: #325afa;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
.shop-member-bottom-box {

    .member {
        width: 30%;
        img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
            @media (max-width:1365px) {
                display: none;
            }
        }
       
    }
    .mail {
        color: #575963;
    }
    .member-id {
        color: #575963;
    }
    .action {
        .btn {
            background-color: rgba($color: #e4e7ed, $alpha: .5);
            border-radius: 24px;
            line-height: 18px;
            display: flex;
            align-items: center;
            height: 48px;
            &.btn-action {
                min-width: 124px;
                max-width: 124px;
                justify-content: center;
            }
        }
    }
    .item-row {
        position: relative;
        border-bottom: 1px solid #e4e7ed;
        padding-left: 32px;
        padding-right: 32px;
        @media (max-width:1365px) {
            padding-left: 8px;
            padding-right: 8px;
        }
        .remove-btn {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 24px;
        }
  
    }
    .box-footer  {
        .password-eye {
            top: 50%;
            transform: translateY(-50%);
            right: 17px;
        }
        .action {
            .btn {
                border-radius: 14px;
            }
        }
    }
}