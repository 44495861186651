.custom-select {
  border: none;
  .custom-selectbox__control  {
    height: 100%;
    border: 1px solid  $gray!important;
    border-radius: 8px;
    &:hover {
      border-color: $gray;
    }
    box-shadow: none !important;
  }
  .custom-selectbox__indicators {
    .custom-selectbox__indicator-separator {
      display: none;
    }
    .custom-selectbox__indicator  {
      display: none;
    }
    &::after {
      content: "";
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      background-image: url('../../../img/icons/drp-arrow.svg');
      width: 24px;
      height: 24px;
      z-index: 30;
    }
  }
  &  > * {
    font-family: 'TBCXMedium';
  }
  .custom-selectbox__menu  {
    z-index: 60;
    
  }
  .custom-selectbox__value-container {
    cursor: pointer;
    padding:0 12px;
    height: 100%;
  }
  .custom-selectbox__option {
    cursor: pointer;
    color: $greymiddle;
  }
  .custom-selectbox__control--menu-is-open {
    .custom-selectbox__indicators  {
      &::after {
        transform: translateY(-50%) rotateX(-180deg);
      }
    }
  }
  .custom-selectbox__single-value {
    color: $greymiddle;
  }
}

// search
.my-product-seacrh {
  border-color:  $gray !important;
  &::placeholder {
    font-family: 'TBCXMedium';
    color: $greymiddle;
    font-size: 14px;
  }
  &:active {
    border-color: $gray  !important;
  }
}

// picker
.range-picker  {
  .range-picker-arrow {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }
  .react-datepicker__tab-loop {
    & ~ .range-picker-arrow {
      transform: translateY(-50%) rotateX(-180deg);
    }
   }

}
.custom-selectbox__menu-list {
  &::-webkit-scrollbar {
    width: 3px;
    height: 48px;
    display:block;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d7dce4;
    border-radius: 6px;
  }
}
