.green-icon {
  svg {
    circle {
      fill: $dark-green;
    }

  }
}
.yellow-icon {
  svg {
    path {
      fill: $primary;
    }
  }
}

.timeleft {
  .last-child-margin {
    &:last-child {
      margin-right: 0 !important;
    }
  }
}
.my-produqts-mobile-filter {

  bottom: -100%;
  &.active {
    bottom: 0;
  }
  &::after {
    content: "";
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 39px;
    height: 6px;
    border-radius: 4px;
    background-color: #ffffff;
    pointer-events: none;
  }
  .my-produqts-mobile-filter-inner {
    height: 50vh;
  }
}
.border-radius-lg-desk {
  @media (max-width:767px) {
    border-radius: 0 !important;
    border: none !important;
  }   
}
.my-products-box {
  .custom-checkbox-2 .custom-control-input:checked ~ .custom-control-label {
    &::before {
      border-color: $blue;
      background-color: $blue;
    }
  }
  .custom-control-input:focus ~ .custom-control-label {
    &::before {
      border-color: $blue !important;
    }
  }
  .custom-control-input:focus:not(:checked)~.custom-control-label {
    &::before {
      border-color: #e4e7ed!important;
    }
  }
}
 
.date-sorting-hover {
	.date-sorting-box {
		position: absolute;
		width: 200px;
		top: 15px;
		right: 0;
		border: 1px solid rgba(226, 229, 235, 0.5);
		box-shadow: 0px 19px 38px -15px rgba(122, 130, 144, 0.15);
		border-radius: 10px;
		z-index: 10;
		opacity: 0;
		pointer-events: none;
		.date-sorting-item {
			border-radius: 8px;
			transition: 0.3s ease-in-out;
			&:hover {
				background-color: #f3f5f7;
			}
		}
	}
	&:hover {
		.date-sorting-box {
			opacity: 1;
			pointer-events: all;
		}
	}
}
.react-datepicker__day{
  &.react-datepicker__day--in-range  {
    background-color: $dark-gray;
  }
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,.react-datepicker__month-text--in-range,.react-datepicker__quarter-text--in-range,.react-datepicker__year-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,.react-datepicker__month-text--in-range,.react-datepicker__quarter-text--in-range,.react-datepicker__year-text--in-range), .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,.react-datepicker__month-text--in-range,.react-datepicker__quarter-text--in-range,.react-datepicker__year-text--in-range), .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,.react-datepicker__month-text--in-range,.react-datepicker__quarter-text--in-range,.react-datepicker__year-text--in-range) {
  background-color: $dark-gray !important;
}
.desk-border-none {
  @media (min-width:768px) {
    border: none !important;
  }
}