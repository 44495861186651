.app-modal {
    height: 100vh;
    z-index: 9999999999999999999;
    .store-img {
        object-fit: contain;
       
    }
  .app-modal-wrapper {
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .app-img {
    img {
        width: 310px;
        height: 380px;
        @media (max-width:360px) {
            height: 300px;
        }
    }
  }
}
