.payment-cards-platforms {
  position: relative;
}

.payment-cards-logos {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
}

@media (hover: hover) {
  .payment-cards-platforms .payment-cards-text:hover + .payment-cards-logos {
    opacity: 1;
    pointer-events: unset;
  }
}

