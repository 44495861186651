.notification {
    .notification-tabs { 
        .nav-tabs {
            &::after {
                position: absolute;
                content: '';
                // height: 1px;
                width: 100%;
                left: 0;
                right: 0;
                bottom: 1px;
                background-color: #e4e7ed;
            }
            .nav-item {
                color: #8996ae;
                margin: 0;
                border: none;
                display: flex;
                position: relative;
                &::after {
                    content: '';
                    width: 16px;
                    height: 5px;
                    border-radius: 3px;
                    background-color: #ffd102;
                    display: block;
                    position: absolute;
                    bottom: -3px;
                    left: 0;
                    transform: scaleX(0);
                    transform-origin: left;
                    transition: .3s ease-in-out;
                }
                &.active {
                    color: #272a37;
                    background: transparent;
                    &::after {
                        transform: scaleX(1);
                    }
                }
            }
        }
        .tab-content {
            > ul {
                li {
                    padding: 0;
                    border-bottom: 1px solid #f3f4f6; 
                    &:last-child {
                        border: 0;
                    }
                    .notification-box {
                        
                        .icon-cont {
                            width: 48px;
                            height: 48px;
                            padding: 12px; 
                            position: relative;
                            &.news {
                                background-color: #ebfaed;
                                svg {
                                    width: 16px;
                                    path {
                                        stroke: #0ec604;
                                    }
                                }
                            }
                            &.danger {
                                background-color: #ffe9e8;
                                svg {
                                    width: 16px;
                                    path{
                                        stroke: $danger;
                                    }
                                }
                            }
                            &.prohibition {
                                background-color: #fff5e5;
                                svg {
                                    width: 16px;
                                    path {
                                        stroke: $orange;
                                    }
                                }
                            }
                        }
                        .notification-text {
                            color: #8996ae;
                            .btn {
                                &:hover {
                                    border-color: #4a6cfa!important;
                                    background-color: #eaeefe!important;
                                }
                            }
                        }
                        .notification-date {
                            color: #8996ae;
                        }
                        &.unread {
                            .icon-cont:before {
                                content: '';
                                position: absolute;
                                top: 0;
                                right: 0;
                                width: 14px;
                                height: 14px;
                                border-radius: 25px;
                                border: 2px solid white;
                                background-color: #f25777;
                                display: none
                            }
                            .notification-text {
                                color: #272a37;
                                font-weight: bold;
                            }
                            .notification-date {
                                color: #4a6cfa;
                            }
                        }
               
                    }
                }
            }
        }
    }
}
.notification-header  {
    border-bottom: 1px solid #e4e7ed;
}
.notification-hv {
    color: inherit;

    &:hover {
        background-color: #f9f9fa;
    }
}
.notification-item {
    &:last-child {
        border: none;
    }
}
.icon-cont {
    width: 48px;
    height: 48px;
    padding: 12px; 
    &.news {
        background-color: #ebfaed;
        svg {
            width: 16px;
            path {
                stroke: #0ec604;
            }
        }
    }
    &.danger {
        background-color: #ffe9e8;
        svg {
            width: 16px;
            path{
                stroke: $danger;
            }
        }
    }
    &.prohibition {
        background-color: #fff5e5;
        svg {
            width: 16px;
            path {
                stroke: $orange;
            }
        }
    }
}
.back-notification {
    transition: .3s ease-in-out;
    span {
        line-height: 21px;
    }
    &:hover {
        transform: translateX(-4px);
    }
}
.notification-box {
    &.unread {
        .icon-cont  {
            &::after {
                content: '';
                position: absolute;
                width: 16px;
                height: 16px;
                top: 2px;
                right: 0px;
                background-color: #ff641f;
                border: 4px solid #ffff;
                border-radius: 50%;
            }
        }
    
    }
}
