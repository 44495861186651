.header-cats {
    ul {
        li {
            white-space: nowrap;
            display: flex;
            justify-content: center;
            @media (min-width:1200px) {
                width: auto !important;
            }
            @media (max-width:1023px) {
                // justify-content: flex-start;
                // width: auto!important;
                // margin-right: 12px;
            }
            &:last-child {
                margin-left: 0!important;
                display: flex;
                // justify-content: flex-end;
                // position: absolute;
                // right: 15px;
                @media (max-width:767px) {
                    margin-left: 12px!important;
                }
            }
            a {
                transition: .3s ease-in-out;
                &:hover {
                    color: #272a37!important;
                }
            }
        }
    }

    @media (max-width: 1365px) {
        flex: 1;
        &.header-cats-shop {
            width: 100% !important;
        }
    }

}
.header-cats-sales-btn-online {
    color: #5386E4 !important;
    background-color: #EDF3FC ;
    border-radius: 32px !important;
    transition: .3s ease-in-out;
    span {
        line-height: 12px;
    }
    &:hover {
        background-color: rgba($color: #5386E4, $alpha: .2);
    }
}
.header-cats-sales-btn {
    color: #DC3545!important;
    position: relative;
    background-color:#FCEDEE;
    border-radius: 32px !important;
    transition: .3s ease-in-out;
    span {
        line-height: 12px;
    }
&:hover {
    background-color: rgba($color: #DC3545, $alpha: .2);
}
}
.header-cats-container {
    background-color: #ffffff;
    height: 58px;
    display: flex;
    align-items: center;
    @media (min-width:1025px) {
      height: 58px;
    }
    @media (max-width:1024px) {
        // background-color: transparent;

    }
    .swiper-header-cats {
        height: 80px;
        display: flex;
        align-items: center;
        @media (min-width:1025px) {
          height: 58px;
        }
        li {
            display: flex;
            align-items: center;

        }
    }
}
.main-slider-row {
    @media (max-width:767px) {
        margin: 0 ;
    }
}

.main-section {
    height: 620px;
    @media (max-width:767px) {
      height: auto;
    }
    .main-slider-btn {
        @media (max-width:767px) {
            display: none;
          }
    }
        .filter-block {
            position: relative;
            z-index: 9999;
            width: 320px;
            height: 620px;
            background-color: #ffffff;
            border-radius: 16px;
            // transition: .6s cubic-bezier(0.77, 0, 0.175, 1);
            position: absolute;

        .primary-cats {
            position: relative;
            width: 320px;
            overflow-y: auto;
            flex-shrink: 0;
            background:
            /* Shadow Cover TOP */
            linear-gradient(
              white 30%,
              rgba(255, 255, 255, 0)
            ) center top,
            
            /* Shadow Cover BOTTOM */
            linear-gradient(
              rgba(255, 255, 255, 0), 
              white 70%
            ) center bottom,
            
            /* Shadow TOP */
            radial-gradient(
              farthest-side at 50% 0,
              rgba(0, 0, 0, 0.1),
              rgba(0, 0, 0, 0)
            ) center top,
            
            /* Shadow BOTTOM */
            radial-gradient(
              farthest-side at 50% 100%,
              rgba(0, 0, 0, 0.1),
              rgba(0, 0, 0, 0)
            ) center bottom;
          
          background-repeat: no-repeat;
          background-size: 100% 20px, 100% 7px, 100% 14px, 100% 7px;
          background-attachment: local, local, scroll, scroll;
            &::-webkit-scrollbar {
                width: 6px;
                background: none;
                border-radius: 16px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: transparent;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: transparent;
                width:6px;
                border-radius: 16px;
            }
            &:hover {
                &::-webkit-scrollbar-thumb {
                    background: #d7dce4;
                }
            }
            &::after {
                content: '';
                position: absolute;
                right: 0;
                height: 620px;
                top: 0;
                width: 2px;
                transform-origin: top;
                transform: scaleY(0);
                background-color: #f1f3f6;
            }
            &:hover {
                &::after {
                    opacity: 0;
                }
            }
            li {
                a {
                    color: #454B57;
                    line-height: 20px;
                    text-decoration: none;
                    &:hover {
                        color: #272a37;
                        text-decoration: none;
                    }
                    & > div {
                        white-space: nowrap;
                    }
                }
                i {
                    opacity: 0;
                    
                }
                &.active {
                    border-radius: 12px;
                    background: #F2F3F6;
                    i {
                        opacity: 1;
                    }
                }
                &:hover{
                    border-radius: 12px;
                    background: #F2F3F6;
                    i {
                        opacity: 1;
                    }
                 
                }
            }
            .primary-cats-text {
                line-height: 16px;
            }
        }
        &.active {
            width: 100%;
            .primary-cats {
                width: 320px;
                &::after {
                    transform: scaleY(1);

                }
            }
        }
        &.scaleY {
            .primary-cats {
                // width: 100%;
                // max-width: 320px;
                &::after {
                    transform: scaleY(0);

                }
            }
        }
        &.closed {
            width: auto;
            padding: 30px 8px 12px 0;
            transition: none;
            .filter-icon  {
                margin-right: 0!important;
            }
            .primary-cats {
                width: auto;
                &::after {
                    display: none;
                }
                li {
                    // pointer-events: none;
                    justify-content: center!important;
                }
            }
            .cats-block {
                display: none!important;
                li {
                    display: none!important;
                }
            }
        }
        .filter-icon {
            // height: 20.8px;
            // transform: translateY(-1px);
            display: flex;
            align-items: center;

        }
        .close-filter {
            width: 32px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 12px;
            i {
                flex-shrink: 0;
            }
            &.rotate-arrow {
                i {
                  transform: rotate(180deg);
                }
            }
        }
    @media (max-width:1023px) {
        display: none!important;
    }
    }
ul {
    li {
        cursor: pointer;
        &.active {
         a {
            & > div {
                white-space: nowrap;
            }
         }
            svg {
                path {
                    &:last-child {
                        fill: #272a37;
                    }
                }
                circle {
                    fill: #272a37;
                }
                &.rent-svg {
                    path {
                        fill: #272a37;
                    }
                }
                &.construction {
                    path {
                        fill: #272a37;
                    }
                }
                &.agriculture {
                    path {
                        fill: #272a37;
                    }
                }
                &.sport {
                    path {
                        fill: #272a37;
                    }
                }
                &.book {
                    path {
                        fill: #272a37;
                    }
                }
                &.svg-1069 {
                  path {
                    fill: #272a37;
                    opacity: 1;
                  }
                g {
                    g {
                        rect {
                            fill: #272a37;
                        }
                    }
                }
                }
            }
        }
    }

}
    .slider-block {
        background: #ffffff;
        width: calc(100% - 344px);
        height: 100%;
        margin-left: auto;
        border-radius: 16px;
        // transition: .5s ease-out;
        @media (max-width:1023px) {
            width: 100%!important;
        }
        @media (max-width:767px) {
            height: auto;
            background-color: transparent;
        }
        &.full-width {
            width: calc(100% - 104px);
            .slider-cat-box {

                .cat-box {
                    //
              }
            }
            .mask-div {
                // transform: scaleY(0)!important;
                // z-index: 100!important;;
            }
        }



        // slider
.main-slider {
    @media (min-width:768px) and (max-width:1199px) {
        height: 320px;
    }
    width: 100%;
    position: relative;
    @media (max-width:767px) {
        // height: 180px;
        // background-color: #ffffff;
        border-radius: 20px;
    }

    .swiper-container {
        height: 100%;
        .swiper-slide {
            height: 100%;
            @media (max-width:1199px) {
                background-color:#f2f4f7;
            }

            a {

                position: relative;
                @media (min-width:1200px) {
                    display: block;
                    padding-top: 37%;
                }
                @media (max-width:767px) {
                    display: block;
                    padding-top:52%;

                }
                img {

                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    &.contain {
                        object-fit: contain !important;
                    }
                    @media (max-width:767px) {
                        position: absolute;
                        top: 0;
                        letter-spacing: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    @media (min-width:1200px) {
                        position: absolute;
                        top: 0;
                        letter-spacing: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

        }
    }
    .main-slider-btn  {
        opacity: 0;
        transform:translateY(-50%) scale(0.95);
        transition: .5s ease-in-out;
    }
&:hover {
    .main-slider-btn  {
        opacity: 1;
        transform:translateY(-50%) scale(1);
        &:hover {
            background-color:#e6e6e6;
            transform:translateY(-50%) scale(1.05);
        }
    }
}
}
.slider-cat-box {
    background-color: #f2f4f7;
    border-radius: 14px;
    .cat-box {
        border-radius: 8px;
        width: 100%;
        // min-width: 186px;
        min-height: 96px;
        height: 100%;

    }
}
.main-slider-btn {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%!important;
    background-color: #ffffff;
    box-shadow: 0 2px 6px 0 rgba(39, 42, 55, 0.1);
    position: absolute;
    top: 50%;
    z-index: 50;
    transform: translateY(-50%);
    left: 32px;

    &.right-btn {
        right: 32px;
        left: inherit;
    }
}
    }
}
.main-slider-btn {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%!important;
    background-color: #ffffff;
    box-shadow: 0 2px 6px 0 rgba(39, 42, 55, 0.1);
    position: absolute;
    top: 50%;
    z-index: 50;
    transform: translateY(-50%);
    left: 32px;
    @media(max-width: 767px) {
        width: 32px;
        height: 32px;
    }
    &.right-btn {
        right: 32px;
        left: inherit;
    }
    &.seacrh-slider-btn {
        position: initial;
        top: 0;
        transform: none;
    }
    &:disabled {
        opacity: 1!important;
        i {
            opacity: .15;
        }
    }
}
.continue-seacrh-slider {
    .swiper-slide {
        .slide-box {
            height: 80px;
        }

    }
    .sl-seacrh-icon  {
        transition: .3s ease-in-out;
        border: 1px solid transparent;
        border-radius: 50%;
        &:hover {
            transform: scale(1.1);
            // border-color: $market-color;
        }
    }
}
.slider-header {
    @media (max-width:767px) {
        display: flex;
        align-items: flex-start!important;
        &.no-tabs-header {
            align-items: center!important;
        }
    }
    .slider-tabs  {
        @media (max-width:767px) {
            display: flex;
            margin-top: 20px;

        }
    }
    &.slider-header-continuesearch {
        @media (min-width:768px) {
            height: 40px;
        }
    }
}

.sl-top  {
    &.sl-tabs  {
        overflow: hidden;
        @media (max-width:1023px)  {
            margin-right: 12px;
        }
        @media (max-width:767px) {
            display: flex;
            flex-direction: column;
            align-items: flex-start!important;
            margin-right: 0;
            h2 {
                margin-bottom: 10px;
            }
            &.no-tabs {
                flex-direction: row;
                align-items: center!important;
                h2 {
                    margin-bottom: 0;
                }
            }
        }

    }

    .btn {
        border-radius: 20px !important;
        background-color: rgba($color: #8996ae, $alpha: .1);
        transition: .1s ease-in-out;
        padding-top: 7px!important;
        padding-bottom: 7px!important;
        font-family: $font-family-bold;
        &.active {
            background-color: #ffffff;
        }

    }
    .all-pr {
        position: relative;
        padding-left: 10px;
        display: flex;
        &::after {
            content: '';
            position: absolute;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            background-color: #8996ae;
        }
    }
    h2 {
      flex-shrink: 0;
    }
}
.sl-save-btn {
    position: absolute;
    z-index: 9999;
    right: 20px;
}
.sec-banner {
    .banner-cont {
        width: 100%;
        height: 128px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        overflow: hidden;
        @media (max-width:767px) {
            flex: 0 0 100%;
            margin: 0 auto;
            // height: 72px;
        }
        .banner-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 100%;
            height: 100%;
            overflow: hidden;
            object-fit: contain;
            border-radius: 14px;
            // background-color: rgba(137, 150, 174, 0);
            a  {
                display: flex;
                align-self: center;
                height: 100%;
                overflow: hidden;
            }
            img {
                width: 100%;
                height: 100%;
                // border-radius: 14px;
                object-fit: contain;
                @media (max-width:1023px) {
                    border-radius: 6px;
                }
            }
        }
    }
}
// product slider
.slider-card {

    overflow: hidden;
    .sl-card-img {
        background-color: #e4e7ed;
        border-radius: 12px;
        height: 210px;
        margin-bottom: 18px;
        overflow: hidden;
        &.shop-card-img {
            height: 100%;
            margin-bottom: 0;
        }
        img {
            object-fit: cover;
        }
        @media (max-width:1200px) {
            height:150px;
        }
        @media (max-width:1024px) {
            height:210px;
        }
        @media (max-width:576px) {
            height:120px;
        }
    }
    &.shop-card {
        height: 100%;
        .card-info {
            // position: absolute;
            top: 0;
            z-index: 999;
            background: #ffffff;;
            width: 100%;
            height: 100%;
            padding-top: 64px;
            // opacity: 0;
            transition: .3s ease-in-out;
            & > div {
                margin-left: 64px;

                &.shop-slide-price {
                    color: #272a37 !important;
                    margin-top: 80px;
                }
                &.sl-card-price-old  {
                    position: relative;
                    display: inline-block;
                    margin-top: 12px;
                    &::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 2px;
                        background-color: #8996AE;
                        top: 50%;
                        transform: translate3d(0,-50%,0);
                        left: 0;
                    }
                }

            }

        }
        .offers-sale {
            position: absolute;
            line-height: 14px;
            padding: 4px 7px;
            border-radius: 11px;
        }
        &:hover {
            .card-info {
                opacity: 1;
            }
        }
    }
}
// vip-product
.vip-product  {
    .slider-card {
        width: 100%;
        overflow: hidden;
        display: inline-block;
        .sl-card-img {
            background-color: transparent;
            border-radius: 10px;
            height: 130px;
            margin-bottom: 18px;
            overflow: hidden;
            img {
                object-fit: cover;
            }
        }
        a {
            width: 100%;
        }
    }
    .vip-box {
        background-color: #ffffff;
        border-radius: 16px;
        padding: 40px 32px;
        @media (max-width:1023px) {
            padding:28px 28px;
        }
        @media (max-width:767px) {
            padding:24px 12px;
        }
        // &:nth-child(1) {
        //     margin-right: 16px;
        // }
        .vip-card {
            &:last-child {
                margin-right: 0!important;
            }
        }
    }
}
.vip-box-header  {
    .vip {
        border-radius: 11px;
        padding: 0 7px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.super-vip {
            background-color: #fec900;
        }
        &.vip-plus {
            background-color: #ff641f;
        }
    }
    .control-btn {
        .btn {
            background-color: $white;
             border-color: rgba(137, 150, 174, 0.3);
        }
    }
}
.secondary-sec {
    .sec-cont {
        background-color: #ffffff;
        width: 100%;
        border-radius: 24px;
        overflow: hidden;
        position: relative;
        justify-content:space-between;
        @media (max-width:767px) {
            flex: 0 0 100%;
            max-width: calc(100% - 30px);
            margin: 0 auto;
        }
        .left-info {
            // width: 50%;
            // max-width: 516px;
            padding: 80px 0 56px 94px;
            @media (max-width:1365px) {
                padding-left: 48px;
              }
            @media (max-width:1023px) {
              padding-left: 28px;
              padding-top: 56px;
            }
            @media (max-width:767px) {
                padding:24px
              }
        }
        .img-box {
            padding-right: 116px;
            display: flex;
            align-items: center;
            overflow: hidden;
            @media (max-width:1023px) {
                padding-right: 28px;
              }
            img {
                height: 320px;
                object-fit: contain;
                @media (max-width:767px) {
                   width: 100%;
                  }
                  &.shops-block-img {
                      @media (min-width:768px) {
                          width: 320px;
                      }
                      @media (min-width:1000px) {
                        width: 400px;
                    }
                      @media (min-width:1200px) {
                        width: 100%;
                    }
                  }
            }
        }
    }

}
.category-sec {
    .cat-link {
        &:last-child {
            margin-right: 0!important;
        }
    }
    .cat-item {
        // margin-right:34px;
        .cat-img {
            width: 184px;
            height: 184px;
            border-radius: 50%;
            background-color: #ffffff;
            overflow: hidden;
            transition: .2s ease-in-out;
            @media (max-width:767px) {
                width: 124px;
                height: 124px;
            }
            img {
                width: 104px;
                height: 104px;
            }

        }
        &:last-child {
            // margin-right: 0;
        }
        &:hover {
            .cat-img {
                background-color: #fec900;
            }
        }
    }

}
// shop slider
.shop-slider  {
    .swiper-slide {
        cursor: pointer;
        .shop-box {
            width: 118px;
            height: 118px;
            border-radius: 50%;
            position: relative;
            background-color: #ffffff;
            cursor: pointer;
            transition: .5s ease-in-out;
            @media (max-width:767px) {
                width: 84px;
                height: 84px;
            }
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: contain;
            }
            &::after {
                content: '';
                position: absolute;
                border: 2px solid #8996AE;
                width: 130px;
                height: 130px;
                border-radius: 50%;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                opacity: 0;
                transition: .5s ease-in-out;
                @media (max-width:1023px) {
                    display: none;
                }

            }

        }
        &:hover  {
            .shop-box {
             &::after {
                 opacity: 1;
                }
                transform: scale(.90);
                @media (max-width:1023px) {
                   transform: none;
                }
            }

          }
    }

}

// services-slider 
.services-slider-sec {
    .services-slider {
     height: 100%;
        .swiper-slide {
            background-color: #ffffff;
            border-radius: 16px;
            padding: 16px;
            .slider-card {
                display: flex;
                align-items: flex-end;
                // height: 128px;
                overflow: hidden;
                border-radius: 14px;
                .sl-card-img {
                    background-color: transparent !important;
                    border-radius: 0;
                    height: 100%;
                    margin-bottom: 18px;
                    overflow: hidden;
                    margin-bottom: 0;
                    border-radius: 14px;
                    img {
                        width: 96px!important;
                        height: 96px!important;
                        border-radius: 14px;

                    }
                }
            .card-info {
               margin-left: 16px;
               .cat-name {
                   line-height: 18px;
               }
               .sl-card-name  {
                  line-height: 18px;
               }
            }
            }
        }
    }
}

.sale-slide-box  {
    background-color: #ffffff;
    border-radius: 16px;
    padding: 12px;
    .sale-card {
        // width: 168px;
        //height: 120px;
        //border-radius: 10px;
        overflow: hidden;
        cursor: pointer;
        @media (max-width:1023px) {
            height: 100%;
            .sale-card  {
                img {
                    height: 110px;
                }
            }
        }
        .sale-hover-info {
            position: absolute;
            background-color: rgba($color: #272a37, $alpha: 0.95);
            border-radius: 10px;
            width: 100%;
            height: 100%;
            bottom: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            opacity: 0;
            transform: translate3d(0,100%,0);
            transition: .3s ease-in-out;
            @media (max-width:1023px) {
                transform: none;
                opacity: 1;
                position: initial;
                background-color: transparent;
                height: auto;
            }

        }
        &:hover {
            .sale-hover-info {
                opacity: 1;
                transform: translate3d(0,0,0);
            }
        }
        .sl-card-price-old  {
            position: relative;
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: #ffffff;
                top: 50%;
                transform: translate3d(0,-50%,0);
                left: 0;
                @media (max-width:1023px)  {
                    background-color: #8996AE;
                }
            }
            @media (max-width:1023px)  {
                color: #8996AE!important;
            }

        }
        .sl-card-price {
            @media (max-width:1023px)  {
               margin-top: 10px;
            }
        }
        .slider-card   {
            .sale-card-img {
                // padding: 4px;
                border-radius: 10px;
                border: 1px solid #F4F4F4;
            }
            a {

                @media (max-width:1023px) {
                    display: flex;
                    flex-direction: column;
                    // padding: 8px;

                    img {
                        border-radius: 10px;
                    }
                }
            }
        }

    }
.slider-card   {
    img {
        object-fit: cover;
    }
}
}
.category-sale {
    .percent {
        line-height: 14px;
        padding: 4px 7px;
        border-radius: 11px;
    }
}
.step-box  {
    max-width: 192px;
    text-align: center;
    overflow: hidden;
    margin-top: 16px;
    @media (max-width:768px) {
        margin-bottom: 16px;
    }
}
.cats-block-content {
    display: grid;
}
.cat-backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($black, .2);
    z-index: 8000;
    transition: .3s ease-in-out;
}
.cats-block  {
    max-height: 620px;
    overflow-y: auto;
    /* width */
	&::-webkit-scrollbar {
		width: 6px;
		background: none;
        border-radius: 16px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: transparent;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: transparent;
		width:6px;
        border-radius: 16px;
	}
    &:hover {
        &::-webkit-scrollbar-thumb {
            background: #d7dce4;
        }
    }
}
//
.child-cats {
    div {
        display: none;
        &:nth-child(1) {
            display: block;
        }
        &:nth-child(2) {
            display: block;
        }
        &:nth-child(3) {
            display: block;
        }
        &:nth-child(4) {
            display: block;
        }
        &:nth-child(5) {
            display: block;
        }
        &:last-child {
            display: block;
        }
    }
 }
 .column-count-3 {
    column-count: 3;
 }
.product-outer-container {
    @media (max-width:1023px) {
        display: block!important;
    }
}
.left-slider-block {
    width: 408px;
    border-radius: 16px;
    overflow: hidden;
    height: 404px;
    position: relative;
    @media (max-width:1023px) {
        width: 100%;
        height: 335px;
        margin-top: 24px;
    }
    .swiper-container {
        height: 404px;
        @media (max-width:1023px) {
           height: auto;
        }
    }
    .slider-offers {
        width: 100%;
        height: 404px;
        position: relative;
        border-radius: 16px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #4a6cfa;
        @media (max-width:1023px) {
            width: 100%;
            height: 335px;
        }
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
            transition: 1s ease-out;
        }
        .cat-info {
            position: absolute;
            top: 42px;
            left: 46px;
            z-index: 1;
            .cat-descr {
                max-width: 297px;
            }
        }
        &.swiper-slide-active {
            // opacity: 1!important;
            img {
                transform: scale(1);
            }
        }
    }
    .control-btn {
        position: absolute;
        left: 46px;
        bottom: 30px;
        .offer-btn {
            width: 62px;
            height: 62px;
        }
    }
}

.right-slide-block {
    @media (min-width:1024px) and (max-width:1199px)  {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    @media (max-width:940px) {
        margin-left: 0!important;
     }
    .offers-static-slide {
        width: calc(50% - 12px);
        max-width: 408px;
        height: 192px;
        margin-bottom: 20px;
        background-color: white;
        border-radius: 16px;
        overflow: hidden;
        @media (min-width:1024px) and (max-width:1199px) {
            margin: 0 !important;
            &:nth-child(3) {
                display: none;
            }
            &:nth-child(4) {
                display: none;
            }
        }
        &:nth-child(3) {
            margin-bottom: 0;
        }
        &:nth-child(4) {
            margin-bottom: 0;
        }
        @media (max-width:1199px) {
            width:100%;
            max-width: 100%;
            margin: 12px 0;
        }
        @media (max-width:1023px) {
            width: calc(50% - 8px);
            margin-top: 12px!important;
            margin-bottom: 0!important;
        }
        @media (max-width:940px) {
         margin: 12px 0;
           }

        @media (max-width:767px) {
            width: 100%;
            height: 120px;
            margin-top: 12px!important;
            margin-bottom: 0!important;
        }
        .sl-card-img {
            height: 160px;
            // background-color: white;
            margin-right: 38px;
            margin-top: auto;
            margin-bottom: auto;
            @media (max-width:767px) {
                height: 80px;
            }
            img {
                width: 160px!important;
                height: 160px!important;
                @media (max-width:767px) {
                    width: 80px!important;
                     height: 80px!important;
                }
            }
        }
        .slider-card {
            display: flex;
            padding: 13px 26px 13px 13px;
            .card-info {
                margin-top: 12px;
                position: relative;
                @media (max-width:767px) {
                   margin-top: 36px;
                }
              .sl-card-price  {
                  font-size: 18px!important;
                  color: #454B57!important;
                  @media (max-width:767px) {
                   display: inline-flex;
                   margin-right: 12px;
                }
                  &.sl-card-price-old  {
                    font-size: 14px!important;
                    color: #8996AE!important;
                    font-family: $font-family-medium!important;
                  }
              }
              .percent-box {
                // position: absolute;
                // top: -31px;
                margin-bottom: 8px;
                .offers-sale {
                    line-height: 14px;
                    padding: 4px 7px;
                    border-radius: 11px;
                }
              }
            }
            .sl-card-name  {
                @media (max-width:767px) {
                    -webkit-line-clamp: 1;
                }

            }
        }

    }
    .sl-card-price-old  {
        position: relative;
        display: inline-block;

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: #8996AE;
            top: 50%;
            transform: translate3d(0,-50%,0);
            left: 0;
        }
    }
    .all-product {
        width: 192px;
        height: 192px;
        border-radius: 16px;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media (max-width:1365px) {
            width: calc(50% - 24px);
            max-width: 100%;
            margin: 12px;
        }
        @media (max-width:1200px) {
            width: 100%;
            max-width: 100%;
            margin-top: 0;
        }
        @media (max-width:940px) {
            margin: 12px 0;
        }
    }

}
.slider-card {
    &:hover {
        .sl-card-name  {
            color: #325afa!important;
            text-decoration: underline;
        }
        .sl-card-price {
            color: #325afa!important;
        }
        .sale-hover-info {
            .sl-card-price  {
                color: #0ec604 !important;
            }
            .sl-card-price-old {
                color: #ffffff !important;
            }

        }
    }
}
.home-placeholder-cards {
    // height: 280px!important;
    .card-placeholder  {
        height: 280px!important;

    }
  .placeholder-col  {
    height: 280px!important;
  }
}
.shop-sales-static-slide {
    .sl-card-price {
        text-decoration: none!important;
    }
}
.right-slide-block  {
    .sl-card-price {
        text-decoration: none!important;
    }
}

.fav-hr {
    width: 48px;
    height: 2px;
    background-color: #8996ae;
    opacity: 0.25;
    border: 0;
}
.tab-btn {
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
        background-color: $white!important;
        //opacity: 0.65;
    }
}
//
.home-categories  {
    position: fixed;
    opacity: 0;
    pointer-events: none;
    @media (max-width:1023px) {
        top: 100%;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 999;
        transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
        .cats-icon {
            svg {
                width: 20px;
                height: 20px;
            }
        }
        .search-filter-body {
            height: calc(100vh - 160px);
            overflow-y: auto;
        }
        .home-cats-item-text {
            // padding-bottom: 24px;
            // border-bottom: 1px solid #F1F3F6;

        }
        .cats-item {
         &:last-child {
            .home-cats-item-text {
                border-bottom: 1px solid transparent;
            }
         }
         &.border-none {
             &::after {
                 display: none;
             }
         }
         position: relative;
         &::after {
             content: '';
             position: absolute;
             width: calc(100% - 70px);
             right: 20px;
             height: 1px;
             bottom: 0;
             background-color: #F1F3F6;
         }
        }
        .cats-arr {
            transform: rotate(90deg);
        }
    }


    }
    .home-categoris-txt {
        color: #A4AEC1;
    }
body {
    &.filter-open {
        @media (max-width:1023px) {
            max-height: 100vh;
            // overflow: hidden;
            .home-categories  {
                // display: flex;
                z-index: 10000;
                top:0;
                opacity: 1;
                pointer-events: all;
            }
        }


    }
}
.header-cats-row {
    @media (max-width:1365px) {
        flex-wrap: nowrap;
    }
}
.control-btn {
    &.btn-for-mobile {
        @media (max-width:767px) {
            position: absolute;
            right: 15px;
        }
    }
}
.sl-card-price-old {
    @media (max-width:767px)  {
        margin: 0!important;
    }
}
.cats-border {
    // box-shadow: 0 2px 6px 0 rgba(39, 42, 55, 0.1);
    border: solid 1px rgba($color: #e4e7ed, $alpha: 0.4);
}
.mobile-full {
    @media (max-width:767px) {
        width: 100%;
    }
}
.home-slider-banner {
    background-color:$white;
    border-radius: 12px;
    overflow: hidden;
    @media (max-width:767px) {
        display: none;
    }
}
.section-slider-custom-tabs {
    @media (min-width:1024px) {
      width: auto !important;
    }
}
.inner-scroller-banner {
    position: relative;
    width: 100%;
    max-width: 578px;
    max-height: 324px;
    margin: 0 auto;
    border-radius: 10px;
    overflow: hidden;
 
    @media (max-width:767px) {
        max-width: 320px;
        max-height: 320px;
    }
    &.inner-scroller-banner-home {
        max-width: 1050px;
        @media (max-width:1100px) {
            max-width: 578px;
        }
        @media (max-width:767px) {
            max-width: 320px;
            max-height: 320px;
        }
    }
  &.inner-scroller-video{
    max-width: 856px;
    max-height: 480px;
    @media (max-width:1100px) {
        max-width: 578px;
    }
    @media (max-width:767px) {
        max-width: 320px;
        max-height: 320px;
    }
  }
}

.in-article-banner {
    position: relative;
    width: 100%;
    max-width: 750px;
    max-height: 422px;
    margin: 0 auto;
    border-radius: 10px;
    overflow: hidden;
}
