.custom-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100001;
    overflow-y: auto;

    @media (max-width: 767px) {
        z-index: 99999999;
    }

    .custom-modal-inner {
        width: 100%;
        max-width: 624px;
        padding: 24px;
        border-radius: 14px;
        background-color: #ffffff;
        position: relative;
        margin: 1.75rem auto;

        @media (max-width: 767px) {
            max-width: 100%;
            padding: 16px;
            margin: 0;
        }

        &.agreement-modal {
            max-width: 840px;

            .modal-content-box {
                max-height: 500px;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 3px;
                    height: 48px;
                    display: block;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #d7dce4;
                    border-radius: 6px;
                }
            }
        }

        &.new-product {
            max-width: 524px;
        }

        &.modal-know-more {
            max-width: 824px;

            .custom-modal-wrapper {
                padding: 24px;

                @media (max-width: 767px) {
                    padding: 36px 0 0 0;
                }
            }

            iframe {
                border-radius: 24px;

                @media (max-width: 767px) {
                    border-radius: 10px;
                    height: 400px;
                }
            }
        }

        @media (max-width: 767px) {
            border-radius: 10px;
        }

        .close-popup {
            position: absolute;
            top: 24px;
            right: 24px;

            svg {
                width: 14px;
                height: 14px;

                line {
                    transition: .3s ease-in-out;
                }
            }

            &.custom-close {
                top: -30px;
                right: -30px
            }

            &:hover {
                svg {
                    line {
                        stroke: $blue-gray;
                    }
                }
            }

            @media (max-width: 767px) {
                top: 12px;
                right: 12px;
            }
        }

        .popup-footer {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                width: calc(100% + 48px);
                height: 2px;
                top: 0;
                left: -24px;
                background-color: #f3f4f6;
            }
        }
    }
}

//
.prom-qnt-box {
    width: 88px;

    @media (max-width: 767px) {
        width: 70px;
    }
}

.mobile-prom {
    @media (min-width: 768px) {
        display: none !important;
    }
}

.custom-modal-inner {
    &.fixed-mobile {
        @media (max-width: 767px) {
            position: absolute !important;
            bottom: 0;
            // height: 70vh;
            border-top-left-radius: 18px !important;
            border-top-right-radius: 18px !important;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;

            .custom-modal-wraper,
            .custom-modal-wrapper {
                max-height: 50vh;
                overflow-x: hidden;
                overflow-y: auto;
            }

            .popup-footer {
                flex-direction: column;
                padding-bottom: 40px;
            }

            &::after {
                content: "";
                position: absolute;
                top: -20px;
                left: 50%;
                transform: translateX(-50%);
                width: 39px;
                height: 6px;
                border-radius: 4px;
                background-color: #ffffff;
                pointer-events: none;
            }

            .custom-modal-wrapper {
                max-height: 60vh;
                overflow-y: auto;
                overflow-x: hidden;
            }
        }

    }
}

@media (max-width:767px) {
    .custom-modal-dialog {
        max-height: 70vh;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 4px;
            height: 48px;
            display: block;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #d7dce4;
            border-radius: 6px;
        }
    }

    .promos-modal-container {
        border-radius: 20px 20px 0 0;
        margin-top: 15px;
    }

    .promotions-scroll {
        max-height: 70vh;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 4px;
            height: 48px;
            display: block;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #d7dce4;
            border-radius: 6px;
        }

        .acceleration-promotions {
            background-color: white !important;
        }

        .acceleration-promotions {
            width: 100% !important;

            .promo-balance {
                width: 100% !important;
            }
        }

    }
}

// stats
.custom-modal-inner {
    &.pr-stats {
        padding: 32px;
        width: 100%;
        max-width: 840px;

        @media (max-width: 767px) {
            padding: 32px 8px;
        }

        .stats-box {
            display: flex;
            justify-content: center;
        }

        .apexcharts-yaxis-texts-g {
            display: none;
        }

        .stats-btn {
            border: solid 1px rgba(228, 231, 237, 0.75) !important;
            padding: 16px 24px;
            height: 88px;
            width: 100%;
            min-width: 168px;
            //  margin: 0 8px;
            border-radius: 16px;

            .stats-arrow {
                position: absolute;
                bottom: 24px;
                right: 24px;
            }
        }

        .vip-btn {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 0;
            margin-right: 16px;

            .color-vip {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                margin-right: 6px;

                &.s-vip {
                    background-color: #fec900;
                }

                &.vip-plus {
                    background-color: #ff641f;
                }

                &.vip {
                    background-color: #0ec604;
                }

                &.clr {
                    background-color: #325afa;
                }
            }

            div {
                &:nth-child(2) {
                    text-transform: uppercase;
                }
            }
        }

        .stats-month {
            width: 100%;
            max-width: 170px;
            margin-left: 12px;
        }

        .col-3 {
            padding: 0 8px;
        }

        .stats-box {
            @media (max-width: 767px) {
                display: none !important;
            }
        }

    }

    &.modal-adress-from {
        .add-address-content {
            max-height: 70vh;
            overflow-y: auto;
            overflow-x: hidden;

            &::-webkit-scrollbar {
                width: 4px;
                height: 48px;
                display: block;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #d7dce4;
                border-radius: 6px;
            }
        }

    }
}

.pay-content {
    .custom-control-label {
        display: flex;
        align-items: center;

        &::before {
            top: 50%;
            transform: translateY(-50%);
        }

        &::after {
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.flexible-btn {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);

    .btn {
        border-radius: 24px;
        line-height: 12px;
    }
}

body {
    @media (min-width:768px) {
        .custom-modal-dialog {
            display: flex;
            align-items: center;
            min-height: calc(100% - (.5rem * 2));
            transition: transform .3s ease-out;
            transition: transform .3s ease-out, -webkit-transform .3s ease-out;
            -webkit-transform: translate(0, -25%);
            transform: translate(0, -25%);
            margin: auto;
            width: auto;


        }

        &.modal-active {
            overflow: hidden;
            padding-right: 17px;

            .custom-modal-dialog {
                transform: translate(0, 0);
            }

            .custom-modal-container {
                background-color: rgba($color: #000000, $alpha: 0.5);
                &.black {
                    background-color: rgba($color: #000000, $alpha: .9);
                }
            }
        }
    }

    &.modal-active {
        overflow: hidden;

        .custom-modal-container {
            background-color: rgba($color: #000000, $alpha: 0.5);
        }
    }
}

.request-loan {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 400px;

    @media (min-width:1600px) {
        max-height: 740px;
    }

    &::-webkit-scrollbar {
        width: 4px;
        height: 48px;
        display: block;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #d7dce4;
        border-radius: 6px;
    }
}

.custom-modal-inner {
    .add-address-content {
        .scrolled-address {
            @media (max-width:1600px) {
                max-height: 400px;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 4px;
                    height: 48px;
                    display: block;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #d7dce4;
                    border-radius: 6px;
                }
            }

            @media (max-width:767px) {
                max-height: 350px;
                padding: 20px !important;
            }
        }
    }
}

.content-scroll_ {
    @media (min-width:768px) and (max-width:1679px) {
        max-height: 400px;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 3px;
            height: 48px;
            display: block;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #d7dce4;
            border-radius: 6px;
        }
    }

    @media (max-height:640px) and (max-width:1920px) {
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 3px;
            height: 48px;
            display: block;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #d7dce4;
            border-radius: 6px;
        }
    }

}

.modal-backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background-color: transparent;
}

.security-modal {
    .close-popup {
        position: absolute;
        top: 24px;
        right: 24px;

        svg {
            line {
                stroke: white;
            }
        }

        &:hover {
            svg {
                line {
                    stroke: $blue-gray;
                }
            }
        }

    }
}

.custom-nootification-box {
    background-color: #F1F3F6;
    padding: 46px 93px;
}

.custom-modal-inner { 
    &.modal-promotion   {
      width: 100%;
      max-width: 930px;
      padding: 0;
      border-radius: 20px;
      .acceleration-promotions{
        width: 295px;
        border-radius: 20px 0 0 20px;
      }
      .promo-balance {
        width: 247px;
        height: 72px;
        padding: 8px 16px;
          border: 1px solid #E7EAF3;
          border-radius: 8px;
      }
      .promo {
        path {
          fill: #6F7383!important;
        }
        &.active {
          color: #5386E4 !important;
          font-weight: bold;
          path {
            fill: #5386E4 !important;
          }
        }
      }
    }
  }

.custom-modal-inner {
    &.modal-promotion {
        width: 100%;
        max-width: 930px;
        padding: 0;
        border-radius: 20px;

        .acceleration-promotions {
            width: 295px;
            border-radius: 20px 0 0 20px;
        }

        .promo-balance {
            width: 247px;
            height: 72px;
            padding: 8px 16px;
            border: 1px solid #E7EAF3;
            border-radius: 8px;
        }

        .promo {
            path {
                fill: #6F7383 !important;
            }

            &.active {
                color: #5386E4 !important;
                font-weight: bold;

                path {
                    fill: #5386E4 !important;
                }
            }
        }
    }
}

.custom-nootification-box {
    background-color: #F1F3F6;
    padding: 46px 93px;
}
