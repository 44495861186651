.change-img  {
    .img-box {
        width: 64px;
        height: 64px;
        border-radius: 12px;
        border: solid 1px #e4e7ed;
        overflow: hidden;
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .hover-change {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba($color: #272A37, $alpha: .95);
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            pointer-events: none;
            z-index: 0;
            transition: .3s ease-in-out;
           
        }
        &:hover {
            .hover-change {
                opacity: 1;
            }
        }
    }
    .upload-input {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
    .img-size  {
       color: #728cfb; 
    }
}
.password-eye {
    position: absolute;
    top: 55px;
    transform: translateY(-50%);
    right: 32px;
    cursor: pointer;
    padding: 12px;
    background-size: initial !important;
}