.contentWrapper {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rateText {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  color: #272A37;
  text-align: center;
  max-width: 424px;
  display: block;
}

.rateStarsWrapper {
  // margin-top: 20px;
  display: flex;
  align-items: center;
}

.starWrapper {
  margin-right: 24px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
}

.rateFeedbackWrapper {
  margin-top: 40px;
  margin-bottom: 22px;
  width: 100%;
}

.rateFeedbackTextarea {
  width: 100%;
  height: 120px;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #E4E7ED;
  outline: none;
}

.addPhotoWrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.modalActionWrapper {
  margin-top: 24px;
  display: flex;
  width: 100%;
  justify-content: end;
}

.modalButton {
  padding: 14px 52px;
  background: #FEC900;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  color: #222;
  border:none
}

.disabledButton {
  background: #CAD7E5;
}

.previewImagesWrapper {
  margin-left: 12px;
  display: flex;
  align-items: center;
}

.previewImageWrapper {
  position: relative;
  margin-right: 12px;

  &:last-child {
    margin-right: 0;
  }
}

.previewImageClose {
  cursor: pointer;
  position: absolute;
  top:-7px;
  right: -5px;
  width: 20px;
  height: 20px;
  background: #fff;
  filter: drop-shadow(0px 0px 24px rgba(83, 134, 228, 0.14));
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.previewImage {
  width: 48px;
  height: 48px;
  border-radius: 12px;
}

.previewCloseIcon {
  width: 8px;
  height: 11px;
  fill: #B0B9C9;
}
