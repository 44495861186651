.anim {
	background-color: #d8d8d8;
}
.animate {
    animation : shimmer 2s infinite linear;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 1000px 100%;
}

@keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
}

.card-placeholder {
	border: 1px solid #e4e7ed;
	border-radius: 12px;
	justify-content: space-between;
	background-color: #FFF; /*#f9f9fb*/
	flex: 1 1 0;
  	width: 0;
	.card-placeholder-image {
		height: 120px;
		border-radius: 8px;
	}
	.card-placeholder-body {
		border-top: 1px solid #f9f9fb;
	}
	.line {
		height: 10px;
		background-color: #e7e9ed;
	}
	&.startup-card-placeholder {
		width: 100%;
		.card-placeholder-image {
			height: 100px;
		}	
	}
}
.cat-box-placeholder-cards {
	.card-placeholder-image {
		height: 114px;
		margin: 0 !important
	}
	.card-placeholder-body  {
		display: none;
	}
}

.product-slider-placeholder-cards {
	position: relative;
	overflow: hidden;
	.card-placeholder {
		display: none!important;
	    @media (max-width: 320px) {
	        &:nth-child(1), &:nth-child(2) {
	        	display: inline-flex!important;
	        }
	        &:nth-child(1) {
	        	margin-right: 5px;
	        }
	    }
	    @media (min-width: 320px) and (max-width: 640px) {
	        &:nth-child(1), &:nth-child(2) {
	        	display: inline-flex!important;
	        }
	        &:nth-child(1) {
	        	margin-right: 15px;
	        }
	    }
	    @media (min-width: 640px) and (max-width: 768px) {
	        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
	        	display: inline-flex!important;
	        }
	        &:nth-child(1), &:nth-child(2) {
	        	margin-right: 20px;
	        }
	    }
	    @media (min-width: 768px) and (max-width: 1024px) {
	        &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
	        	display: inline-flex!important;
	        }
	        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
	        	margin-right: 20px;
	        }
	    }
	    @media (min-width: 1024px) {
	        display: inline-flex!important;
	        &:not(:last-child) {
	        	margin-right: 20px;
	        }
	    }
	}
}

.search-placeholder-cards {
	.card-placeholder {
		width: 100%;
	}
}

.notification-card-placeholder {
	padding: 18px;
	&:not(:last-child) {
		border-bottom: 1px solid #f3f4f6;
	}
	> div:first-child {
		width: 48px;
		height: 48px;
	}
}
// placeholder
.placeholder-container {
	margin-left:-8px !important;
	margin-right:-8px !important;
    .placeholder-card {
        &:last-child {
			// @media (min-width:768px) {
			// 	padding-right: 15px !important;
			// }
        }
	}
	&.sales-placeholder {
		margin-left: -0 !important;
		margin-right: -0 !important;
		.placeholder-card {
			&:first-child {
				padding-left: 0!important;
			}
		}
	}
	&.shop-sales-placeholder {
		height: 424px;
		& > div {
			height: 100%;
			& > div {
				height: 100%;
				.card-placeholder {
					height: 100%;
					.card-placeholder-image  {
						height: 100%;
					}
				}
			}
		}
	}
}
.nw-pr-placeholder  {
//   margin-bottom: 24px;
	div {
		height: 100%;
	}
	.card-placeholder-image {
		height: 100%;
	}
}
.vip-placeholder {

	.card-placeholder-image {
		margin: 10px!important;
	}
	.card-placeholder-body {
		
	}
}

.shop-placeholder-container {
	.shop-placeholder {
		.placeholder-inner {
			@media (min-width:960px) {
				width: 180px !important;
				height: 180px !important;
				flex: 0 0 180px;
				max-width: 180px;
				border-radius: 50%;
				overflow:hidden;
				.card-placeholder {
					border: none;
				}
	
			}
		}

		
		.card-placeholder-image {
			width: 80px;
			height: 80px;
			border-radius: 50%;
			margin: 0!important;
		}
		.card-placeholder-body  {
			display: none;
		}
		.card-placeholder {
			padding: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			@media (min-width:768px) { 
				// border-radius: 50%;
				// padding: 20px;
			}
		
		}
		div {
			height: 100%;
		}
		&:last-child {
			@media (min-width:768px) {
				// padding-right: 15px !important;
			}
		}
	}
}

.search-placeholder  {
	.card-placeholder  {
		border: 0;
		height: 100%;
	}
	.placeholder-inner {
		height: 100%;
	}
}
.cat-image-container {
	&.animate {
		animation : shimmer 2s infinite linear;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 1000px 100%;
	}
}
.animate-box {
	animation : shimmer 2s infinite linear;
	background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
	background-size: 1000px 100%;
}