.shops-page {
    #search-input{
        background-image: url('../../../img/icons/filter-search-i.svg');
        background-repeat: no-repeat;
        background-position: right 15px bottom 14px;
    }
    .row {
        .top-info {
            flex-direction: row;
            background-color: transparent;
            @media (max-width:1200px) {
                flex-direction: column;
            }
            .drop-cont {
                @media (max-width:1200px) {
                    margin-top: 20px;
                }
                @media (max-width:567px) {
                    flex-direction: column-reverse;
                }
                .shop-prs-filter{
                    input{
                        background-image: url('../../../img/icons/filter-search-i.svg');
                        background-repeat: no-repeat;
                        background-position: right 15px bottom 13px;
                    }
                    @media (max-width:767px) {
                        position: unset;
                        padding:unset;
                        padding-right:15px;
                        width:unset;
                        .form-group{
                            justify-content: flex-start;
                        }
                    }
                    @media (max-width:567px) {
                       margin-top: 10px;
                    }
                }
            }
        }
        &.row-cols-5 {
            margin-left: -8px;
            margin-right: -8px;
            .col {
                padding: 8px;
                .card {
                    border-color: red !important;
                    .card-header {
                        border-color: red !important;
                    }
                }
            }
        }
        &.row-cols-1 {
            .card-header {
                img {
                    object-fit: scale-down;
                    width: 100%;
                    @media (min-width:1366px) {
                        height: 104px;
                    }
                    @media (max-width:1365px) {
                        height: 94px;
                    }
                    @media (max-width:1200px) {
                        height: 60px;
                    }
                    @media (max-width:1024px) {
                        height: 94px;
                    }
                }
            }
        }
    }
}