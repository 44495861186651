.modal-header .close {
	position: absolute;
	right: 20px;
	top: 20px;
}
// .custom-modal-wrapper{
// 	.scrolled-modal{
//       max-height:600px;
// 	  overflow-y:scroll;
//      &::-webkit-scrollbar {
// 		width: 4px;
//         height: 48px;
// 		display:block;
// 	  }
// 	  &::-webkit-scrollbar-thumb {
// 		background-color: #d7dce4;
// 		border-radius: 6px;
// 	  }

// 	}

// }
