.form-control {
    border-radius: 10px;
    border: solid 1px #e4e7ed;
    background-color: #ffffff;
    font-size: 14px;
    line-height: 1.29;
    color: #272a37;
    padding: 23px 14px;
    &:focus, &:active {
		border: 1px solid #9aadfc;
		outline: none;
    }
    &-lg {
    	padding: 29px 24px;
    	border-radius: $border-radius-lg;
    }
    &.sg-error{
	    border: 1px solid #ff665e;
	}
	&::-webkit-input-placeholder {
	    
	    font-size: 14px;
	    color: #b0b9c9;
	}
	&::placeholder {
	    
	    font-size: 14px;
	    color: #b0b9c9;
	}
	&:-moz-placeholder {
	    
	    font-size: 14px;
	    color: #b0b9c9;
	    opacity:  1;
	}
	&::-moz-placeholder {
	    
	    font-size: 14px;
	    color: #b0b9c9;
	    opacity:  1;
	}
	&:-ms-input-placeholder {
    	
    	font-size: 14px;
    	color: #b0b9c9;
	}
	&::-ms-input-placeholder {
    	
    	font-size: 14px;
    	color: #b0b9c9;
	}
}
.sg-error-message {
    
    font-size: 12px;
    color: #ff665e;
    display: block;
}

//border radius
.border-radius {
	border-radius: $border-radius;
}
.border-radius-sm {
	border-radius: $border-radius-sm;
}
.border-radius-xs {
	border-radius: $border-radius-xs;
}
.border-radius-md {
	border-radius: $border-radius-md;
}
.border-radius-lg {
	border-radius: $border-radius-lg;
}
.input-border-end {
	border-top-right-radius: 10px !important;
	border-bottom-right-radius: 10px !important;
}
//headings
h1, h2, h4, h3, h5, h6 {
	font-family: $font-family-bold-caps;
}
h1 {
	font-size: 24px;
	line-height: 36px;
}
h2 {
	font-size: 21px;
}
h3 {
	font-size: 18px;
}
h4 {
	font-size: 16px;
}

//form-group
.form-group {
	// 
}
.input-group {
	display: flex;

	.input-group-text {
		background: transparent;
		border-radius: $border-radius;
		font-size: 12px;
		display: flex;
		align-items: center;
		padding: 0.375rem 0.75rem;
		margin-bottom: 0;
		height: 100%;
		font-weight: 400;
		line-height: 1.5;
		text-align: center;
		white-space: nowrap;
		border: 1px solid #e4e7ed;
		border-left: none;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
	.input-group-append .sg-selectbox-container .sg-selectbox__control {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
	.form-control {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
}
.form-check .form-check-label {
	cursor: pointer;
	.form-check-input {
    	width: 16px;
	    height: 16px;
	    border-radius: 4px;
	    border: solid 2px #e4e7ed;
	    background-color: #f3f4f6;
	    -webkit-appearance: none;
	    outline: none;
	    &:checked {
	    	background-color: $danger;
	    	border-color: $danger;
	    	// background-image: url(/static/media/checkbox.b7902671.svg);
		    background-repeat: no-repeat;
		    background-position: 50% 50%;
	    }
	}
}
//widths
.width-70px {
	width: 70px;
}
.width-100px {
	width: 100px;	
}

/*SELECT BOXES*/
.sg-selectbox-container{
    .sg-selectbox__control{
    	border-radius: 10px;
		border: solid 1px #e4e7ed;
		background: transparent;
		height: 48px;
		&.sg-selectbox__control--is-focused{
			border: solid 1px #9aadfc;
			border-radius: 10px;
			box-shadow: none;
		}
		&:hover{
			border: solid 1px #9aadfc;
			box-shadow: none;
		}
		&.sg-selectbox__control--menu-is-open{
			.sg-selectbox__indicator{
				background-image: url('../../../img/guide/arrow-up.svg');
			}
		}
	
	}
	&.products-quantity  {
		.sg-selectbox__control {
			background-color: $white;
		}
	
	}
	.sg-selectbox__value-container{
		padding: 10px 14px;
		line-height: 1.29;
	    
	    font-size: 12px;
	    color: #272a37;
	    cursor: pointer;
	    &:hover{
			box-shadow: none;
		}
	    .sg-selectbox__single-value{
	    	margin:0;
	    }
	    .sg-selectbox__placeholder{
	    	// margin: 0;
			
			font-size: 12px;
			color: #b0b9c9;
	    }
	    .sg-selectbox__input{
	    	margin: 0;
	    	padding: 0;
	    }
	}
	&.sg-md-height {
		.sg-selectbox__value-container {
			padding: 0 14px;
			line-height: normal;
			height: 100%;
		}
		.sg-selectbox__control {
			height: 100% !important;
			min-height: initial;
		}
	}
	&.sg-lg-height {
		.sg-selectbox__value-container {
			padding: 0 14px;
			line-height: normal;
			height: 100%;
		}
		.sg-selectbox__control {
			height: 100% !important;
			min-height: initial;
		}
	}
	.sg-selectbox__indicators{
		.sg-selectbox__indicator-separator{
			display: none;
		}
		.sg-selectbox__indicator{
			background-image: url('../../../img/guide/arrow-down.svg');
	    	background-repeat: no-repeat;
	    	background-position: calc(100% - 10px) 50%;
	    	padding: 14px 14px 14px;
			svg{
				display: none;
			}
		}
	}
	&.sg-white{
		.sg-selectbox__indicators{
			.sg-selectbox__indicator{
				background-image: url('../../../img/guide/arrow-down-white.svg');
    			border: 0;
			}
		}
		.sg-selectbox__control--menu-is-open{
			.sg-selectbox__indicator{
				background-image: url('../../../img/guide/arrow-up-white.svg');
    			border: 0;
			}
		}
	}
	&.sg-green{
		.sg-selectbox__indicators{
			.sg-selectbox__indicator{
				background-image: url('../../../img/guide/arrow-down-green.svg');
    			border: 0;
			}
		}
		.sg-selectbox__control--menu-is-open{
			.sg-selectbox__indicator{
				background-image: url('../../../img/guide/arrow-up-green.svg');
    			border: 0;
			}
		}
	}
	&.sg-blue{
		.sg-selectbox__indicators{
			.sg-selectbox__indicator{
				background-image: url('../../../img/guide/arrow-down-blue.svg');
    			border: 0;
			}
		}
		.sg-selectbox__control--menu-is-open{
			.sg-selectbox__indicator{
				background-image: url('../../../img/guide/arrow-up-blue.svg');
    			border: 0;
			}
		}
	}
	&.sg-purple{
		.sg-selectbox__indicators{
			.sg-selectbox__indicator{
				background-image: url('../../../img/guide/arrow-down-purple.svg');
    			border: 0;
			}
		}
		.sg-selectbox__control--menu-is-open{
			.sg-selectbox__indicator{
				background-image: url('../../../img/guide/arrow-up-purple.svg');
    			border: 0;
			}
		}
	}
	&.sg-gray{
		.sg-selectbox__indicators{
			.sg-selectbox__indicator{
				background-image: url('../../../img/guide/arrow-down-gray.svg');
    			border: 0;
			}
		}
		.sg-selectbox__control--menu-is-open{
			.sg-selectbox__indicator{
				background-image: url('../../../img/guide/arrow-up-gray.svg');
    			border: 0;
			}
		}
	}

	.sg-selectbox__indicator.sg-selectbox__loading-indicator {
		background-image: none !important;
	}
	.sg-selectbox__menu{
		top: calc(100% + 5px);
		background-color: #ffffff;
		margin: 0 0 0;
		border-radius: 10px;
		box-shadow: 0 2px 6px 0 rgba(39, 42, 55, 0.1);
	    border: solid 1px #e4e7ed;
	    z-index: 9999;
	    .sg-selectbox__menu-list{
	    	.sg-selectbox__option{
				
			    font-size: 12px;
			    color: #272a37;
			    padding: 10px 14px 10px;
			    white-space: nowrap;
			    &:hover,
			    &.sg-selectbox__option--is-focused{
		    		color: #4a6cfa;
		    		background-color: rgba(215, 220, 228, 0.2);
		    		cursor: pointer;
				}
				&.sg-selectbox__option--is-selected{
					color: #4a6cfa;
					background-color: transparent;
				}
			}
			.sg-selectbox__group{
				.sg-selectbox__group-heading{
					
			    	font-size: 12px;
			    	color: #272a37;
			    	text-transform: initial;
			    	background-image: url('../../../img/guide/arrow-down.svg');
    				background-repeat: no-repeat;
    				background-position: calc(100% - 10px) 10px;
					
				}
				.sg-selectbox__option{
					padding: 10px 28px;
				}
			}
		}
	}

	&.sg-selectbox-container-tradein {
		.sg-selectbox__control  {
			border:none !important;
			height: auto !important;
			min-height: auto !important;
			.sg-selectbox__single-value {
				max-width: calc(100% - 20px);
				font-size:14px;
			}
			.sg-selectbox__placeholder {
				font-size: 14px;
				color: $dark;
			}
		}
		.sg-selectbox__value-container  {
			padding:0;
		}
	}
	&.sg-wide-menu {
		.sg-selectbox__menu {
			width: 190px;
		}
	}
	&.sg-menu-right {
	 .sg-selectbox__menu {
		 right:0;
	 }
	}
	&.sg-menu-top {
	 .sg-selectbox__menu {
		 top: auto;
		 bottom: 100%;
	 }
	}
	@include media-breakpoint-down(md) {
		&.sg-menu-small-height-list-md {
			.sg-selectbox__menu-list {
				max-height: 180px;
			}
		}
	}
	&.group-options-menu {
		.sg-selectbox__menu-list,
		.sg-selectbox__group {
			padding-top: 0 ;
			padding-bottom: 0 ;
		}
		.sg-selectbox__group-heading {
			background-image: none !important;
			height: 35px;
			display: flex;
			align-items: center;
			background-color: #F2F3F6;
			margin-bottom: 0;
			font-family: $font-family-bold-caps !important;
			@extend .is-uppercase;
		}
		.sg-selectbox__option {
			padding: 6px 13px !important;
			font-size: 14px;
			color: #6F7383 !important;
			&.sg-selectbox__option--is-selected,
			&.sg-selectbox__option--is-focused {
				color: #272A37 !important;
				background-color: initial !important;
			}
		}
	}
}

.sg-selectbox__menu {
	overflow: hidden;
}
.sg-selectbox__menu-list {
	overflow:hidden;
	&::-webkit-scrollbar {
		width: 3px;
		background: none;
	  }
	
	  /* Track */
	  &::-webkit-scrollbar-track {
		background: #ffffff;
	  }
	
	  /* Handle */
	  &::-webkit-scrollbar-thumb {
		background: #d7dce4;
		width: 2px;
	  }
}
.product-search-selectbox {
	.sg-selectbox__menu {
		width: 170px!important;
	}
}
/*END*/