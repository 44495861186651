.svg-i {
    display: flex;
    width: 14px;
    height: 14px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;

    // header icons
    &.logo-i {
        background-image: url('../../../img/icons/Logo-market.svg');
        width: 150px;
        height: 40px;
    }
    &.youtube-play {
        background-image: url('../../../img/icons/YoutubePlay.png');
        width: 30px;
        height: 30px;
    }

    &.header-dropdown-arrow {
        background-image: url('../../../img/icons/header-dropdown-arrow.svg');
    }

    &.search-i {
        background-image: url('../../../img/icons/search.svg');
    }

    &.search-i-mobile {
        background-image: url('../../../img/icons/search-icon-mobile.svg');
    }

    &.notification-i {
        background-image: url('../../../img/icons/notification.svg');
    }

    &.add-statement-i {
        background-image: url('../../../img/icons/add-statement.svg');
    }
    &.header-add-icon {
        background-image: url('../../../img/icons/header-add-icon.svg');
    }
    &.add-statement-black-i {
        background-image: url('../../../img/icons/add-statement-black.svg');
    }

    &.lang-minus-i {
        background-image: url('../../../img/icons/lang-minus.svg');
    }

    &.message-i {
        background-image: url('../../../img/icons/message.svg');
    }

    &.favorites-i {
        background-image: url('../../../img/icons/favorites.svg');
    }

    &.favorites-white-i {
        background-image: url('../../../img/icons/favorites-white.svg');
    }

    &.cart-i {
        background-image: url('../../../img/icons/cart.svg');
    }

    &.home-icon-i {
        background-image: url('../../../img/icons/home-icon.svg');
    }
    &.add-icon-i {
        background-image: url('../../../img/icons/add-icon.svg');
    }
    &.navbar-add-icon {
        background-image: url('../../../img/icons/navbar-add-icon.svg');
    }
    &.navbar-categories {
        background-image: url('../../../img/icons/navbar-categories.svg');
    }
    &.active-add-i {
        background-image: url('../../../img/icons/active-add-icon.svg');
    }
    &.active-home-i {
        background-image: url('../../../img/icons/active-home-icon.svg');
    }
    &.active-category-i {
        background-image: url('../../../img/icons/active-category-icon.svg');
    }
    &.active-favorites-i {
        background-image: url('../../../img/icons/active-favorites-icon.svg');
    }
    &.category-icon-i {
        background-image: url('../../../img/icons/category-icon.svg');
    }
    &.cart-icon-i {
        background-image: url('../../../img/icons/cart-icon.svg');
    }
    &.active-cart-i {
        background-image: url('../../../img/icons/active-cart-icon.svg');
    }

    &.arrow-dropdown-i {
        background-image: url('../../../img/icons/arrow-dropdown.svg');
    }

    &.cart-white-i {
        background-image: url('../../../img/icons/add-cart-white.svg');
    }

    &.menu-i {
        background-image: url('../../../img/icons/menu.svg');
        width: 25px;
        height: 25px;
    }

    &.auth-i {
        background-image: url('../../../img/icons/auth.svg');
    }

    &.user-male-i {
        background-image: url('../../../img/icons/user-male.svg');
    }

    &.user-female-i {
        background-image: url('../../../img/icons/user-female.svg');
    }

    &.lang-ka-i {
        background-image: url('../../../img/icons/lang-ka.svg');
    }

    // social
    &.fb-i {
        background-image: url('../../../img/icons/fb.svg');
    }

    &.insta-i {
        background-image: url('../../../img/icons/insta.svg');
    }

    &.linkedin-i {
        background-image: url('../../../img/icons/linkedin.svg');
    }

    &.my-logo-i {
        background-image: url('../../../img/icons/logo.svg');
        width: 30px;
        height: 14px;
    }

    &.arrow-filter {
        background-image: url('../../../img/icons/arrow-filter.svg');
    }

    &.down-arrow {
        background-image: url('../../../img/icons/down-arrow.svg');
        width: 8px;
        height: 8px;
    }

    &.down-arrow-disable {
        background-image: url('../../../img/icons/down-arrow-disable.svg');
        width: 8px;
        height: 8px;
    }

    &.up-arrow {
        background-image: url('../../../img/icons/up-arrow.svg');
        width: 8px;
        height: 8px;
    }

    &.up-arrow-disable {
        background-image: url('../../../img/icons/up-arrow-disable.svg');
        width: 8px;
        height: 8px;
    }

    &.chevron-left {
        background-image: url('../../../img/icons/chevron-left.svg');
        background-size: 9px;
    }

    &.cats-arr {
        background-image: url('../../../img/icons/cats-arr.svg');
        width: 9px;
        height: 9px;
    }

    &.cats-leftarr {
        background-image: url('../../../img/icons/cats-leftarr.svg');
    }

    &.home-cats {
        background-image: url('../../../img/icons/home-cats.svg');
    }

    &.header-cats-sale-online {
        background-image: url('../../../img/icons/sale-cats-online.svg');
    }

    &.header-cats-sale {
        background-image: url('../../../img/icons/Discount.svg');
        height: 16px;
        width: 16px;
    }

    &.dropdown-arrow {
        background-image: url('../../../img/icons/dropdown-arrow.svg');
    }

    &.price-arrow {
        background-image: url('../../../img/icons/price-arrow.svg');
    }

    &.draft-i {
        background-image: url('../../../img/icons/draft.svg');
        width: 56px;
        height: 56px;
    }

    // pagination
    // &.arrow-single-left-i {
    //     background-image: url('../../../img/icons/linkedin.svg');
    // }
    // &.arrow-single-right-i {
    //     background-image: url('../../../img/icons/linkedin.svg');
    // }
    // &.arrow-multi-left-i {
    //     background-image: url('../../../img/icons/linkedin.svg');
    // }
    // &.arrow-multi-right-i {
    //     background-image: url('../../../img/icons/linkedin.svg');
    // }

    // global
    &.arrow-btn-down-i {
        background-image: url('../../../img/icons/arrow-down.svg');
    }

    &.arrow-slide-left-i {
        background-image: url('../../../img/icons/arrow-slide-left.svg');
        width: 6.621px;
        height: 10.243px;
    }

    &.arrow-slide-right-i {
        background-image: url('../../../img/icons/arrow-slide-right.svg');
        width: 6.621px;
        height: 10.243px;
    }

    &.delete-i {
        background-image: url('../../../img/icons/delete.svg');
    }

    &.img-delete-i {
        background-image: url('../../../img/icons/img-delete.svg');
    }

    &.delete-danger-i {
        background-image: url('../../../img/icons/delete-danger.svg');
    }

    &.add-more-img {
        background-image: url('../../../img/icons/add-more-img.svg');
    }

    &.error-message {
        background-image: url('../../../img/icons/error-message.svg');
        width: 48px;
        height: 48px;
    }

    &.success-messages {
        background-image: url('../../../img/icons/success-message.svg');
        width: 48px;
        height: 48px;
    }

    &.mobile-prom-i {
        background-image: url('../../../img/icons/mobile-prom.svg');
        width: 24px;
        height: 24px;
    }

    &.mobile-color-i {
        background-image: url('../../../img/icons/mobile-color.svg');
        width: 24px;
        height: 24px;
    }

    &.mobile-update-i {
        background-image: url('../../../img/icons/mobile-update.svg');
        width: 24px;
        height: 24px;
    }

    &.turn-off {
        background-image: url('../../../img/icons/turn-off.svg');
    }
    &.turn-on {
        background-image: url('../../../img/icons/turn-on.svg');
    }

    &.action-promotion-i {
        background-image: url('../../../img/icons/promotion-i.svg');
    }

    &.action-color-i {
        background-image: url('../../../img/icons/action-color.svg');
    }

    &.action-update-i {
        background-image: url('../../../img/icons/action-update.svg');
    }
    &.action-close-i {
        background-image: url('../../../img/icons/action-close.svg');
    }


    &.info-i {
        background-image: url('../../../img/icons/info.svg');
    }

    &.tooltip-info-i {
        background-image: url('../../../img/icons/tooltip-info.svg');
    }

    &.call-i {
        background-image: url('../../../img/icons/call.svg');
    }

    &.share-i {
        background-image: url('../../../img/icons/share.svg');
    }

    &.dots-i {
        background-image: url('../../../img/icons/dots.svg');
    }

    &.delivery-i {
        background-image: url('../../../img/icons/delivery.svg');

    }

    &.location-i {
        background-image: url('../../../img/icons/location.svg');
    }

    &.navigation-i {
        background-image: url('../../../img/icons/navigation.svg');
    }

    &.feedback-i {
        background-image: url('../../../img/icons/feedback.svg');
        width: 53px;
        height: 57px;
    }

    &.delivery-data {
        background-image: url('../../../img/icons/delivery-date.svg');
        width: 18px;
        height: 18px;
    }

    &.mark-success {
        background-image: url('../../../img/icons/mark-success.svg');
    }

    &.mark-market {
        background-image: url('../../../img/icons/mark-market.svg');
    }

    &.mark-mute {
        background-image: url('../../../img/icons/mark-mute.svg');
    }

    &.mark-white {
        background-image: url('../../../img/icons/mark-white.svg');
        width: 13px;
        height: 6px;
    }

    &.addresses-pin {
        background-image: url('../../../img/icons/addresses-pin.svg');
    }

    &.scroll-top-i {
        background-image: url('../../../img/icons/scr-top.svg');
    }

    &.card-i {
        background-image: url('../../../img/icons/card.svg');
    }

    &.card-icon-i {
        background-image: url('../../../img/icons/card-icon.svg');
        width: 40px;
        height: 32px;
    }

    &.love-card-icon-i {
        background-image: url('../../../img/icons/love-card-icon.svg');
        width: 40px;
        height: 32px;
    }

    &.paybox-i {
        background-image: url('../../../img/icons/paybox.svg');
        width: 40px;
        height: 32px;
    }

    &.balance-icon-i {
        background-image: url('../../../img/icons/balance-icon.svg');
        width: 40px;
        height: 32px;
    }

    &.mastercard-i {
        background-image: url('../../../img/icons/mastercard.svg');
    }

    &.visa-i {
        background-image: url('../../../img/icons/visa.svg');
    }

    // PAYMENT
    &.pay-card {
        background-image: url('../../../img/icons/payments/card.svg');
        width: 48px !important;
    }

    &.pay-invoice {
        background-image: url('../../../img/icons/payments/invoice.svg');
    }

    &.pay-ertguli {
        background-image: url('../../../img/icons/payments/ertguli.svg');
    }

    &.pay-fast-pay {
        background-image: url('../../../img/icons/payments/fast-pay.svg');
    }

    &.pay-emoney {
        background-image: url('../../../img/icons/payments/emoney.svg');
    }

    &.Crypto {
        background-image: url('../../../img/icons/payments/Crypto.png');
    }

    &.badge-emoney {
        background-image: url('../../../img/icons/emoney.svg');
    }

    &.space-installment {
        background-image: url('../../../img/icons/payments/space.png');
    }

    &.tbc-installment {
        background-image: url('../../../img/icons/payments/tbc.svg');
    }
    &.tbc-installment-new {
        background-image: url('../../../img/icons/payments/tbc.svg');
    }
    &.credo-installment {
        background-image: url('../../../img/icons/payments/credo.svg');
    }

    &.american-icon {
        background-image: url('../../../img/icons/payments/american-express.jpg');

    }

    &.google-pay {
        background-image: url('../../../img/icons/payments/gpay.svg');
    }

    //
    &.star-mute-i {
        background-image: url('../../../img/icons/star-mute.svg');
    }

    &.star-active {
        background-image: url('../../../img/icons/star-active.svg');
    }

    &.close-i {
        background-image: url('../../../img/icons/close-i.svg');
    }
    &.close-bg-i {
        background-image: url('../../../img/icons/close-bg-i.svg');
    }

    &.close-black-i {
        background-image: url('../../../img/icons/close-black-i.svg');
    }

    &.close-gray-i {
        background-image: url('../../../img/icons/close-gray.svg');
    }

    &.close-white-i {
        background-image: url('../../../img/icons/close-white.svg');
    }

    &.tags-delete-i {
        background-image: url('../../../img/icons/tags-delete.svg');
    }

    &.close-catfish-i {
        background-image: url('../../../img/icons/close-white.svg');
    }

    &.close-feedback-i {
        background-image: url('../../../img/icons/close-feedback.svg');
    }

    &.download-i {
        background-image: url('../../../img/icons/download-i.svg');
    }

    &.download-blue {
        background-image: url('../../../img/icons/download-blue.svg');
    }

    &.filter-search-i {
        background-image: url('../../../img/icons/filter-search-i.svg');

        &.bg-size {
            background-size: 14px 14px;
        }
    }

    &.filter-btn-i {
        background-image: url('../../../img/icons/filter-btn.svg');
    }

    &.filter-new-btn-i {
        background-image: url('../../../img/icons/filter-new-btn.svg');
    }

    &.select-arrows-i {
        background-image: url('../../../img/icons/select-arrows.svg');
        width: 20px;
        height: 20px;
    }

    &.favorites-add-i {
        background-image: url('../../../img/icons/Add-favorites.svg');
    }

    &.favorites-added-i {
        background-image: url('../../../img/icons/added-favorites.svg');
    }

    &.rate-bg {
        background-image: url('../../../img/icons/rate-bg.svg');
    }

    &.rate-active {
        background-image: url('../../../img/icons/rate-active.svg');
    }

    &.rate-half {
        background-image: url('../../../img/icons/rate-half.svg');
        width: 7px;

        &.rate-half-2 {
            background-image: url('../../../img/icons/rate-half-2.svg');
            width: 7px;
        }
    }

    &.add-cart-white {
        background-image: url('../../../img/icons/add-cart-white.svg');
        width: 7px;
    }

    &.sales-plus {
        background-image: url('../../../img/icons/sales-plus.svg');
        width: 11px;
        height: 11px;
    }

    &.total-price {
        background-image: url('../../../img/icons/total-price.svg');
        width: 12px;
        height: 6px;
    }

    &.send-offer-i {
        background-image: url('../../../img/icons/send-offer.svg');
        width: 128px;
        height: 64px;
    }

    &.sent-offer-i {
        background-image: url('../../../img/icons/offer-sent.svg');
    }

    &.upload-i {
        background-image: url('../../../img/icons/upload.svg');
    }

    &.blocked-i {
        background-image: url('../../../img/icons/blocked-i.svg');
    }

    &.upload-primary-i {
        background-image: url('../../../img/icons/upload-primary.svg');
    }

    &.stats-top- {
        background-image: url('../../../img/icons/green.svg');
    }

    &.stats-bottom {
        background-image: url('../../../img/icons/red.svg');
    }

    &.stats-dissabled {
        background-image: url('../../../img/icons/dissabled.svg');
    }

    &.eye-i {
        background-image: url('../../../img/icons/eye.svg');
    }

    &.eye-hide {
        background-image: url('../../../img/icons/eye-hide.svg');
    }

    &.adress-pin {
        background-image: url('../../../img/icons/adress-pin.svg');
    }

    &.edit-i {
        background-image: url('../../../img/icons/edit.svg');
    }

    &.left-arr {
        background-image: url('../../../img/icons/card-left.svg');
        width: 6.621px;
        height: 10.243px;
    }

    &.right-arr {
        background-image: url('../../../img/icons/card-right.svg');
        width: 6.621px;
        height: 10.243px;
    }

    &.promotion {
        background-image: url('../../../img/icons/flash.svg');
        width: 12px;
        height: 14px;
    }

    &.theme-i {
        background-image: url('../../../img/icons/theme.svg');
        width: 12px;
        height: 14px;
    }

    &.refresh-i {
        background-image: url('../../../img/icons/refresh.svg');
        width: 12px;
        height: 14px;
    }

    &.sm-eye-i {
        background-image: url('../../../img/icons/sm-eye.svg');
        width: 9px;
        height: 7px;
    }

    &.sm-fav-i {
        background-image: url('../../../img/icons/sm-fav.svg');
        width: 9px;
        height: 7px;
    }

    &.filter-secondary-i {
        background-image: url('../../../img/icons/filter-secondary.svg');
    }

    &.pack-danger-i {
        background-image: url('../../../img/icons/danger-pack.svg');
    }

    &.buy-pack-i {
        background-image: url('../../../img/icons/buy-pack.svg');
    }

    &.ls-edit-i {
        background-image: url('../../../img/icons/ls-edit.svg');
        width: 24px;
        height: 24px;
    }

    &.ls-off-i {
        background-image: url('../../../img/icons/ls-off.svg');
        width: 24px;
        height: 24px;
    }

    &.ls-online-i {
        background-image: url('../../../img/icons/ls-online.svg');
        width: 24px;
        height: 24px;
    }

    &.ls-stat-i {
        background-image: url('../../../img/icons/ls-stat.svg');
        width: 24px;
        height: 24px;
    }

    &.ls-delete-i {
        background-image: url('../../../img/icons/ls-delete.svg');
        width: 24px;
        height: 24px;
    }

    &.percent-i {
        background-image: url('../../../img/icons/percent-i.svg');
    }

    &.percent {
        background-image: url('../../../img/icons/percent.svg');
        width: 28px;
        height: 28px;
    }

    &.tooltip-arrow {
        background-image: url('../../../img/icons/tooltip-arrow.svg');
    }

    // home filters-icon

    &.filter-close-i {
        background-image: url('../../../img/icons/filter-close-i.svg');

    }

    &.home-filter-i {
        background-image: url('../../../img/icons/home-filter.svg');
        width: 16px;
        height: 16px;
    }

    &.slider-right-i {
        background-image: url('../../../img/icons/slider-right.svg');
        width: 7px;
        height: 10px;
    }

    &.slider-left-i {
        background-image: url('../../../img/icons/slider-left.svg');
        width: 7px;
        height: 10px;
    }

    //
    &.main-orders-i {
        background-image: url('../../../img/icons/categories/main-orders.svg');
        width: 40px;
        height: 40px;
    }

    &.main-statement-i {
        background-image: url('../../../img/icons/categories/main-statement.svg');
        width: 40px;
        height: 40px;
    }

    &.main-finance-i {
        background-image: url('../../../img/icons/categories/main-finance.svg');
        width: 40px;
        height: 40px;
    }

    &.main-fast-sale-i {
        background-image: url('../../../img/icons/categories/main-fast-sale.svg');
        width: 40px;
        height: 40px;
    }

    &.main-sale-i {
        background-image: url('../../../img/icons/categories/main-sale.svg');
        width: 40px;
        height: 40px;
    }

    &.main-shops-i {
        background-image: url('../../../img/icons/categories/main-shops.svg');
        width: 40px;
        height: 40px;
    }

    &.main-delivery-i {
        background-image: url('../../../img/icons/categories/main-delivery.svg');
        width: 40px;
        height: 40px;
    }

    &.main-sign {
        background-image: url('../../../img/icons/categories/main-sign.svg');
        width: 40px;
        height: 40px;
    }

    &.main-draft {
        background-image: url('../../../img/icons/categories/drafts.svg');
        width: 40px;
        height: 40px;
    }

    &.main-cart {
        background-image: url('../../../img/icons/categories/main-cart.svg');
        width: 40px;
        height: 40px;
    }

    &.main-template {
        background-image: url('../../../img/icons/categories/templates.svg');
        width: 40px;
        height: 40px;
    }

    //

    &.main-icon-160 {
        background-image: url('../../../img/icons/categories/160.svg');
        width: 40px;
        height: 40px;
    }

    &.main-icon-1038 {
        background-image: url('../../../img/icons/categories/1038.svg');
        width: 40px;
        height: 40px;
    }

    &.main-icon-1066 {
        background-image: url('../../../img/icons/categories/1066.svg');
        width: 40px;
        height: 40px;
    }

    &.main-icon-2 {
        background-image: url('../../../img/icons/categories/2.svg');
        width: 40px;
        height: 40px;
    }

    &.main-icon-1064 {
        background-image: url('../../../img/icons/categories/1064.svg');
        width: 40px;
        height: 40px;
    }

    &.main-icon-18 {
        background-image: url('../../../img/icons/categories/18.svg');
        width: 40px;
        height: 40px;
    }

    &.main-icon-1068 {
        background-image: url('../../../img/icons/categories/1068.svg');
        width: 40px;
        height: 40px;
    }

    &.main-icon-12 {
        background-image: url('../../../img/icons/categories/12.svg');
        width: 40px;
        height: 40px;
    }

    &.main-icon-1067 {
        background-image: url('../../../img/icons/categories/1067.svg');
        width: 40px;
        height: 40px;
    }

    &.main-icon-250 {
        background-image: url('../../../img/icons/categories/250.svg');
        width: 40px;
        height: 40px;
    }

    &.main-icon-302 {
        background-image: url('../../../img/icons/categories/302.svg');
        width: 40px;
        height: 40px;
    }

    &.main-icon-1070 {
        background-image: url('../../../img/icons/categories/1070.svg');
        width: 40px;
        height: 40px;
    }

    &.main-icon-142 {
        background-image: url('../../../img/icons/categories/142.svg');
        width: 40px;
        height: 40px;
    }

    &.main-icon-7 {
        background-image: url('../../../img/icons/categories/7.svg');
        width: 40px;
        height: 40px;
    }

    &.main-icon-1065 {
        background-image: url('../../../img/icons/categories/1065.svg');
        width: 40px;
        height: 40px;
    }

    &.main-icon-1166 {
        background-image: url('../../../img/icons/categories/1166.svg');
        width: 40px;
        height: 40px;
    }

    &.main-icon-1069 {
        background-image: url('../../../img/icons/categories/1069.svg');
        width: 40px;
        height: 40px;
    }









    //
    &.sl-seacrh-i {
        background-image: url('../../../img/icons/sl-seacrh.svg');
        width: 40px;
        height: 40px;
    }

    &.sl-seacrh-add-i {
        background-image: url('../../../img/icons/sl-save.svg');
        width: 40px;
        height: 40px;
    }

    &.how-buy-i {
        background-image: url('../../../img/icons/how-buy.svg');
        width: 24px;
        height: 24px;
    }

    &.step-1 {
        background-image: url('../../../img/icons/step-1.svg');
        width: 56px;
        height: 56px;
    }

    &.market {
        background-image: url('../../../img/icons/market.svg');
        width: 56px;
        height: 56px;
    }

    &.cart-svg {
        background-image: url('../../../img/icons/cart-svg.svg');
        width: 56px;
        height: 56px;
        ;
    }

    &.box {
        background-image: url('../../../img/icons/box.svg');
        width: 56px;
        height: 56px;
    }

    &.house {
        background-image: url('../../../img/icons/house.svg');
        width: 56px;
        height: 56px;
    }

    &.step-2 {
        background-image: url('../../../img/icons/step-2.svg');
        width: 56px;
        height: 56px;
    }

    &.step-3 {
        background-image: url('../../../img/icons/step-3.svg');
        width: 56px;
        height: 56px;
    }

    &.step-4 {
        background-image: url('../../../img/icons/step-4.svg');
        width: 56px;
        height: 56px;
    }

    &.step-line {
        background-image: url('../../../img/icons/step-line.svg');
        width: 79px;
    }

    &.step-line-2 {
        background-image: url('../../../img/icons/step-line-2.svg');
        width: 79px;
    }

    &.member-arr {
        background-image: url('../../../img/icons/shop-arr.svg');
    }

    &.info-i {
        background-image: url('../../../img/icons/info-i.svg');
    }

    &.back-arrow-i {
        background-image: url('../../../img/icons/back-arr.svg');
    }

    &.back-arrow {
        background-image: url('../../../img/icons/back-arrow.svg');
    }

    &.back-arrow-faq {
        background-image: url('../../../img/icons/back-arrow-faq.svg');
    }

    &.seacrh-upload-i {
        background-image: url('../../../img/icons/search-opload.svg');
        width: 32px;
        height: 32px;
    }

    &.seacrh-img-i {
        background-image: url('../../../img/icons/search-opload.svg');
        width: 48px;
        height: 48px;
    }

    &.see-more-i {
        background-image: url('../../../img/icons/see-more.svg');

    }

    &.see-more-black-i {
        background-image: url('../../../img/icons/see-more-black.svg');

    }

    &.checked-step {
        background-image: url('../../../img/icons/checked-step.svg');
        width: 6px;
        height: 4px;
    }

    &.ukraine-flag {
        background-image: url('../../../img/icons/ukraine.svg');
        background-position: center;
        background-size: cover;
        width: 60px;
        height: 40px;
    }

    &.checked-icon {
        background-image: url('../../../img/icons/checked-step.svg');
        background-position: center;
        background-size: auto 6px;
    }

    &.ka {
        background-image: url('../../../img/icons/georgia.svg');
        width: 24px;
        height: 24px;
    }

    &.en {
        background-image: url('../../../img/icons/united-kingdom.svg');
        width: 24px;
        height: 24px;
    }

    &.ru {
        background-image: url('../../../img/icons/russia.svg');
        width: 24px;
        height: 24px;
    }

    &.svg-i-small {
        width: 10px;
        height: 10px;
    }

    &.svg-i-sm {
        width: 16px;
        height: 16px;
    }

    &.svg-i-md {
        width: 18px;
        height: 18px;
    }

    &.svg-i-20 {
        width: 16px;
        height: 16px;

        @media (max-width:767px) {
        width: 20px;
        height: 20px;
        }

    }

    &.svg-i-lg {
        width: 24px;
        height: 24px;
    }

    &.svg-i-xlg {
        width: 40px;
        height: 40px;
    }

    &.svg-i-header {
        width: 24px;
        height: 25px;
    }

    &.user-large {
        width: 60px;
        height: 60px;
        flex-shrink: 0;
    }


    &.vip-super-i {
        background-image: url('../../../img/icons/vip-super.svg');
        width: 16px;
        height: 14px;
    }

    &.vip-i {
        background-image: url('../../../img/icons/vip.svg');
        width: 16px;
        height: 14px;
    }

    &.color-i {
        background-image: url('../../../img/icons/color.svg');
        width: 16px;
        height: 16px;
    }

    &.update-i {
        background-image: url('../../../img/icons/update.svg');
        width: 14px;
        height: 14px;
    }


    &.finances-balance-i {
        background-image: url('../../../img/icons/finances-balance-i.svg');
        width: 48px;
        height: 40px;
    }

    &.plus-i {
        background-image: url('../../../img/icons/plus.svg');
        height: 16px;
    }

    &.add-plus-i {
        background-image: url('../../../img/icons/add_plus.svg');
    }

    &.sale-i {
        background-image: url('../../../img/icons/sale.svg');
    }

    &.group-i {
        background-image: url('../../../img/icons/group.svg');
    }

    &.transfer-i {
        background-image: url('../../../img/icons/transfer.svg');
        width: 138px;
        height: 80px;
    }

    &.transfer-new-i {
        background-image: url('../../../img/icons/transfer-new.svg');
        width: 138px;
        height: 80px;
    }

    &.arrow-green-i {
        background-image: url('../../../img/icons/arrow-green.svg');
        height: 16px;
    }

    &.arrow-green-new-i {
        background-image: url('../../../img/icons/arrow-green-new.svg');
        height: 9px;
    }

    &.fill-balance-i {
        background-image: url('../../../img/icons/fill-balance.svg');
        width: 138px;
        height: 80px;
    }

    &.fill-balance-new-i {
        background-image: url('../../../img/icons/fill-balance-new.svg');
        width: 138px;
        height: 80px;
    }

    &.plus-green-i {
        background-image: url('../../../img/icons/plus-green.svg');
        height: 16px;
    }

    &.arrow-white-i {
        background-image: url('../../../img/icons/arrow-white.svg');
        height: 16px;
    }


    &.update-icon-i {
        background-image: url('../../../img/icons/update-icon.svg');
        width: 175px;
        height: 111px;
    }

    &.vip-plus-icon-i {
        background-image: url('../../../img/icons/vip-plus-icon.svg');
        width: 175px;
        height: 111px;
    }

    &.vip-icon-i {
        background-image: url('../../../img/icons/vip-icon.svg');
        width: 175px;
        height: 111px;
    }

    &.color-icon-i {
        background-image: url('../../../img/icons/color-icon.svg');
        width: 175px;
        height: 111px;
    }

    &.super-vip-icon-i {
        background-image: url('../../../img/icons/super-vip-icon.svg');
        width: 175px;
        height: 111px;
    }

    &.select-down-arrow-i {
        background-image: url('../../../img/icons/select-down-arrow.svg');
        width: 175px;
        height: 111px;
    }

    &.minus-icon-i {
        background-image: url('../../../img/icons/-.svg');
        width: 24px;
        height: 24px;
    }

    &.plus-icon-i {
        background-image: url('../../../img/icons/+.svg');
        width: 24px;
        height: 24px;
    }


    &.table-search-icon-i {
        background-image: url('../../../img/icons/table-search-icon.svg');
        width: 14px;
        height: 14px;
    }


    &.open-shop-logo-icon-i {
        background-image: url('../../../img/icons/open-shop-logo-icon.svg');
        width: 24px;
        height: 24px;
    }

    &.open-shop-info-icon-i {
        background-image: url('../../../img/icons/open-shop-info-icon.svg');
        width: 16px;
        height: 16px;
    }

    &.select-up-icon-i {
        background-image: url('../../../img/icons/select-up-icon.svg');
        width: 9px;
        height: 6px;
    }

    &.select-down-icon-i {
        background-image: url('../../../img/icons/select-down-icon.svg');
        width: 9px;
        height: 6px;
    }

    &.add-photo-icon-i {
        background-image: url('../../../img/icons/open-shop-logo-icon.svg');
        width: 40px;
        height: 40px;
    }

    &.facebook-i {
        background-image: url('../../../img/icons/facebook.svg');
        width: 24px;
        height: 24px;
    }

    &.copy-i {
        background-image: url('../../../img/icons/copy.svg');
        width: 24px;
        height: 24px;
    }

    &.ka-flag-i {
        background-image: url('../../../img/icons/ge-flag.svg');
        width: 24px;
        height: 24px;
    }

    &.en-flag-i {
        background-image: url('../../../img/icons/en-flag.svg');
        width: 24px;
        height: 24px;
    }

    &.ru-flag-i {
        background-image: url('../../../img/icons/ru-flag.svg');
        width: 24px;
        height: 24px;
    }

    &.company-i {
        background-image: url('../../../img/icons/company.svg');
    }

    &.mail-active {
        background-image: url('../../../img/icons/mail-active.svg');
    }

    &.mail-i {
        background-image: url('../../../img/icons/mail.svg');
    }

    &.code-i {
        background-image: url('../../../img/icons/code.svg');
    }

    &.headphone-i {
        background-image: url('../../../img/icons/headphone.svg');
    }

    &.locations-i {
        background-image: url('../../../img/icons/locations.svg');
    }

    &.Write-to-us-i {
        background-image: url('../../../img/icons/Write-to-us.svg');
        width: 20px;
        height: 20px;
    }

    &.chat-send-btn-i {
        background-image: url('../../../img/icons/chat-send-btn.svg');
        width: 32px;
        height: 32px;

        &:hover {
            background-image: url('../../../img/icons/chat-send-btn-hover.svg');
            -webkit-transition-duration: 0.8s;
            /* Safari */
            transition-duration: 0.8s;
            text-decoration: none;
            overflow: hidden;
        }
    }

    &.search-icon-i {
        background-image: url('../../../img/icons/search-icon.svg');
        width: 14px;
        height: 14px;
    }

    &.chat-dotted-icon-i {
        background-image: url('../../../img/icons/chat-dotted-icon.svg');
        width: 16px;
        height: 16px;
    }

    &.amountbefore-i {
        background-image: url('../../../img/icons/amountbefore.svg');
        width: 48px;
        height: 40px;
    }

    &.amountafter-i {
        background-image: url('../../../img/icons/amountafter.svg');
        width: 48px;
        height: 40px;
    }

    &.tbc-button-icon-i {
        background-image: url('../../../img/icons/tbc-logo.svg');
        width: 19px;
        height: 17px;
    }

    &.tbc-blue-icon-i {
        background-image: url('../../../img/icons/tbc-blue.svg');
        width: 19px;
        height: 17px;
    }

    &.i-icon-i {
        background-image: url('../../../img/icons/i-icon.svg');
        width: 12px;
        height: 12px;
    }

    &.clear-icon-i {
        background-image: url('../../../img/icons/clear-icon.svg');
        width: 14px;
        height: 14px;
    }

    &.tradein-1 {
        background-image: url('../../../img/icons/tradein-1.svg');
        width: 56px;
        height: 56px;
    }

    &.tradein-2 {
        background-image: url('../../../img/icons/tradein-2.svg');
        width: 56px;
        height: 56px;
    }

    &.tradein-3 {
        background-image: url('../../../img/icons/tradein-3.svg');
        width: 56px;
        height: 56px;
    }

    &.empty-finances {
        background-image: url('../../../img/icons/emptyStates/empty-finances.svg');
        width: 48px;
        height: 48px;
    }

    &.empty-search {
        background-image: url('../../../img/icons/emptyStates/empty-search.svg');
        width: 48px;
        height: 48px;
    }

    &.empty-fav {
        background-image: url('../../../img/icons/emptyStates/empty-fav.svg');
        width: 48px;
        height: 48px;
    }

    &.empty-orders {
        background-image: url('../../../img/icons/emptyStates/orders.svg');
        width: 48px;
        height: 48px;
    }

    &.empty-cart {
        background-image: url('../../../img/icons/emptyStates/empty-cart.svg');
        width: 48px;
        height: 48px;
    }

    &.empty-products {
        background-image: url('../../../img/icons/emptyStates/empty-products.svg');
        width: 48px;
        height: 48px;
    }

    &.empty-offers {
        background-image: url('../../../img/icons/emptyStates/empty-offers.svg');
        width: 48px;
        height: 48px;
    }

    &.app-store-i {
        background-image: url('../../../img/icons/app-store.svg');
        width: 84px;
        height: 28px;
    }

    &.google-play-i {
        background-image: url('../../../img/icons/google-play.svg');
        width: 93px;
        height: 28px;
    }

    &.change-status-i {
        background-image: url('../../../img/icons/change-status.svg');
        width: 10px;
        height: 10px;
    }

    &.app-store-app {
        background-image: url('../../../img/icons/apple_app.svg');
        width: 122px;
        height: 42px;
    }

    &.google-play-app {
        background-image: url('../../../img/icons/google_app.svg');
        width: 137px;
        height: 42px;
    }

    &.warning-i {
        background-image: url('../../../img/icons/warning.png');
        width: 96px;
        height: 86px;
    }

    &.white-tbc-i {
        background-image: url('../../../img/icons/white-tbc.svg');
        width: 20px;
        height: 20px;
    }

    &.tbc-questionnaire {
        background-image: url('../../../img/icons/tbc-q.svg');
        width: 40px;
        height: 40px;
    }

    &.white-info-i {
        background-image: url('../../../img/icons/white-info.svg');
        width: 20px;
        height: 20px;
    }

    &.blue-tbc-i {
        background-image: url('../../../img/icons/blue-tbc.svg');
        width: 20px;
        height: 20px;
    }

    &.mycoins {
        background-image: url('../../../img/icons/mycoins-icon.svg');
    }

    &.v1-tbc {
        background-image: url('../../../img/icons/V1 Tbc.svg');
        width: 52px;
        height: 52px;
    }

    &.v1-space {
        background-image: url('../../../img/icons/V1 Space.svg');
        width: 52px;
        height: 52px;
    }

    &.v2-tbc {
        background-image: url('../../../img/icons/V2 TBC.svg');
        width: 52px;
        height: 52px;
    }

    &.v2-space {
        background-image: url('../../../img/icons/V2 Space.svg');
        width: 52px;
        height: 52px;
    }

    &.my-add {
        background-image: url('../../../img/icons/my-add.svg');
    }

    &.my-update {
        background-image: url('../../../img/icons/my-update.svg');
    }

    &.end-time {
        background-image: url('../../../img/icons/end-time.svg');
    }

    &.end-time-sort {
        background-image: url('../../../img/icons/end-time-sort.svg');
    }

    &.cookies {
        background-image: url('../../../img/icons/coockie.svg');
    }

    &.store-badge {
        background-image: url('../../../img/icons/Store.svg');
    }

    &.user-badge {
        background-image: url('../../../img/icons/User.svg');
    }

    &.like-new-badge {
        background-image: url('../../../img/icons/Like new.svg');
    }

    &.secoondary-badge {
        background-image: url('../../../img/icons/Secondary.svg');
    }

    &.new-badge {
        background-image: url('../../../img/icons/New.svg');
    }

    &.clear-filter {
        background-image: url('../../../img/icons/clear-filter.svg');
    }

    &.add-form-info {
        background-image: url('../../../img/icons/add-form-info.svg');
    }

    &.applePay-i {
        background-image: url('../../../img/icons/applePay.svg');
    }

    &.applePayCard-i {
        background-image: url('../../../img/icons/applePayCard.svg');
    }
    &.filter-Back-Arrow-i {
        background-image: url('../../../img/icons/filterBackArrow.svg');
    }

    &.applePayment {
        background-image: url('../../../img/icons/applePay.svg');
        //width: 60px !important;
        //height: 32px;
    }

    &.safety-1 {

        background-image: url('../../../img/icons/document-text.svg');

        width: 56px;

        height: 56px;

        border-radius: 50%;

        background-color: #f1f3f6;

        background-size: 24px;

    }

    &.safety-2 {

        background-image: url('../../../img/icons/mobile.svg');

        width: 56px;

        height: 56px;

        border-radius: 50%;

        background-color: #f1f3f6;

        background-size: 20px;

    }

    &.safety-3 {

        background-image: url('../../../img/icons/global.svg');

        width: 56px;

        height: 56px;

        border-radius: 50%;

        background-color: #f1f3f6;

        background-size: 24px;

    }

    &.shop-icon-card {
        background-image: url('../../../img/icons/shop-icon-card.svg');
    }

    &.user-icon-card {
        background-image: url('../../../img/icons/user-icon-card.svg');
    }

    &.grey-info {
        background-image: url('../../../img/icons/grey-info.svg');
    }

    &.return-icon {
        background-image: url('../../../img/icons/return-icon.svg');
    }

    &.return-delete {
        background-image: url('../../../img/icons/return-delete.svg');
    }

    &.return-success {
        background-image: url('../../../img/icons/return-success.svg');
    }

    &.fotball-i {
        background-image: url('../../../img/icons/Sports icon.png');
    }

    &.like-i {
        background-image: url('../../../img/icons/like.svg');
    }

    &.dislike-i {
        background-image: url('../../../img/icons/dislike.svg');
    }

    &.faq-no-i {
        background-image: url('../../../img/icons/faq-pay.svg');
    }

    &.card-online-i {
        background-image: url('../../../img/icons/card-online.svg');
    }

    &.boxarrow {
        background-image: url('../../../img/icons/boxarrow.svg');
    }

    &.instructions-i {
        background-image: url('../../../img/icons/instructions-i.svg');
    }

    &.add-address-i {
        background-image: url('../../../img/icons/add-address.svg');
    }

    &.drp-arrow-i {
        background-image: url('../../../img/icons/drp-arrow.svg');
    }

    &.pr-edit-i {
        background-image: url('../../../img/icons/pr-edit.svg');
    }

    &.time-color {
        background-image: url('../../../img/icons/timecolor.svg');
    }

    &.time-vip {
        background-image: url('../../../img/icons/timevip.svg');
    }

    &.time-update {
        background-image: url('../../../img/icons/timeupdate.svg');
    }

    &.my-producfilter-i {
        background-image: url('../../../img/icons/my-productfilter.svg');
    }

    &.sorting-i {
        background-image: url('../../../img/icons/sorting.svg');
    }

    &.gel-i {
        background-image: url('../../../img/icons/myprs-gel.svg');
    }

    &.myprs-date-i {
        background-image: url('../../../img/icons/myprs-date.svg');
    }

    &.myprs-views-i {
        background-image: url('../../../img/icons/myprs-views.svg');
    }

    &.views-i {
        background-image: url('../../../img/icons/views.svg');
    }

    &.pr-add-time-i {
        background-image: url('../../../img/icons/pr-add-time.svg');
    }
    &.error-i {
        background-image: url('../../../img/icons/error.svg');
    }

    &.pick-up-i {
        background-image: url('../../../img/icons/pick-up.svg');
        width: 42px;
        height: 42px;
    }

    &.pick-up-blue-i {
        background-image: url('../../../img/icons/pick-up-blue.svg');
    }

    &.delivery-blue-i {
        background-image: url('../../../img/icons/delivery-blue.svg');
    }

    &.pick-both-i {
        background-image: url('../../../img/icons/both.svg');
    }

    &.copy-black-i {
        background-image: url('../../../img/icons/copy-black.svg');
        width: 24px;
        height: 24px;
    }

    &.c2csell-i {
        background-image: url('../../../img/icons/c2csell.svg');
    }

    &.c2cdelivery-i {
        background-image: url('../../../img/icons/c2cdelivery.svg');
    }

    &.c2cloan-i {
        background-image: url('../../../img/icons/c2cloan.svg');
    }

    &.c2cpay-i {
        background-image: url('../../../img/icons/c2cPay.svg');
    }

    &.update-auto-i {
        background-image: url('../../../img/icons/update-auto-i.svg');
    }

    &.add-color-i {
        background-image: url('../../../img/icons/add-color-i.svg');
    }

    &.vips-i {
        background-image: url('../../../img/icons/vips-i.svg');
    }

    &.return-pr {
        background-image: url('../../../img/icons/return-pr.svg');
    }

    &.maincat-arr-i {
        background-image: url('../../../img/icons/maincat-arr.svg');
    }

    &.langs-i {
        background-image: url('../../../img/icons/langs.svg');
    }

    &.arrow-down-black-i {
        background-image: url('../../../img/icons/arrow-down-black.svg');

        &.up {
            transform: rotate(180deg);
        }
    }

    &.staySafe-i {
        background-image: url('../../../img/icons/staySafe.svg');
    }

    &.dropdown-arrow-down {
        background-image: url('../../../img/icons/dropdown-arrow-down.svg');

        &.up {
            transform: rotate(180deg);
        }
    }

    &.tnet-platforms {
        background-image: url('../../../img/icons/TNETPlatformsLogo.svg');
        width: 66px;
    }

    &.visa-prom {
        background-image: url('../../../img/icons/VisaProm.svg');
        width: 37px;
    }



    &.plus-new-rounded {
        background-image: url('../../../img/icons/plus-new-rounded.svg');
    }

    &.delete-bin {
        background-image: url('../../../img/icons/delete-bin.svg');
        width: 24px;
        height: 24px;
    }

    &.arrow-blue-right {
        background-image: url('../../../img/icons/arrow-blue-right.svg');
    }

    &.credo-pay-i {
        background-image: url('../../../img/icons/credo-pay.svg');
    }

    &.tbc-bank-icon {
        background-image: url('../../../img/icons/tbc-bank-icon.svg');
    }
}

@each $name,
$value in $theme-colors {
    .icon-#{$name} {
        svg {
            rect {
                stroke: $value;
            }

            circle,
            .path,
            path {
                fill: $value !important;
            }
        }
    }

    .stroke-#{$name} {
        svg {
            rect {
                stroke: $value;
            }

            .path,
            path {
                stroke: $value !important;
            }
        }
    }
}


@each $name,
$value in $theme-colors {
    .icon-#{$name} {
        svg {
            rect {
                stroke: $value;
            }

            circle,
            .path,
            path {
                fill: $value !important;
            }
        }
    }

    .stroke-#{$name} {
        svg {
            rect {
                stroke: $value;
            }

            .path,
            path {
                stroke: $value !important;
            }
        }
    }
}