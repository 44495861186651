
.add-address-content {
    border: solid 1px $gray;
    Select {
        border: solid 1px $gray;
    }
}
.footer-btn {
    border-top: solid 1px $gray;
}

.checkout-address {
    .right-side {
        width: 100%;
        max-width: 100%;
    }
}

.paymentIcon {
    width: 32px!important;
    height: 32px!important;
}
.sg-selectbox-container {
    &.payment-select {
        .sg-selectbox__menu  {
            overflow: hidden;
        }
        .sg-selectbox__menu-list {
            margin: 8px;
            &::-webkit-scrollbar  {
                width: 3px;
                display: block;
                border-radius: 8px;
            }
            &::-webkit-scrollbar-thumb  {
                width: 3px;
                border-radius: 8px;
                background-color: $market-color;
            }
            
        }
        .sg-selectbox__control {
            height: 56px;
        }
    }
}
.info-link  {
    cursor: pointer;
}
.form-group{
    .sg-selectbox__menu-list{
        div:hover{
            background-color:$gray  !important;
        }
        .sg-selectbox__menu-notice--no-options{
            padding: 0px;
            &:hover{
                background-color: transparent  !important;
                div:hover{
                    background-color: transparent  !important;
                }
            }
        }
    }
}
.pr-stiky-list {
    transform: translateY(15px);
}
.total-tooltip-info {
    .total-tooltip {
        opacity: 0;
        position: none;
        &::after {
            content: '';
            position: absolute;
            width: 0; 
            height: 0; 
            top: -5px;
            left: 50%;
            transform: translateX(-50%);
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #eaeefe;
        }
    }
    &:hover {
        .total-tooltip {
            opacity: 1;
            pointer-events: all;
        }
    }
}
