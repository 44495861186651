
  .safety-cover  {
    // background-image: url('../../../img/safety-illustration.jpg');
    // background-repeat: no-repeat;
    // background-size: 100%;
    position: relative;
    .safety-cover-img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
   
    }
    .cover-text {
      @media (max-width:767px) {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        overflow: hidden;
      }
      &.clamp-none {
        @media (max-width:767px) {
          -webkit-line-clamp: inherit;
        }
      }
 
    }
    .kmow-more {
      text-decoration: underline;
    }
    &::after {
      @media (max-width:767px) {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba($color: #000000, $alpha: .3);
      }
    }
  }
