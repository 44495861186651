.favorites-cards {
    .card {
        //min-height: 372px;
        .card-header {
            border-bottom-color: transparent;
            border-radius: $border-radius-xs;
        }
        &:hover {
            padding: 15px !important;
            border: solid 1px $dark-purple-blue !important;
            background-color: $light-purple-blue;
        }
    }
    hr {
        &.fav-hr {
            width: 48px !important;
            height: 2px;
            opacity: 0.25;
            border-radius: 2px;
            background-color: $blue-gray;
        }
    }
    i {
        &.cart-i {
            width: 16px;
            height: 16px;
        }
        &.favorites-white-i {
            width: 16px;
            height: 16px;
        }
    }
}

