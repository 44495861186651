@font-face {
  font-family: 'TBCXLight';
  src: url('../../../fonts/tbc/TBCXLight.eot');
  src: url('../../../fonts/tbc/TBCXLight.eot') format('embedded-opentype'),
  url('../../../fonts/tbc/TBCXLight.woff2') format('woff2'),
  url('../../../fonts/tbc/TBCXLight.woff') format('woff'),
  url('../../../fonts/tbc/TBCXLight.ttf') format('truetype');
}

@font-face {
  font-family: 'TBCXRegular';
  font-style: normal;
  font-weight: 400;
  src: url('../../../fonts/tbc/TBCXRegular.eot');
  src: url('../../../fonts/tbc/TBCXRegular.eot') format('embedded-opentype'),
  url('../../../fonts/tbc/TBCXRegular.woff2') format('woff2'),
  url('../../../fonts/tbc/TBCXRegular.woff') format('woff'),
  url('../../../fonts/tbc/TBCXRegular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'TBCXMedium';
  font-style: normal;
  font-weight: 500;
  src: url('../../../fonts/tbc/TBCXMedium.eot');
  src: url('../../../fonts/tbc/TBCXMedium.eot') format('embedded-opentype'),
  url('../../../fonts/tbc/TBCXMedium.woff2') format('woff2'),
  url('../../../fonts/tbc/TBCXMedium.woff') format('woff'),
  url('../../../fonts/tbc/TBCXMedium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'TBCXBold';
  font-weight: bold;
  font-style: normal;
  src: url('../../../fonts/tbc/TBCXBold.eot');
  src: url('../../../fonts/tbc/TBCXBold.eot') format('embedded-opentype'),
  url('../../../fonts/tbc/TBCXBold.woff2') format('woff2'),
  url('../../../fonts/tbc/TBCXBold.woff') format('woff'),
  url('../../../fonts/tbc/TBCXBold.ttf') format('truetype');
}

@font-face {
  font-family: 'TBCXBlack';
  font-weight: 900;
  src: url('../../../fonts/tbc/TBCXBlack.eot');
  src: url('../../../fonts/tbc/TBCXBlack.eot') format('embedded-opentype'),
  url('../../../fonts/tbc/TBCXBlack.woff2') format('woff2'),
  url('../../../fonts/tbc/TBCXBlack.woff') format('woff'),
  url('../../../fonts/tbc/TBCXBlack.ttf') format('truetype');
}

* {
  font-family: 'TBCXRegular', serif;
}


// // ru fonts
// @font-face {
// 	font-family: 'Helvetica-base';
// 	src: url('../../../fonts/ru/base/HelveticaNeueCyr-Roman.eot');
// 	src: local('../../../fonts/ru/base/HelveticaNeueCyr-Roman'),
// 		url('../../../fonts/ru/base/HelveticaNeueCyr-Roman.eot?#iefix') format('embedded-opentype'),
// 		url('../../../fonts/ru/base/HelveticaNeueCyr-Roman.woff') format('woff'),
// 		url('../../../fonts/ru/base/HelveticaNeueCyr-Roman.ttf') format('truetype');
// 	font-weight: normal;
// 	font-style: normal;
// }

// @font-face {
// 	font-family: 'Helvetica-medium';
// 	src: url('../../../fonts/ru/medium/HelveticaNeueCyr-Medium.eot');
// 	src: local('../../../fonts/ru/medium/HelveticaNeueCyr-Medium'),
// 		url('../../../fonts/ru/medium/HelveticaNeueCyr-Medium.eot?#iefix') format('embedded-opentype'),
// 		url('../../../fonts/ru/medium/HelveticaNeueCyr-Medium.woff') format('woff'),
// 		url('../../../fonts/ru/medium/HelveticaNeueCyr-Medium.ttf') format('truetype');
// 	font-weight: 500;
// 	font-style: normal;
// }
// @font-face {
// 	font-family: 'Helvetica-bold';
// 	src: url('../../../fonts/ru/bold/HelveticaNeueCyr-Bold.eot');
// 	src: local('../../../fonts/ru/bold/HelveticaNeueCyr-Bold'),
// 		url('../../../fonts/ru/bold/HelveticaNeueCyr-Bold.eot?#iefix') format('embedded-opentype'),
// 		url('../../../fonts/ru/bold/HelveticaNeueCyr-Bold.woff') format('woff'),
// 		url('../../../fonts/ru/bold/HelveticaNeueCyr-Bold.ttf') format('truetype');
// 	font-weight: bold;
// 	font-style: normal;
// }

// 
@font-face {
  font-family: 'Lari';
  src: url('../../../fonts/lari/lari.eot');
  src: url('../../../fonts/lari/lari.eot') format('embedded-opentype'),
  url('../../../fonts/lari/lari.woff') format('woff'),
  url('../../../fonts/lari/lari.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

$font-size: () !default;
$font-size: map-merge(
                (3: $font-3,
                        6: $font-6,
                        8: $font-8,
                        10: $font-10,
                        11: $font-11,
                        12: $font-12,
                        13: $font-13,
                        14: $font-14,
                        15: $font-15,
                        16: $font-16,
                        18: $font-18,
                        20: $font-20,
                        21: $font-21,
                        22: $font-22,
                        23: $font-23,
                        24: $font-24,
                        26: $font-26,
                        28: $font-28,
                        30: $font-30,
                        32: $font-32,
                        34: $font-34,
                        36: $font-36,
                        38: $font-38,
                        45: $font-45,
                        48: $font-48,
                        64: $font-64,
                ),
                $font-size
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $name, $value in $font-size {
      .font-size#{$infix}-#{$name} {
        font-size: $value !important;
      }
    }
    @each $name, $value in $font-size {
      .line-height#{$infix}-#{$name} {
        line-height: $value + 10 !important;
      }
    }
  }
}

.is-uppercase {
  text-transform: uppercase;
  -moz-font-feature-settings: 'case';
  -webkit-font-feature-settings: 'case';
  font-feature-settings: 'case' on;
}

.is-lowercase {
  text-transform: lowercase !important;
  -moz-font-feature-settings: normal !important;
  -webkit-font-feature-settings: normal !important;
  font-feature-settings: normal !important;
}

.font-base {
  font-family: $font-family-base;
  font-weight: normal;
}

.font-black {
  font-family: $font-black;
}

.font-base-caps {
  font-family: $font-family-base-caps;
  @extend .is-uppercase;
}

.font-medium {
  font-family: $font-family-medium;
  font-weight: 500 !important;
}

.font-medium-caps {
  font-family: $font-family-medium-caps;
  @extend .is-uppercase;
}

.font-bold {
  font-family: $font-family-bold;
}

.font-bold-caps {
  font-family: $font-family-bold-caps;
  @extend .is-uppercase;
}

.font-gel {
  font-family: $font-family-lari;
}

.text-normal {
  font-weight: normal;
  font-style: normal;
}


.line-through {
  text-decoration: line-through;
}

.is-uppercase {
  text-transform: uppercase;
  -moz-font-feature-settings: 'case';
  -webkit-font-feature-settings: 'case';
  font-feature-settings: 'case' on;
}

.lari {
  -moz-font-feature-settings: normal;
  -moz-font-language-override: normal;
  display: inline-block;
  font-family: Lari !important;
  font-size: inherit;
  font-size-adjust: none;
  font-stretch: normal;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 1;
  text-rendering: auto;
}

* {
  font-family: 'TBCXRegular';
}

// html:lang(ru) {
//     body {
//       font-family: 'Helvetica-base';
//     }
//     .font-base {
//       font-family: 'Helvetica-base';
//     }
//     .font-base-caps {
//       font-family: 'Helvetica-base';
//       text-transform: uppercase;
//     }
//     .font-medium {
//       font-family: 'Helvetica-medium';
//     }
//     .font-medium-caps {
//       font-family: 'Helvetica-medium';
//       text-transform: uppercase;
//     }
//     .font-bold {
//       font-family: 'Helvetica-bold';
//     }
//     .font-bold-caps {
//       font-family: 'Helvetica-bold';
//       text-transform: uppercase;
//     }
// }