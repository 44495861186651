@mixin rgba-bg-variant($parent, $color) {
  #{$parent} {
    background-color: $color !important;
  }
}
/*opacity*/
@mixin opacity-variant($parent, $value) {
  #{$parent} {
    opacity: $value !important;
  }
}
/*btn heights*/
@mixin btn-heights-variant($parent, $value) {
  #{$parent} {
    height: $value !important;
  }
}
/*border-width*/
@mixin border-width($parent, $width, $style) {
  #{$parent} {
    border-width: $width !important;
    border-style: $style;
  }
}
/*border-radius*/
@mixin border-radius($parent, $width) {
  #{$parent} {
    border-radius: $width !important;
  }
}
/*caret*/
@mixin caret-variant($dir, $width, $color,$border-radius) {
  /*direction conditions*/
  @if $dir==down {
    border-top: $width solid $color;
    border-right: $width solid transparent;
    border-bottom: 0;
    border-left: $width solid transparent;
  }
  @else if $dir==up {
    border-top: 0;
    border-right: $width solid transparent;
    border-bottom: $width solid $color;
    border-left: $width solid transparent;
  }
  @else if $dir==right {
    border-top: $width solid transparent;
    border-right: 0;
    border-bottom: $width solid transparent;
    border-left: $width solid $color;
  }
  @else {
    border-top: $width solid transparent;
    border-right: $width solid $color;
    border-bottom: $width solid transparent;
  }
  /**/
  @if $border-radius {
    border-radius: $border-radius;
  }
}
/*rounded object*/
@mixin rounded-object-variant($width, $color,$border-radius) {
  background-color: $color;
  width: $width;
  height: $width;
  border-radius: $border-radius;
}
/*transform spacing*/
@mixin transform-spacing-left($x, $y, $transform-x, $transform-y) {
  top:$y;
  left:$x;
  transform: translate($transform-x,$transform-y);
}
@mixin transform-spacing-right($x, $y, $transform-x, $transform-y) {
  top:$y;
  right:$x;
  transform: translate($transform-x,$transform-y);
}
/*path fill*/
@mixin path-fill($parent, $color) {
  #{$parent} {
    path {
      fill: $color;
      @include transition($transition-fill);
    }
    fill:$color;
    &.active,
    &:hover {
      path {
        fill: darken( $color, 30% );
      }
    }
  }
}
/*make-wide-container-max-widths*/
@mixin make-wide-container-max-widths($max-widths: $wide-container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}
/*make-small-container-max-widths*/
@mixin make-small-container-max-widths($max-widths: $small-container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}
/*make-responsive-overflow-x-scroll*/
@mixin make-responsive-overflow-x-scroll($values: $wide-container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $value in $values {
    @include media-breakpoint-down($breakpoint, $breakpoints) {
      max-width: $value;
    }
  }
}
/*make content-min height*/
@mixin make-content-min-height($min-heights: $content-min-heights, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $content-min-heights in $min-heights {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      min-height: calc(100vh - #{$content-min-heights});
    }
  }
}

@mixin focus-active() {
  &:focus,
  &:active {
    @content;
  }
}