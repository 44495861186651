$transparent: transparent;
$dark: #272a37;
$black:#000000;
$nLight-dark:#454B57;
$dark-text: #272a37;
$greymiddle:#6F7383;
$white: #ffffff;
$white-gray: #EBEEF6;
$gray: #e4e7ed;
$nm-gray:#F3F5F7;
$gray-light : #b0b9c9;
$light-gray: #f6f7fa;
$middle-gray : #f1f3f6;
$blue-light-gray:#EDF3FC;
$dark_brown: #1D0900;
$bg-gray-450: #f5f7f8;
$dark-gray: #454b57;
$light-white-gray: #F2F3F6;
$grey-light: #A4AEC1;
$blue: #4a6cfa;
$nBlue: #006AFF;
$blue-light:#ECF0FF;
$blue_2: #4b6dff;
$dark-blue: #325afa;
$tnet-blue: #3C74FF;
$secundary-blue: #5386E4;
$light-blue: #eaeefe;
$condBlue:#EDF0FE;
$condGreen:#EEF9EB;
$middle-blue: #c2cefd;
$blue-gray: #8996ae;
$blue-gray04: #F8F9FD;
$light-purple-blue: #ebefff;
$dark-purple-blue: #d4dcfa;
$purple: #9f5aff;
$purple-dark: #575CA2;
$green: #c3f2cb;
$light-green: #ebfaed;
$dark-green: #0ec604;
$pink: #ffbdba;
$light-pink: #ffe9e8;
$dark-orange: #ff641f;
$badge-orange: #FD541A;
$danger: #ff3b30;
$light-red:#F06543;
$new-red:#FF621F;
$red: red;
$light-danger:#F9D4D7;
$middle-red: #ED461D;
$error-red: #DF2935;
$market-color:#fec900;
$gray-650: #414141;
$darkmode-bg-blue:#83A8EC;
$darkmode-bg-dark:#25252D;
$darkmode-light-dark:#3F3F46;
$primary-text-color: #222;
$error-red-10: #FCEAEB;

$primary-yellow-color: #FFFAE5;
$darkmode-bg-light:#AEB5C9;
$black-70:#646464;
$gray-2:#B5B5B5;
$gray-3: #E8EBEF;
$gray-4: #E7E9EF;
$gray-5: #F9FAFB;
$gray-6: #AAA;
$gray-7: #EFF0F4;
$secondary-gray-10: #F7F7F7;
$secondary-gray: #F6F6F6;

$dark-market-color: #fec900;
$label : #9aadfc;
$label_error : #ff665e;
$text_label: #a3adc0;
$gray-transparent: #f3f4f6;
$transparent-gray: #f7f9fc;
$green-dark: #4cd964;
$primary : $dark-market-color;
$light-dark: #3C3E45;

$blue-tbc: #00aeef;
$blue-tbc-loan: #2B6ADE;
$blue-ukraine: #005bbb;
$space:#E24BE4;
$dark-beige:#FFE999;
$beige:#FFF6D6;

$light-blue-tbc: #EDF6FD;
$light-tbc: #5CB3EB;
$alert-bg-green: #1d872f;
$light-yellow:#FFD8A1;
$light-yel-yellow:#FFF6D6;
$ngray-dark:#6E7481;
$nmbluedark:#2E3D5C;
$blue-100:#2B6ADE;
$blue-80:#5588E5;
$loan-green:#028D71;
$black-70:#646464;
$gray-70: #C3C3C3;
$gray-20: #EEEEEE;
$black-50: #909090;
$purple-prom: #6425D8;
$yellow-20: #FEF2CC;
$green-100: #26B753;
$blue-accept: #1D41DC;
$pink-error: #FEE6E6;
$red-80: #F93333;

$bordergray-20: #EFF0F4;
$textgray: #AAAAAA;

//rgba
$rgba-white-10: rgba($white,0.1);
$rgba-white-50: rgba($white,0.5);
$rgba-white-30: rgba($white,0.3);
$rgba-white-15: rgba($white,0.15);
$rgba-white-40: rgba(221, 221, 221, 0.40);
$rgba-middle-gray-30: rgba(228, 231, 237, 0.3);
$rgba-middle-gray-50: rgba($middle-gray,0.5);
$rgba-gray-25 : rgba(137, 150, 174, 0.25);
$rgba-gray-11 : rgba(137, 150, 174, 0.11);
$rgba-gray-10: rgba(168,178,196,0.1);
$rgba-blue-gray-15: rgba($blue-gray,0.15);
$rgba-blue-gray-30: rgba($blue-gray,0.3);
$rgba-dark-gray-75: rgba($dark-gray,0.75);
$rgba-dark-gray-50: rgba($dark-gray,0.5);
$white-grey: rgba($white,0.60);
$rgba-blue-10: rgba(#4A6CFA,  0.10);
$rgba-green-10: rgba(#0EC604, 0.10);
$rgba-orange-10: rgba($dark-orange, .10);
$rgba-dark-10: rgba($dark, .10);
$rgba-dark-80: rgba($dark, .80);
$rgba-nBlue-8: rgba($nBlue, .08);
$rgba-blue-05 : rgba($blue, .05);
$rgba-backdrop: rgba(39,42,55,.95);
$rgba-ngray: rgba(#CAD7E5, .15);
$emoney: rgba(229, 240, 246, 1);
$rgba-blue-40: rgba(#325AFA , .4);
$rgba-gray-07:rgba(#9AADFC, .07);
$rgba-gray-11 : rgba(137, 150, 174, 0.11);
$rgba-yellow-01:rgba(254, 201, 0, 0.10);
$primary-80: rgba(254, 201, 0, 0.8);
/*theme-colors Merge Or overrides*/
$theme-colors: () !default;
$theme-colors: map-merge(
                (
                        'transparent': $transparent,
                        'dark': $dark,
                        'black': $black,
                        'white': $white,
                        'white-gray': $white-gray,
                        'gray': $gray,
                        'nm-gray':$nm-gray,
                        'gray-light': $gray-light,
                        'light-gray': $light-gray,
                        'grey-light': $grey-light,
                        'middle-gray': $middle-gray,
                        'blue-light-gray': $blue-light-gray,
                        'primary-text-color': $primary-text-color,
                        'primary-yellow-color': $primary-yellow-color,
                        'dark_brown': $dark_brown,
                        'dark-gray': $dark-gray,
                        'bg-gray-450': $bg-gray-450,
                        'blue': $blue,
                        'blue-light':$blue-light,
                        'secundary-blue':$secundary-blue,
                        'condBlue':$condBlue,
                        'condGreen': $condGreen,
                        'blue_2': $blue_2,
                        'gray-2': $gray-2,
                        'gray-3': $gray-3,
                        'dark-blue': $dark-blue,
                        'light-blue': $light-blue,
                        'middle-blue': $middle-blue,
                        'blue-gray': $blue-gray,
                        'light-purple-blue': $light-purple-blue,
                        'dark-purple-blue': $dark-purple-blue,
                        'purple': $purple,
                        'purple-dark': $purple-dark,
                        'green': $green,
                        'light-white-gray': $light-white-gray,
                        'light-green': $light-green,
                        'dark-green': $dark-green,
                        'pink': $pink,
                        'light-pink': $light-pink,
                        'dark-orange': $dark-orange,
                        'badge-orange': $badge-orange,
                        'danger': $danger,
                        'error-red': $error-red,
                        'light-red':$light-red,
                        'new-red':$new-red,
                        'red': $red,
                        'light-danger': $light-danger,
                        'middle-red': $middle-red,
                        'market-color': $market-color,
                        'dark-market-color': $dark-market-color,
                        'label': $label,
                        'label_error': $label_error,
                        'text_label': $text_label,
                        'gray-transparent': $gray-transparent,
                        'transparent-gray': $transparent-gray,
                        'green-dark': $green-dark,
                        'light-dark': $light-dark,
                        'blue-tbc': $blue-tbc,
                        'blue-tbc-loan': $blue-tbc-loan,
                        'space':    $space,
                        'light-tbc':$light-tbc,
                        'light-blue-tbc': $light-blue-tbc,
                        'alert-bg-green': $alert-bg-green,
                        'light-yellow': $light-yellow,
                        'light-yel-yellow': $light-yel-yellow,
                        'emoney': $emoney,
                        'rgba-white-10': $rgba-white-10,
                        'rgba-white-15': $rgba-white-15,
                        'rgba-blue-gray-15': $rgba-blue-gray-15,
                        'rgba-blue-gray-30' : $rgba-blue-gray-30,
                        'rgba-middle-gray-50': $rgba-middle-gray-50,
                        'rgba-dark-gray-75': $rgba-dark-gray-75,
                        'rgba-white-50': $rgba-white-50,
                        'rgba-white-30': $rgba-white-30,
                        'white-grey' : $white-grey,
                        'rgba-blue-10': $rgba-blue-10,
                        'rgba-green-10' : $rgba-green-10,
                        'rgba-orange-10': $rgba-orange-10,
                        'rgba-dark-10' : $rgba-dark-10,
                        'rgba-dark-80' : $rgba-dark-80,
                        'blue-uk' : $blue-ukraine,
                        'rgba-nBlue-8': $rgba-nBlue-8,
                        'nLight-dark' : $nLight-dark,
                        'rgba-gray-25' : $rgba-gray-25,
                        'rgba-gray-11' : $rgba-gray-11,
                        'ngray-dark' : $ngray-dark,
                        'gray-650' : $gray-650,
                        'greymiddle':$greymiddle,
                        'nmbluedark':$nmbluedark,
                        'blue-100':$blue-100,
                        'blue-80':$blue-80,
                        'rgba-blue-40': $rgba-blue-40,
                        'tnet-blue' : $tnet-blue,
                        'beige' : $beige,
                        'dark-beige': $dark-beige,
                        'loan-green':$loan-green,
                        'darkmode-bg-blue' : $darkmode-bg-blue,
                        'bordergray-20': $bordergray-20,
                        'textgray': $textgray,
                        'darkmode-bg-dark' : $darkmode-bg-dark,
                        'darkmode-light-dark' : $darkmode-light-dark,
                        'darkmode-bg-light' : $darkmode-bg-light,
                        'rgba-white-40': $rgba-white-40,
                        'black-70' : $black-70,
                        'rgba-yellow-01': $rgba-yellow-01,
                        'gray-4': $gray-4,
                        'gray-5': $gray-5,
                        'gray-6': $gray-6,
                        'gray-7': $gray-7,
                        'secondary-gray-10': $secondary-gray-10,
                        'secondary-gray': $secondary-gray,
                        'primary-80': $primary-80,
                        'blue-gray04': $blue-gray04,
                        'gray70': $gray-70,
                        'black-50': $black-50,
                        'gray-20': $gray-20,
                        'purple-prom': $purple-prom,
                        'yellow-20': $yellow-20,
                        'error-red-10': $error-red-10,
                        'green-100': $green-100,
                        'blue-accept': $blue-accept,
                        'pink-error': $pink-error,
                        'red-80': $red-80,
                        ),
                $theme-colors
);
/*colors Merge Or overrides*/
/*$colors: () !default;
$colors: map-merge(
    (
      'white': $white,
    ),
    $colors
);*/
/*RGBA backgrounds*/

$rgba-colors: () !default;
$bg-gradient: linear-gradient(76deg, #fec900, #ffd026);
$button-bg-gradient: linear-gradient(80deg, #fec900, #ffd026);
$gradient-yellow-white: linear-gradient(90deg, #FED433 0%, #EEE 100%);
$hidden-item-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.00) -52.22%, #FFF 100%);

$rgba-colors: map-merge(
                (
                        'middle-gray-30': $rgba-middle-gray-30,
                        'white-10': $rgba-white-10,
                        'bg-gradient': $bg-gradient,
                        'button-bg-gradient': $button-bg-gradient,
                        'rgba-blue-10':$rgba-blue-10,
                        'blue-10': $rgba-blue-10,
                        'gray-10': $rgba-gray-10,
                        'rgba-blue-05': $rgba-blue-05,
                        'backdrop': $rgba-backdrop,
                        'dark-gray-50': $rgba-dark-gray-50,
                        'rgba-ngray': $rgba-ngray,
                        'rgba-gray-07': $rgba-gray-07,
                        '': $hidden-item-gradient,
                        
                ),
                $rgba-colors
);
/*opacity*/
$opacity-1: 1;
$opacity-80: 0.8;
$opacity-70: 0.7;
$opacity-60: 0.6;
$opacity-50: 0.5;
$opacity-40: 0.4;
$opacity-30: 0.3;
$opacity-25: 0.25;
$opacity-20: 0.2;
$opacity-15: 0.15;
$opacity-10: 0.1;
$opacity-0: 0;
$opacity: () !default;
$opacity: map-merge(
                (
                        '80':  $opacity-80,
                        '70':  $opacity-70,
                        '60':  $opacity-60,
                        '50':  $opacity-50,
                        '40':  $opacity-40,
                        '30':  $opacity-30,
                        '25':  $opacity-25,
                        '20':  $opacity-20,
                        '15':  $opacity-15,
                        '10':  $opacity-10,
                        '0':   $opacity-0,
                        '1' :  $opacity-1
                ),
                $opacity
);
/*spacer*/
$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
                (
                        0:    0,
                        1px:  1px,
                        2px:  2px,
                        3px:  3px,
                        4px:  4px,
                        5px:  5px,
                        6px:  6px,
                        7px:  7px,
                        8px:  8px,
                        10px:  10px,
                        11px:  11px,
                        12px:  12px,
                        13px:  13px,
                        14px:  14px,
                        15px:  15px,
                        16px:  16px,
                        18px:  18px,
                        20px:  20px,
                        21px:  21px,
                        22px:  22px,
                        23px:  23px,
                        24px:  24px,
                        25px:  25px,
                        27px:  27px,
                        28px:  28px,
                        30px:  30px,
                        32px:  32px,
                        34px:  34px,
                        36px:  36px,
                        40px:  40px,

                        44px: 44px,
                        48px: 48px,
                        52px: 52px,
                        56px: 56px,
                        64px: 64px,
                        60px: 60px,
                        68px: 68px,
                        72px: 72px,
                        80px: 80px,
                        84px: 84px,
                        88px: 88px,
                        95px: 95px,
                        100px: 100px,
                        108px: 108px,
                        110px: 110px,
                        120px: 120px,
                        128px: 128px,
                        130px: 130px,
                        140px: 140px,
                        150px: 150px,
                        160px: 160px,
                        170px: 170px,
                        200px: 200px,
                        210px: 210px,
                        225px: 225px,
                        235px: 235px,
                        240px: 240px,
                        250px: 250px,
                ),
                $spacers
);
/*borders-width*/
$borders-width: ();
$borders-width: map-merge(
                (
                        0: 0,
                        1: 1px,
                        2: 2px,
                        3: 3px,
                        10: 10px,
                ),
                $borders-width
);
$border-radius-10:       10px;
//
$border-radius:       8px;
$border-radius-xs:    6px;
$border-radius-st:    10px;
$border-radius-sm:    12px;
$border-radius-md:    16px;
$border-radius-lg:    24px;
$border-radius-xl:    26px;
$border-radius-42:    42px;
$border-radius-44:    44px;
$border-radius-55:    55px;
$border-radius-sizes: ();
$border-radius-sizes: map-merge(
  (
          0: 0,
          6:  $border-radius-xs,
          8:  $border-radius,
          10: $border-radius-st,
          12: $border-radius-sm,
          16: $border-radius-md,
          24: $border-radius-lg,
          26: $border-radius-xl,
          44: $border-radius-44,
        42: $border-radius-42,
          55: $border-radius-55
  ),
  $border-radius-sizes
);
/*fonts*/
$font-family-base: 'TBCXRegular';
$font-family-base-caps: 'TBCXRegular';
$font-family-medium: 'TBCXMedium';
$font-family-medium-caps:'TBCXMedium';

$font-family-bold: 'TBCXBold';
$font-family-bold-caps: 'TBCXBold';
$font-black: 'TBCXBlack';
$font-family-lari: 'Lari';
/*Font Size*/
$font-3: 3px;
$font-6:  6px;
$font-8:  8px;
$font-10: 10px;
$font-11: 11px;
$font-12: 12px;
$font-13: 13px;
$font-14: 14px;
$font-15: 15px;
$font-16: 16px;
$font-18: 18px;
$font-20: 20px;
$font-21: 21px;
$font-22: 22px;
$font-23: 23px;
$font-24: 24px;
$font-26: 26px;
$font-28: 28px;
$font-30: 30px;
$font-32: 32px;
$font-34: 34px;
$font-36: 36px;
$font-38: 38px;
$font-45: 45px;
$font-48: 48px;
$font-53: 53px;
$font-64: 64px;
/*$drop-shadpw*/
$drop-shadpw: 0 30px 60px #0000000F;
/*Hover opacity variables*/
$opacity-100: 1;
$opacity-80: 0.8;
/*$transition-fill*/
$transition-time : .15s;
$transition-time-slow : .5s;
$transition-fill:  fill $transition-time ease-in-out;
/*$transition-bg-fill*/
$transition-bg:  background $transition-time ease-in-out;
/*$transition-all*/
$transition-all:  all $transition-time ease-in-out;
$transition-all-slow:  all $transition-time-slow ease-in-out;
/*default transitions*/
$border-color-transition: border .15s ease-in-out;
$text-color-transition: color .15s ease-in-out;
$emphasized-link-hover-darken-percentage : 0;
/*Header*/
$link-color:                              $blue;
//$link-decoration:                         none;
//$link-hover-color:                        darken($link-color, 0%);
//$link-hover-decoration:                   underline;

/*sizes*/
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-m: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1600px;

/*grid-breakpoints*/
$grid-breakpoints: (
        xs: 0,
        sm: $breakpoint-sm,
        md: $breakpoint-md,
        m: $breakpoint-m,
        lg: $breakpoint-lg,
        xl: $breakpoint-xl
);
$grid-breakpoints-rev: (
        xs: 576px,
        sm: 768px,
        m: 1024px,
        md: 1200px,
        lg: 1600px,
);
/*$container-max-widths*/
$container-max-widths: (
        lg: 1200px,
        xl: 1302px
);
/*$overflows*/
$overflows: (
        auto,
        initial,
        hidden,
        scroll,
);
/*z-index*/
$z-index: (
        1,
        2,
        3,
        9,
        1000,
        1001,
        1002,
);


//old variables

// $roman: "Helvetica Neue LT GEO 55 Roman";
// $romanCaps: "Helvetica Neue LT GEO 55 Roman Caps";
// $medium: "Helvetica Neue LT GEO 65 Medium";
// $mediumCaps: "Helvetica Neue LT GEO 65 Medium Caps";
// $bold: "Helvetica Neue LT GEO 75 Bold";
// $boldCaps: "Helvetica Neue LT GEO 75 Bold Caps";
