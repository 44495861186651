
.content_forms {
    border: 1px solid red;
}

.round_ {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 2px solid #d7dce4;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    position: relative;
   &:after {
        content: "";
        position: absolute;
        width: 2px;
        height: 20px;
        background: #d7dce4;
        left: 4px;
        top: 18px;
        border-radius: 2px;
        transition: 1s;
        -webkit-transform-origin: top;
        transform-origin: top;
    }
    .text_ {
        position: absolute;
        left: 50px;
        top: 50px;
    }
}
.active .round_ {
    border: 2px solid #f25777;
}

.file_input_inner {
    border: dashed 2px #fec900;
}
.open-shop {
    width: 100%;
    @media (min-width:1200px) {
        max-width: 624px;
    }
    .form-group {
        .form-control {
            &::placeholder {
                font-size: 14px;
                color: $dark;
                font-family: $font-family-base;
            }
        }
        button {
            border: solid 1px #e4e7ed !important;
            &.btn {
                padding: 12px 14px;
            }
            &.add-address {
                border: 1px solid transparent !important;
            }
        }
        textarea {
            height: 230px;
            &::-webkit-resizer {
                background: url("../../../img/icons/textarea-resizer.svg") no-repeat;
                background-size: 50% 50%;
            }
        }
        .add-statement-i {
            width: 16px;
            height: 16px;
        }
    
    }

 

}
.selectbox-container {
    z-index: 1000;
}
.legal-info {
  
    .selectbox {
            &.__control {
                width: 110px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
    }
}
.steps-line {
    position: relative;
    @media (max-width:1024px) {
        margin-top: 24px;
    }
    li {
        margin-bottom: 25px;
        position: relative;
        a {
            display: flex;
            align-items: center;
            .round_ {
                width: 14px;
                height: 14px;
                border-radius: 50%;
                border: 2px solid #d7dce4;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 16px;
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    width: 2px;
                    height: 20px;
                    background: #d7dce4;
                    left: 4px;
                    top: 18px;
                    border-radius: 2px;
                    transition: 1s;
                    -webkit-transform-origin: top;
                    transform-origin: top;
                }
                .inner_round {
                    display: flex;
                    flex-shrink: 0;
                    background: #d7dce4;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                }
                .checked_icon {
                    display: none;
                    align-items: center;
                    flex-shrink: 0;
                    width: 6px;
                    height: 4px;
                 
                }
            }
            .text_ {
                color: #8996ae;
                font-size: 14px;
            }
            &.checked {
                .inner_round {
                    display: none;
                }
                .round_ {
                    background-color: $market-color;
                    border: 2px solid transparent;
                   
                }
                .checked_icon {
                    display: flex;
                }
            }
        }
    }
    &:last-child {
        .round_ {
            &::after {
                display: none;
            }
        }
    }
}
// chappie
.open-shop {
    .fixed-step {
        position: sticky;
        top: 50px;
    }
}
.upload-img-container {
    width: 185px;
    height: 85px;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        border-radius: 24px;
        &.edit {
            object-fit: cover;
        }
    }
}
.file_input_inner {
    &.uploaded {
        .change-onhover {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(39, 42, 55, 0.95);
            opacity: 0;
            pointer-events: none;
            transition: .3s ease-in-out;
        }
        &:hover {
            .change-onhover {
                opacity: 1;
                pointer-events: all; 
            }
         
        }
    }
}

.about-org-select {
    .form-control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        &:focus {
            & ~  
             .selectbox-container {
                .selectbox  {
                    border-color: #9aadfc;
                }
            }
        }
    }
    .selectbox-container {
        .selectbox  {
           cursor: pointer;
        }
    }
}