.pay-content {
    .pay-check {
        .custom-control {
            box-shadow: 0 0 0 1px #E9EAEB inset;
            &.active {
                box-shadow: 0 0 0 2px $green-100 inset;
            }
            &:hover:not(.active,.expired) {
                box-shadow: 0 0 0 1px #BEBFC3 inset;
                transition: 0.3s;

                .custom-control-label:not(.active) {
                    &::before {
                        border: 2px solid #1ABA6B;
                        box-shadow: 0 0 0 4px rgba(26, 186, 107, 0.20);

                    }
                }
            }

            &.expired {
                .custom-control-label {
                    &::before {
                        opacity: 0.3;
                    }
                }
            }

            .custom-control-label {
                padding-left: 40px;
                &::before {
                    position: absolute;
                    //opacity: .25;
                    height: 24px;
                    width:24px;
                    //background-color: rgba(137,150,174,.1);
                    border: 2px solid #A9AAAF;
                    margin-right: 16px;
                }
                &.active::after {
                    background-image: url(../../../img/icons/check-circle-modal.svg)!important;
                    background-size: 100% 100%;
                    height: 24px;
                    width: 24px;
                    background-repeat: no-repeat;
                }
                &.balance {
                    &::before {
                        margin-top: 8px;
                    }
                    &::after {
                        margin-top: 8px;
                    }
                    &.balance-error {
                        &::after {
                            background-image: url(../../../img/icons/label-error.svg)!important;
                        }
                    }
                }
            }
        }

        .hidden-item-gradient {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) -52.22%, #FFF 100%);
        }
    }
    .popup-footer {
        button {
            padding: 16px 42px;
        }
    }
}

.payment-options-saved-cards-modal {
    @media (min-width:768px) {
        max-height: 460px;
        overflow-y: auto;
    }
}

.payment-options-cards-modal {
    @media (min-width:768px) {
        max-height: 540px;
        overflow-y: auto;
    }
}