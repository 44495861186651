.custom-alert {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 95%;
  bottom: 15px;
  z-index: 9999999;
}
.cookie-frame {
  max-width: 826px;
  border-radius: 12px;
  padding: 0 5px;
  border: 1px solid #EBEEF6;
  box-shadow: 0px 4px 36px 0px #0000001A;

}
.custom-alert.cookie-frame p {
  font-size: 14px;
  line-height: 1.5;
  color: #6c6c74;
  margin: 0;
}

