.order-sort {
    border-radius: 10px;
    // border: solid 2px rgba(137, 150, 174, 0.1);
    padding:0;
    
    .dropdown-toggle {
        background-color: transparent!important;
        color: #272a37!important;
        &::after {
            display: none;
        }
    }
    .sort-arrow  {
        pointer-events: none;
        cursor: pointer;
        margin-left: 64px!important;
    }
    .dropdown {
        &.show {
            .sort-arrow {
                transform: rotate(-180deg);
            }
            .dropdown-menu {
                transform: translateY(15px);
                opacity: 1;
                visibility: visible;
                left: 0;
                right: initial;
                height: 300px;
                overflow-y: auto;
                @media (max-width:767px) {
                    height: 160px;
                }
                .dropdown-item {
                    white-space: pre-wrap;
                    cursor: pointer;
                }
            }
        }
    }
    div:not(.sort-arrow ) {
        width: 100%;
    }
    button {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
}
.orders-header {
    .dropdown-box {
        @media (max-width:767px) {
            width: 100%;
            max-width: 100%!important;
            margin-top: 28px!important;
        }
        .react-datepicker-wrapper {
            width: 140px;
           &:nth-child(1) {
            margin-right: 8px;
           }
            @media (max-width:1365px) {
                width: 100px;
            }
            @media (max-width:767px) {
                width: 100%;
                
            }
            input {
                width: 100%;
                &:focus {
                    outline: none;
                }
            }
            &:nth-child(2) {
                // margin-right: 0!important;
            }
        }
        &.status-box {
            min-width: 200px;
            max-width: 200px;
            .sg-selectbox__control{
                height:42px;
            }
            .order-sort  {
                height: 42px;
                border: 1px solid #dee2e6 !important;
                border-radius: 10px;
               & > div {
                    height: 100%;
                    & > div {
                        height: 100%;
                        & > div {
                            height: 100%;
                            & > button {
                                height: 100%;
                                padding-right: 24px!important;
                                padding-left: 24px!important;
                                span {
                                    max-width: 200px;
                                
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .search-input {
        border: none!important;
        border-left: 1px solid #f1f3f6!important;
        border-radius: 0!important;
        &::placeholder {
            color: #272a37;
        }
        &.search-input-tradein {
            &::placeholder {
              font: size 14px !important;
            }
        }
    }
    .filter-search-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        border-radius: 10px;
        border: solid 1px #e4e7ed;
        background-color: #ffffff;
    }
    .dropdown-boxs {
        @media (max-width:767px) {
            flex-direction: column;
            width: 100%;
         
          
        }
    }
    @media (max-width:767px) {
        & > * {
            margin-left: 0!important;
            margin-right: 0!important;
        }
    }
    .order-header-search {
        @media (max-width:767px) {
            margin-top: 28px!important;
            .form-group {
                border: 1px solid #dee2e6 !important;
                border-radius: 10px;
                height: 42px;
                overflow: hidden;
                input {
                    height: 100px;
                    border-left: none!important;
                }
                .filter-search-icon {
                    display: none;
                }
            }
         
        }
    }
}

.orders-box  {
    .order-box-header {
        padding-bottom: 18px;
        border-bottom:1px solid #f3f4f6;
    }
    .adrress-width {
        max-width: 260px;
        @media (max-width:1200px) {
            max-width: 200px;
        }
    }
    .product-box {
        .product-img  {
            width: 80px;
            height: 80px;
            overflow: hidden;
            border-radius: 8px;
            border: solid 1px #e4e7ed;
            flex-shrink: 0;
             img {
                 width: 100%;
                 height: 100%;
                 object-fit: cover;
             }
             @media (max-width:767px) {
                 width: 64px;
                 height: 64px;
             }
        }
        .shop-name  {
            color:  #575963;
        }
        &:last-child {
            margin-bottom: 0!important;
        }
        .pr-width {
            max-width: 410px;
            @media (max-width:767px) {
                max-width: 155px;
                font-size: 12px;
            }
        }
    }
}
.orders-box-footer  {
    padding-top: 26px;
    border-top:1px solid #f3f4f6;
    .dot {
        width: 4px;
        height: 4px;
        background-color: #e4e7ed;
        border-radius: 50%;
    }
    .order-details  {
        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .see-all {
        a {
            border: solid 1px #e4e7ed;
            border-radius: 10px;

        }
        &:hover {
            a {
                background-color: #e4e7ed ;
            }
        }
    }
}
.orders-box-details {
    width: 100%;
    max-width: 840px;
    // margin: 0 auto;
    @media (max-width:767px) {
        border-radius:  0 !important;
    }
}
.react-datepicker__close-icon {
    display: none!important;
}
.orders-boxs, .my-products-box  {
    .react-datepicker {
        background-color: #ffffff;
        border: solid 1px #e4e7ed;
        border-radius: 20px;
        overflow: hidden;
       
    }
    .react-datepicker__header {
        padding-top: 24px;
        border-color: #f3f4f6;
        background-color: #ffffff;
    }
    .react-datepicker__triangle {
        display: none;
    }
    .react-datepicker__day {
        border-radius: 50%;
        background-color: transparent;
        border: none;
        outline: none;
        &:hover {
            border-radius: 50%;
            background-color: #454b57;
            color: #ffffff;
        }
    }
    .react-datepicker__day--keyboard-selected  {
        background-color: #454b57;
    }
    .react-datepicker__navigation {
        border-radius: 50%;
        background-color: rgba($color: #8996ae, $alpha: .1);
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        top: 20px;
        &:hover {
            background-color: rgba($color: #8996ae, $alpha: .2);
        }
        &.react-datepicker__navigation--previous {
            left: 24px;
            &::after {
                content: '';
                width: 20px;
                height: 15px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-image: url('../../../img/icons/left-arr.svg');
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
            }
            span {
                display: none;
            }
        }
        &.react-datepicker__navigation--next {
            right: 24px;
            &::after {
                content: '';
                width: 20px;
                height: 15px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-image: url('../../../img/icons/right-arr.svg');
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
            }
            span {
                display: none;
            }
        }
    }
    .react-datepicker__month {
        margin: 24px;
    }
    input {
        outline: none!important;
    }
}
.order-btn {
    position: absolute;
    right: 15px;
    top: 0;
    z-index: 12;
    i {
        width: 32px;
        height: 32px;
    }
    @media (min-width:768px) {
        display: none!important;
    }
}
.return-item-btn {
    &:hover {
        background-color: #e4e7ed;
        border-color: transparent !important;
    }
}

button.return-item-btn-actions {
    &:hover {
        background-color: $blue !important;
        color: $white !important;
    }
}
.ConfirmReturnPolicy {
    height: 196px;
    overflow-y: auto;
    &::-webkit-scrollbar {
		width: 4px;
        height: 48px;
		display:block;
    }
	&::-webkit-scrollbar-thumb {
	    background-color: $blue;
	    border-radius: 6px;
	}
}

.rate-button-wrapper {
    padding: 12px 16px;
    border-radius: 8px;
    color:#CBA100;
    background: #FFFAE5;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;

    @media (max-width: $breakpoint-md) {
        width: 100%;
        justify-content: center;
    }
}

.rate-modal-inner {
    max-width: 560px !important;
    border-radius: 24px !important;
    padding: 24px 32px !important;
}

.show-rate-modal-inner {
    max-width: 560px !important;
    // border-radius: 12px 12px 0 0 !important;
}
.show-modal-inner {
    max-width: 978px !important;
    // border-radius: 12px 12px 0 0 !important;
    width: auto!important;
    
}
// .calc-margin {
//     margin-right: calc(-18px);
// }

.show-rate-modal-inner-with-images {
    max-width: 960px !important;
}
