
.shop-main-slider {
    .main-slide {
        position: relative;
        padding-top: 20%;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
        }
        .slider-content {
            padding-top: 84px!important;
            @media(max-width: 767px) {
                padding-top: 0!important;
            }
            span {
                line-height: 36px;
                @media(max-width: 767px) {
                    line-height: 24px;
                }
            } 
       
        }

    }
    .shop-slider-btn {
        right: 0;
        left: 0;
        bottom: 38px;
    }
    .control-btn {
        margin-left: auto;
        .shop-main-slider-btn {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%!important;
            background-color: #ffffff;
            box-shadow: 0 2px 6px 0 rgba(39, 42, 55, 0.1);
            z-index: 50;
            &.right-btn {
               margin-left: 6px;
            }
        }
        @media(max-width: 767px) {
            display: none!important;
        }
    }
    .swiper-pagination-bullet {
        background-color: #f1f3f6;
        opacity: 1;
        &.swiper-pagination-bullet-active {
            background-color: #8996ae;
        }
    }
}
.shop-col {
    .shop-sales-static-slide {
        .sl-card-name  {
            @media(max-width:767px) {
                -webkit-line-clamp: 1;
            }
        }
        height: 192px;
        margin-bottom: 20px;
        background-color: white;
        border-radius: 16px;
        overflow: hidden;
        @media(max-width:767px) {
            height: 120px;
        }
        .sl-card-img {
            height: 160px;
            margin-right: 38px;
            border-radius: 10px;
            overflow: hidden;
            margin-bottom: auto;
            margin-top: auto;
            @media(max-width:767px) {
                width: 80px;
                height: 80px;
                margin-top:  auto ;
                margin-bottom: auto;
            }
            img {
                width: 160px!important;
                height: 160px!important;
                border-radius: 10px;
                @media(max-width:767px) {
                    width: 80px!important;
                    height: 80px!important;
                }
            }
        }
        .slider-card {
            display: flex;
            padding: 13px 26px 13px 13px;
            .card-info {
                margin-top: 45px;
                position: relative;
                @media(max-width:767px) {
                    margin-top: 36px;
                }
              .sl-card-price  {
                  font-size: 18px!important;
                  color: #454B57!important;
                  @media(max-width:767px) {
                    display: inline-flex;
                    margin-right: 12px;
                }
                  &.sl-card-price-old  {
                    font-size: 14px!important;
                    color: #8996AE!important;
                    font-family: $font-family-medium!important;
                  }
              }
              .percent-box {
                // position: absolute;
                // top: -31px;
                margin-bottom: 8px;
                .offers-sale {
                   
                    line-height: 14px;
                    padding: 4px 7px;
                    border-radius: 11px;
                }
              }
         
            }
        }

    &.shop-all-product {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .sl-card-price-old  {
        position: relative;
        display: inline-block;
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: #8996AE ;
            top: 50%;
            transform: translate3d(0,-50%,0);
            left: 0;
        }
    }
}
}

.offers-section {
    // .offers-col  {
    //     flex: 0 0 33.33333%;
    //     max-width: 33.33333%;
    //     @media  (max-width:1023px) {
    //         flex: 0 0 50%;
    //         max-width: 50%;
    //         &:nth-child(3) {
    //             display: none;
    //         }
    //     } 
    //     @media  (max-width:767px) {
    //         flex: 0 0 100%;
    //         max-width: 100%;
    //         &:nth-child(2) {
    //             display: none;
    //         }
    //     } 
    // }
    .offers-item  {
        border-radius: 16px;
        background-color:$primary;
        cursor: pointer;
        height: 100%;
        height: 515px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @media (max-width:767px) {
            height:436px
        }
        img  {
            width: 100%;
           
            object-fit: contain;
            transition: .5s ease-in-out;
            @media  (max-width:1365px) {
                height: 200px;

            }
        }
        .see-more {
            opacity: 0;
            pointer-events: none;
            transform: translateY(5px);
            transition: .3s ease-in-out;
            bottom: 32px;
            left: 56px;
            @media (max-width:767px) {
                left:32px
            }
        } 
        &:hover {
            .see-more {
                opacity: 1;
                pointer-events: all;
                transform: translateY(0);
            } 
            img {
                transform: translateY(-10px);
            }
        }
    }
}
.shop-cats-item {

    .cat-img {
        transition: .3s ease-in-out;
        border: 2px solid;
        border-color: transparent;
    }
    &:hover {
        .cat-img {
            border-color: rgba($color: #8996AE, $alpha: .5);
            transform: scale(0.98);
        }
  
    }
}
.shop-continue-search {
    .container {
        .row {
            .slider-header {
                padding-left:15px !important;
                padding-right:15px !important;
            }
        }
        .continue-seacrh-slider {
            .swiper-container  {
                padding-left:15px !important;
                padding-right:15px !important;
            }
        }
    }
}