.top-alert {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    top: 50px;
    z-index: 999;
    .btn {
        &.btn-bordered {
            padding: 9px 16px;
            border: solid 2px rgba(255, 255, 255, 0.5);
        }
        &.close-popup {
            border-radius: 50%;
            &:hover {
                border-radius: 50%;
                background-color: rgba(255, 255, 255, 0.3);
            }
        }
    }
    &.top-alert-type-1 {
        top: 50px;
        background-color: $alert-bg-green;
    }
    &.top-alert-type-2 {
        top: 150px;
        background-color: $alert-bg-green;
    }
    &.top-alert-type-3 {
        top: 250px;
        background-color: $alert-bg-green;
    }
    &.top-alert-type-4 {
        top: 350px;
        background-color: $alert-bg-green;
    }
}