.my-webs {
    a {
        padding: 9px 18px 9px 18px;
        border: 2px solid #cad7e5;
        position: relative;
        .my-logo-hover {
            display: none;
            position: absolute;
            top: 6px;
        }
        &:hover {
            padding: 9px 4px 12px 18px;
            span {
                margin-left: 44px;
                background-color: $white;
                color: $dark-gray;
                padding: 10px 10px;
                border-radius: $border-radius-lg;
            }
            .my-logo-hover {
                display: block;
                position: absolute;
                top: 15px;
            }
            &.market-color {
                border: 2px solid $dark-market-color;
                background-color: $dark-market-color;
            }
            &.auto-color {
                border: 2px solid #ff8600;
                background-color: #ff8600;
            }
            &.parts-color {
                border: 2px solid #0d828f;
                background-color: #0d828f;
            }
            &.home-color {
                border: 2px solid #29b24e;
                background-color: #29b24e;
            }
            &.jobs-color {
                border: 2px solid #10bbe8;
                background-color: #10bbe8;
            }
            &.shop-color {
                border: 2px solid #6084ea;
                background-color: #6084ea;
            }
            &.ads-color {
                border: 2px solid #bb44a3;
                background-color: #bb44a3;
            }
        }
 
        &.active {
            padding: 9px 4px 12px 18px;
            span {
                margin-left: 44px;
                background-color: $white;
                color: $dark-gray;
                padding: 10px 10px;
                border-radius: $border-radius-lg;
            }
            .my-logo-hover {
                display: block;
                position: absolute;
                top: 15px;
            }
            &.market-color {
                border: 2px solid $dark-market-color;
                background-color: $dark-market-color;
            }
        }
    }
}

.cat-fish-box {
    bottom: 0;
    width: 100%;
    height: 56px;
    z-index: 99998;
    .close-catfish {
        width: 40px;
        height: 40px;
        position: absolute;
        right: 0;
        z-index: 99999;
    }
}

.nm-ado-footer {
    position: fixed;
    bottom: 8px;
    left: 0;
    right: 0;
    z-index: 99999;
    width: 100%;
    max-width: 728px;
    // height: 120px;
    overflow: hidden;
    margin: auto;


    @media (max-width:1100px) {
        //max-width: 768px;
        bottom: 76px;
      }

      @media (max-width:767px) {
        max-width: 320px;
        bottom: 77px;
      }

    .nm-af {
        position: relative;
        width: 100%;
        border-radius: 10px;
        height: 90px;
        overflow: hidden;

        @media (max-width:767px) {
            height: 100px;
        }
    }
}
.langs-select {
    .sg-selectbox__input {
        caret-color: transparent;
    }
    .sg-selectbox__control {
       
        &:hover {
            background-color: #F2F3F6;
            border-color:#BEBFC3 !important;
        }
        &.sg-selectbox__control--menu-is-open {
             border-color:#BEBFC3 !important;
            }
    }
    .sg-selectbox__menu  {
        width: 253px;
    }
    .sg-selectbox__menu-list {
        max-height: 200px;
    }
}
.langs-select-option {
    &:hover {
        background-color: #E9EAEB;
    }
}
.logos-hover {
    &:hover {
        opacity: .7;
        background-color: transparent;
    }
 }

 .tnet-footer-logo {
    background-color: #3C74FF;
    @media (max-width:1269px) {
        background-color: transparent;
        svg {
            path {
                fill: #3C74FF;
            }
        }
    }
 }
 .platform-logos {
    display: flex;
    @media (max-width:1269px) {
        display: none;
        &.active {
            display: flex;
        }
    }
 .item {
    width: 110px;
   @media (max-width:767px){
    width: calc(100% / 3);
   }
 }
}
.footer-logo-box {

    display: none;
    border-radius: 64px;
    @media (max-width:1269px) {
        display: flex;
        padding:28px 40px;
        flex-direction: column;
        border-radius: 22px;
        background-color: #F4F4F5;
        @media (max-width:767px) {
            padding:28px 16px;
        }
    }
    .tnet-footer-logo {
        justify-content: center;
        border-radius: 64px;
        height: 66px;
        @media (max-width:1269px) {
            justify-content: flex-start;
            height: 28px;
            padding-left: 16px;
        }  
        
    }
    .drop-arrow {
        display: none;
        @media (max-width:1269px) {
            display: flex;
        }   
        &.up {
            transform: rotate(180deg);
        } 
    }
    .head{
        @media (max-width:1269px) {
            width: 100%;
        }  
    }
}