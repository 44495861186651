.switcher-box  {
    .btn {
        &.active {
            background-color: $primary;
            &:hover {
                background-color: $primary !important;
            }
        }
       
    }
}