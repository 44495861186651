.card {
	border-color: #e4e7ed;
	// background-color: $white-light;
	border-radius: 8px;
	overflow: hidden;
	.card-header {
		border-bottom-color: #F5F5F5;
		position: relative;
		overflow: hidden;
		@media (min-width:1200px) {
			// width: 208px;
		}
		img.position-absolute {
			left: 0;
			top: 0;
			right: 0;
		}
		.toggle-fav {
			display: none;
			background-color: #f8f9fb;
			position: absolute;
			right: 10px;
			top: 10px;
			border-radius: 50%;
			align-items: center;
			justify-content: center;
			padding: 7px;
			&.active {
				background-color: $danger;
				display: flex;
				i {
					// background-image: url(../../../img/heart-full-white.svg);
				}
			}
			i {
				position: relative;
    			top: 1px;
				display: flex;
				width: 10px;
				height: 10px;
				// background-image: url(../../../img/heart-full-light.svg);
				background-position: center;
				background-repeat: no-repeat;
			}
			@media (max-width: $breakpoint-m) {
				display: flex;
			}
			@media (min-width: $breakpoint-m) {
	    		top: 15px;
	    		right: 15px;
	    		padding: 8px;
	    		i {
	    			width: 12px;
	    			height: 12px;
	    		}
	    	}
		}
	}
	.card-body {
        border-bottom: none;
    }
    &:hover {
    	.toggle-fav {
    		display: flex;
    	}
    }
}
.card_404  {
    .card  {
        border: 1px solid transparent!important;
        &:hover {
            border-color: $dark-purple-blue !important;
        }
    }
}