.side {
    float: left;
    width: 12%;
    i {
        width: 10px;
        height: 10px;
    }
}
.middle {
    margin-top:auto;
    margin-bottom: auto;
    float: left;
    width: 70%;
}
.right {
    text-align: left;
    padding-left: 16px;
}
.row:after {
    content: "";
    display: table;
    clear: both;
}
.bar-container {
    width: 100%;
    background-color: $rgba-blue-gray-15;
    border-radius: 5px;
    text-align: center;
    color: white;
}
.bar-percent{
    background-color: $market-color;
    height: 10px;
    border-radius: 5px;
}

.review-filter  {
    .btn-empty  {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .dropdown-menu {
      @media (max-width:767px) {
        width: 100%;
      }
    }
}
.bg-opacity {
    &::after {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        height: 100%;
        width: 100%;
        border-radius: 12px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%),
    }
}
.review-upload-images {
    div {
        @media (max-width:1270px) {
            &:nth-child(4) {
               display: none;
              }
          }
          @media (max-width:1135px) {
            &:nth-child(4) {
               display: none;
              }
          }
          @media (max-width:818px) {
            &:nth-child(4) {
               display: block;
              }
          }
          @media (max-width:575px) {
            &:nth-child(4) {
               display: block;
              }
          }
          @media (max-width:468px) {
            &:nth-child(4) {
               display: none;
              }
          }
        
    }
    
    
}
.product-review-images {
    flex-flow: wrap;
}