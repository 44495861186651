.vast-video {
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 24px;
    overflow: hidden;
}

.op-player__media {
    display: inline-block;
    -o-object-fit: contain;
    object-fit: contain;
    position: relative;
    width: 100%;
    height: 100%;
}

.op-player {
    width: 100%;
    height: 100%;
    .op-ads {
        position: absolute;
        top: -3px;
        left: 0px;
        z-index: 101;
        width: 100%;
        height: 100%;
        & div:first-child {
            height: 100% !important;
        }
    }
    .op-controls{
        bottom: 6px;
        .op-controls__volume--display {
            color: #fec900;
        }
    }
    // .op-controls,
    // .op-player__play,
    // .op-player__unmute,
    // .op-player__loader {
    //     // display: none
    // }
    .op-player__unmute{
        top: 5px;
        left: 5px;
        background: none;
    }
    .op-player__play {
        display: none;
    }
}
    