@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $value in $z-index {
      .z-index#{$infix}-#{$value} {
        z-index: $value;
      }
    }
  }
}

/*transforms*/

.transform-y-center {
  transform: translateY(-50%);
}

.absolute--right-center-position {
  top: 50%;
  transform: translateY(-50%);
  right: 0;

  &.transform-right-top {
    transform: translate(100%, -50%);
  }
}

.absolute--left-top-position {
  top: 0;
  left: 0;

  &.transform--100-0 {
    transform: translate(-100%, 0);
  }
}

.absolute--right-bottom-position {
  bottom: 0;
  right: 0;

  &.transform--50px-100 {
    transform: translate(50px, 100%);
  }
}

.transform-center {
  transform: translate(-50%, -50%) !important;
}

.transform-center-rev {
  transform: translate(50%, -50%);
}

.absolute--right-top-position {
  top: 0;
  right: 0;

  &.transform-right-top {
    transform: translate(100%, -50%);
  }

  &.absolute--transform-100-50 {
    transform: translate(100%, -50%);
  }

  &.absolute--transform-50 {
    transform: translate(50%, 0);
  }

  &.absolute--transform-x {
    right: 15px;
    transform: translateX(100%);
    padding-left: 15px;
    box-sizing: content-box;
    background-color: white;
  }
}

.absolute-x-center {
  left: 50%;
  transform: translateX(-50%);
}

.absolute--left-center-position {
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.absolute--right-center-position {
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.absolute--center-position {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute--center-bottom-position {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);

  &.transform--50-100 {
    transform: translate(-50%, 100%);
  }
}

.absolute--center-top-position {
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);

  &.transform--50-100 {
    transform: translate(-50%, 100%);
  }

  &.transform-n50-n100 {
    transform: translate(-50%, -100%);
  }
}

.fixed-x-center {
  left: 50%;
  transform: translateX(-50%);
}

/*rotates*/
.rotate {
  transform: rotate(0deg);
  transition: $transition-all;

  &.rotate-up {
    transform: rotate(180deg);
  }

  &.rotate-x {
    transform: rotate(45deg);
  }
}

.rotate-vertical {
  transform: rotateX(-180deg);
}

.rotate-45deg {
  rotate: (45deg);
}

.gallery-height {
  height: calc(100vh - 200px) !important;
  max-width: 824px;
  max-height: 550px;
}

/*Width/Height/Min-Width/Min-Height*/
$dimensions: (
  0,
  1px,
  2px,
  3px,
  4px,
  5px,
  6px,
  8px,
  9px,
  10px,
  11px,
  12px,
  13px,
  14px,
  15px,
  16px,
  18px,
  19px,
  20px,
  21px,
  22px,
  24px,
  26px,
  28px,
  30px,
  32px,
  33px,
  34px,
  35px,
  36px,
  38px,
  40px,
  44px,
  46px,
  48px,
  50px,
  52px,
  53px,
  54px,
  55px,
  56px,
  58px,
  60px,
  64px,
  65px,
  68px,
  70px,
  72px,
  73px,
  74px,
  76px,
  80px,
  85px,
  86px,
  90px,
  93px,
  95px,
  99px,
  100px,
  110px,
  112px,
  114px,
  120px,
  122px,
  126px,
  128px,
  130px,
  132px,
  134px,
  136px,
  138px,
  142px,
  150px,
  160px,
  164px,
  165px,
  170px,
  176px,
  180px,
  196px,
  208px,
  210px,
  216px,
  224px,
  230px,
  235px,
  240px,
  248px,
  258px,
  276px,
  280px,
  290px,
  295px,
  298px,
  300px,
  320px,
  335px,
  350px,
  354px,
  370px,
  380px,
  390px,
  408px,
  410px,
  450px,
  460px,
  474px,
  480px,
  500px,
  510px,
  520px,
  540px,
  550px,
  552px,
  580px,
  600px,
  604px,
  630px,
  652px,
  660px,
  700px,
  750px,
  770px,
  797px,
  840px,
  950px,
  978px
);

$gaps: (
  1px,
  2px,
  3px,
  4px,
  6px,
  7px,
  8px,
  9px,
  10px,
  11px,
  12px,
  13px,
  14px,
  15px,
  16px,
  17px,
  18px,
  19px,
  20px,
  21px,
  22px,
  23px,
  24px,
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop,
    $abbrev in (height:h, width:w, flex:w-basis, min-height:min-height, max-height:max-height, min-width:min-width, max-width:max-width, top:top, left:left, right:right, bottom:bottom) {
      @each $value in $dimensions {
        .#{$abbrev}#{$infix}-#{$value} {
          @if $prop==flex {
            width: $value !important;
            #{$prop}: 0 0 $value !important;
          }

          @else {
            #{$prop}: $value !important;
          }
        }
      }
    }

    @each $abbrev,
    $prop in (height:auto, width:auto, min-height:initial, max-height:initial, min-width:initial, max-width:initial) {
      .#{$abbrev}#{$infix}-#{$prop} {
        #{$abbrev}: $prop !important;
      }
    }

    @each $gap-size in $gaps {
      .gap#{$infix}-#{$gap-size} {
        gap: $gap-size !important;
      }
      .row-gap#{$infix}-#{$gap-size} {
        row-gap: $gap-size !important;
      }
      .column-gap#{$infix}-#{$gap-size} {
        column-gap: $gap-size !important;
      }
    }

  }
}

$step : 1;
$from : ceil(calc(1 / $step));
$through : ceil(calc(95 / $step));



@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop,
    $abbrev in (height:h, width:w, flex:w-basis, top:top, left:left, right:right, bottom:bottom) {

      @for $i from $from through $through {
        .#{$abbrev}#{$infix}-#{$i} {
          @if $prop==flex {
            width: $i*1% !important;
            #{$prop}: 0 0 $i*1% !important;
          }

          @else {
            #{$prop}: $i*1% !important;
          }
        }
      }

    }

    // 

    @each $prop,
    $abbrev in (top:top, left:left, right:right, bottom:bottom) {

      //  new
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} {
          @if $prop==flex {
            width: $length !important;
            #{$prop}: 0 0 $length !important;
          }

          @else {
            #{$prop}: $length !important;
          }
        }
      }

      // 

    }

    @each $prop in (relative, fixed, absolute, sticky, static) {

      @if $prop==sticky {
        .position#{$infix}-#{$prop} {
          position: $prop !important;
          position: -webkit-sticky;
        }
      }

      @else {
        .position#{$infix}-#{$prop} {
          position: $prop !important;
        }
      }
    }
  }
}

// 