.review-swal {
    padding: 108px 88px;
    .user-img {
        border-radius: 50%;
        width: 84px;
        height: 84px;
        border: 24px solid rgba($color: #8996ae, $alpha: .1);
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            object-fit: cover;
        }
    }
    .review-star {
     .rate-bg {
         width: 24px;
         height: 24px;
         margin-right: 16px;
         &:last-child {
             margin-right: 0;
         }
     }
     .rate  {
         width: 24px;
         height: 24px;
         .rate-box {
            width: 24px;
            height: 24px; 
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: 16px;
            &:last-child {
                margin-right: 0;
            }
            .rate-active {
                width: 24px;
                height: 24px;
                opacity: 0;
                transition: .3s ease-in-out;
            }
            &:hover {
                .rate-active  {
                    opacity: 1;
                }
             
            }
         }
       
     }
  
    }
}
#full-stars {


    .rating-group {
      display: inline-flex;
    }
    

    .rating__icon {
      pointer-events: none;
      width: 24px;
      height: 24px;
    }
    

    .rating__input {
     position: absolute !important;
     left: 0 !important;
     opacity: 0;
    }
  

    .rating__label {
      cursor: pointer;
      padding: 0 8px;
      opacity: 1;
      position: relative;
    & > 
    span {
        opacity: 0;
        position: absolute;
        top: 32px;
        left: 50%;
        transform: translateX(-50%);
        
    }
    &:hover {
        span {
            opacity: 1;
        } 
    }
      &:first-child {
          padding-left: 0;
          .rating__icon--star {
            svg {
                path {
                    // fill: #ff641f;
                    transition: .3s ease-in-out;
                }
            }
        }
        
      }
      &:last-child {
          padding-right: 0;
      }

    }
    

    .rating__icon--star {
        svg {
            path {
                // fill: #ff641f;
                transition: .3s ease-in-out;
            }
        }
    }
    

    .rating__icon--none {
        svg {
            path {
                fill: #f1f3f6;
            }
        }
    }
  

    

    // .rating__input:checked ~ .rating__label .rating__icon--star {
    //     svg {
    //         path {
    //             fill: #f1f3f6;
    //         }
    //     }
    // }
  

    //  .rating__label:hover .rating__icon--star {
    //     svg {
    //         path {
    //             fill: #ff641f;
    //         }
    //     }
    // }
//
.rating__label  {
    svg {
        path {
            fill: #f1f3f6;
        }
    }
  }

  .rating-group.active  {
    svg {
        path {
            fill: #ff641f;
        }
    }
  } 
  .rating__label {
      &.active {
        svg {
            path {
                fill: #ff641f!important;
            }
        }
      }
  }
  .rating__label.active,
  .rating__label.active ~ .rating__label {
    svg {
        path {
            fill: #f1f3f6;
        }
    }
  } 

// 


    .rating__input:hover ~ .rating__label .rating__icon--star {
        svg {
            path {
                fill: #f1f3f6;
            }
        }
    }

    .rating-group:hover .rating__input--none:not(:hover) + .rating__label .rating__icon--none {
        svg {
            path {
                fill: #f1f3f6;
            }
        }
    }
  
  }
.comment-swal {
    padding-top: 72px;
 

    textarea {
        min-height: 219px;
    }
}
.review-img  {
 
    .img-box {
        width: 78px;
        height: 50px;
        overflow: hidden;
        border-radius: 10px;
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &:hover {
            .hover-delete {
                opacity: 1;
                pointer-events: all;
            }
         
        }
    }
    .upload-input {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        
    }
    .hover-delete {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($color: #272a37, $alpha: .95);
        border: 0;
        padding: 0;
        opacity: 0;
        pointer-events: none;
        transition: .5s ease-in-out;
        .img-delete-i {
            width: 14px;
            height: 14px;
        }
    }
 .add-img {
     width: 100%;
     height: 100%;
     border: 0;
     background-color: transparent;
     border: solid 2px #ffd102;
     display: flex;
     align-items: center;
     justify-content: center;
    border-radius: 10px;
    cursor: pointer;
 }
}
.small-modal-text {
    display: inline-flex;
    align-items: center;
    max-width: 276px;
    position: relative;
    background-color:#272a37;
    border-radius: 16px;
    span {
        color: #d7dce4;
    }
    .close-popup {
        position: absolute;
        right: 16px!important;
        top: 16px!important;
        padding: 0!important;
    }
}
.review-modal-img {
    width: 84px;
    height: 84px;
    object-fit: contain;
}

