.shops-cover {
    @media (min-width:768px) {
        height:320px;
    }
    position: relative;
    & > img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        left: 0;
    }
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        /*background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.75));*/
        
    }
    .shops-info  {
        z-index: 10;
    }
    &.no-cover {
        @media (min-width:768px) {
            height: 224px;
        }
        padding-top: 0;
        display: flex;
        align-items: center;
        background-color: white;
        &::after {
            display: none;
        }
        .shops-info {
            margin-bottom: 0!important;
        }
        .shop-name  {
            span {
                color: #272a37!important;
            }
        }
    }
}
.shop-img {
    border-radius: 50%;
    width: 84px;
    height: 84px;
    // border: 24px solid rgba($color: #8996ae, $alpha: .1);
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width:767px) {
        width: auto;
        height: auto;
        background-color: transparent!important;
    }
    img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        &.rectangle {
            width: auto;
            height: auto;
            max-width: 66px;
            max-height: 66px;
            border-radius: 0;
        }
        &.square {
            width: 76px;
            height: auto;
            max-height: 76px;
        }
        @media (max-width:767px) {
           border: 1px solid #E4E7ED;
        }
    }
}

// user 
.user-top {
    @media (min-width:768px) {
        height: 224px;
    }
    .shop-img {
        border: 24px solid rgba($color: #8996ae, $alpha: .1);
        .svg-i {
            width: 60px;
            height: 60px;
            flex-shrink: 0;
        }
    }
    .shops-phone  {
        border: unset !important;
        background-color: rgba($color: #e4e7ed, $alpha: .25);
    }
}
.top-info {
    padding-bottom: 24px;
    border-bottom: 1px solid #e4e7ed;
}
.shop-tabs {
    height: 112px;
   @media (min-width:768px) {
    height: 40px;
    margin-bottom: 24px;
    align-items: center!important;
    display: flex!important;
   }
}
.info-link {
    transition: .12s ease-in-out;
    &.active {
        color:#272a37!important ;
    }
    &:hover {
        color:#272a37!important ;
    }
}
.map-cont {
    max-width: 408px;
    color: #3f414d;
    a {
        text-decoration: underline;
    }
    .social-list  {
        a {
            padding: 13px;
        }
    }
    .langs-list  {
        a {
            padding: 13px;
        }
    }
}
.shop-map {
    width: 100%;
    height: 160px;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}
.contact-container {
    h2 {
        color: #3f414d;
    }
    .text {
        color: #3f414d;
    }
}
.info-col {
    @media (min-width:1679px) {
        max-width: 624px;
    }

}
.phone-number-msg {
  @media (max-width:767px) {
      width: 100%;
      margin-top: 40px;
      .shops-phone {
          width: 100%;
          margin-left: 0!important;
          margin-right: 0!important;
      }
      .pr-message-btn {
        //   margin-left: 0!important;
        //   margin-right: 0!important;
      }
  }  
}
.shops-inner-tabs {
    @media (max-width:767px) {
        flex-direction: column;
        .sl-top  {
            margin-bottom: 24px;
        }
    }
    
}
.shop-page-tabs li:last-child{
    margin-right:unset !important;
}
