.promotion-modals {
    .promotion {
        width: 244px;
        border: solid 1px $gray;
        cursor: pointer;
        &:hover {
            background-color: rgba($color:$gray, $alpha: .3) ;
        }
    }
    .promotion-content {
        label {
            border: solid 1px $gray;
            &.custom-control-label {
                &::before {
                    top: 0px !important;
                    width: 32px;
                    height: 24px;
                    opacity: 0.5;
                    border-radius: $border-radius;
                    background-color: $blue-gray;
                }
                &::after {
                    width: 4px;
                    height: 10px;
                    margin-top: 1px !important;
                    margin-left: 8px;
                    background-color: $white;
                    border-radius: 3px;
                    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                }
            }
        }
    }
    //.custom-control-input:checked ~ .custom-control-label::before {
    //    color: $white;
    //    border-color: $green-dark !important;
    //    background-color: $green-dark !important;
    //}
}

// promotion time left
.vip-color {
	background-color: #ECF0FF;
}
.vip-plus-color {
	background-color: #FFEFE8;
}
.super-vip-color {
	background-color: #FFF4D5;
}