.custom-tooltip-parent {
  .custom-tooltip {
    opacity: 0;
    user-select: none;
    pointer-events: none;
    transition: opacity .25s ease;
    &:after{
      content:'';
      position: absolute;
    }
    &.arrow-down {
      &:after{
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid $blue;
        bottom:0;
        left:50%;
        transform: translate(-50%, 100%);
      }
    }
  }
  &:hover{
    .custom-tooltip {
      opacity: 1;
      user-select: auto;
      pointer-events: auto;
    }
  }
}