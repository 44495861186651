.shop-inner-slider {
    .offer-slide {
        position: relative;
        padding-top: 24.2%;
        overflow: hidden;
        border-radius: 16px;
        background-color: $gray;
        @media (max-width:767px) {
            padding-top: 23.4%;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 16px;
        }
    }
    .swiper-pagination-bullet {
        background-color: #f1f3f6;
        opacity: 1;
        &.swiper-pagination-bullet-active {
            background-color: #8996ae;
        }
    }
    
}