.filter-col {
    width: 100%;
    flex: 0 0 240px;
    margin-right: 16px;
    max-width: 240px;
    @media (min-width:1366px) {
        max-width: 240px;
    }
}
.search-filter  {
    @media (min-width:1800px) {
        width: 100%;
        max-width: 240px;
    }
    .scrollbar  {
        max-height: 290px;
        padding-right: 24px;
        &::-webkit-scrollbar  {
            width: 2px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #d7dce4;
        }
        &::-webkit-scrollbar-track {
            background-color: #ffff;
        }
    }
    .custom-control  {
        // margin-bottom: 4px;
    }
    .cat-atr {
        margin-top: 26px;
    }
    .dropdown-toggle::after {
        display: none;
    }
    .dropdown-custom {
        height: 44px;
        padding-left: 12px;
        padding-right: 12px;
        // margin-right: 40px;
        border: solid 1px #e4e7ed;
        border-radius: 8px;
        display: flex;
        align-items: center;
        button {
            width: 100%;
            text-align: left;
            justify-content: space-between;
            &:hover {
                background: none!important;
            }
          
        }
     .dropdown-menu  {
         height: 300px;
         overflow-y: auto;
         margin-top: 8px;
         left: 0;
     }
    }
}
body {
    .mibile-search-filterbtn {
        transform: translateY(100%);
        @media (min-width: 1024px) {
            display: none;
        }
    }
    &.modal-open {
        .mibile-search-filterbtn {
            display: flex;
            position: fixed;
            bottom: 0;
            width: 100%;
            height: 160px;
            z-index: 1000;
            transform: translateY(0);
            transition: .5s ease-in-out;
            border-top: 1px solid #F1F3F6 ;
            .btn {
                max-width: 335px;
            }
        }
    }
}
.cats-dot {
    display: flex;
    width: 6px;
    height: 6px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: #e4e7ed;
    flex-shrink: 0;
}
.cats-arrow {
    margin-right: 10px;
    i {
        width: 6.621px;
        height: 10.243px;
    }
}
.cats-item {
    div {
        cursor: pointer;
        &.parent {
            color: #4a6cfa!important;
        }
    }
    span {
        transition: .3s ease-in-out;
        &.active{
            color: #4a6cfa!important;
        }
    }
    &:hover {
        span {
            color: #4a6cfa!important;
        }
    }
}
.search-content {
    flex: 1;
    width: calc(100% - 300px);
    @media (max-width:1023px) {
      width: 100%;
    }
}
.tags-slide {
    //width: calc(100% - 70px);
    border-radius: 20px;
    .swiper-slide {
        width: auto;
        .tags-txt {
            background-color: #4a6cfa;
            padding-right: 4px!important;
        
        }
        .tags-text {
            color: #ffff;
        }
        .close-tags {
            display: flex;
          
            i {
                transition: .3s ease-in-out;
            }
            &:hover {
                i {
                    transform: scale(1.6);
                }
            }
        }
        
        &.arrow-active {
            &:first-child {
                padding-left: 50px;       
            }
            &:last-child {
                padding-right: 50px;
            
            }
        }
    
    }
}
.search-tags {
    .slide-next {
        right: 0!important;
    }
}

.tags-txt {
    border-radius: 20px;
    background-color: rgba($color: #8996ae, $alpha: 0.1);
    padding-top: 7px;
    padding-bottom: 7px;
    // transition: .3s ease-in-out;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // transition: .2s ease-in-out;
    
    .close-tags{
        display: none;
        i {
            width: 10px;
            height: 10px;
        }
    }
    &:hover {
        background-color: #4a6cfa;
        color: #ffff!important;
    }
}
.search-sort {
    border-radius: 10px;
    border: solid 2px rgba(137, 150, 174, 0.1);
   
    
    .dropdown-toggle {
        padding:7.5px 12px !important;
        background-color: transparent!important;
        &::after {
            display: none;
        }
    }
    .sort-arrow  {
        pointer-events: none;
        cursor: pointer;
    }
    .dropdown {
        &.show {
            .sort-arrow {
                transform: rotate(-180deg);
            }
            .dropdown-menu {
                transform: translateY(15px);
                opacity: 1;
                visibility: visible;
                left: 0;
                right: initial;
            }
        }
    }
}
.filter-header {
    border-bottom: 1px solid #F1F3F6;
}


.list-banner {
    border-radius: 8px;
}
.card-old {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        opacity: 0.25;
        border-radius: 2px;
        background-color: #272a37;
        top: 50%;
        left: -3px;
        transform: translateY(-50%);
    }

}
.card-shop  {
    height: 21px;
}
.card-title  {
    height: 19px;
}
.search-media-banner {
    @media (max-width:1192px) {
        display: none;

    }
}
.search-content-cards {
    @media (max-width:1192px) {
        flex: 0 0 100%!important;
        max-width: 100%!important;
    }

}
body {
    .card-col  {
        &.filter-color {
            .card-hover {
                // padding: 15px !important;
                border: solid 1px ;
                border-color: rgba($color: #d4dcfa, $alpha: 0.4);
                background-color: #ebefff !important;
            }
        }
        @media (min-width:992px) {
            .swiper-pagination {
                opacity: 0;
            }
        }
        .card-hover  {
            border: 1px solid transparent;
            transition: .3s ease-in-out;
            &:hover {
                border-color: $dark-purple-blue;
                .swiper-pagination {
                    opacity: 1;
                }
            }
            
        }
        .h-md-auto {
            @media (min-width:768px) {
              height: auto !important;
            }
          }
    }
}




.top-info {
        border-bottom: 1px solid transparent;
        @media (max-width:767px) {
            flex-direction: row-reverse;
            background-color: #F9FAFB;
            .search-sort {
                margin-left: 0 !important;
                margin-right: 0 !important;
                flex-direction: row-reverse;
            }
        }
        &.search-top-info  {
            @media (max-width:767px) {
                height: auto;
            }
        }
    }

    .search-sort {
        @media (max-width:767px) {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
    }
.grid-list {
    @media (max-width:767px) {
        margin-top: 0;
        width: 100%;
    }
    .btn {
        @media (max-width:768px) {
            margin-top: 0;
        }
    }
}
// media
.breadcrumbs_none {
    @media (max-width:1023px) {
        display: none!important;
    }

}
.search_filter_btn {
    @media (min-width:1023px) {
        display: none;
    }
    i {
        width: 25px;
        height: 12px;
    }
}
.filter-col {
    @media (max-width:1023px) {
       position: fixed;
       bottom: -100%;
       left: 0;
       width: 100%;
       max-width: 100%;
       height: calc(100vh - 20vh);
       max-height: 100vh;
       transition: .25s cubic-bezier(0.165, 0.84, 0.44, 1);

        &:before {
            content: '';
            position: absolute;
            top: -20px;
            left: 50%;
            transform: translateX(-50%);
            width: 39px;
            height: 6px;
            border-radius: 4px;
            background-color: #ffffff;
            pointer-events: none;
        }
        .search-filter {
            height: 77vh;
            // height: 80vh;
            // max-height: 100vh;
            overflow-y: auto;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    
    } 

}
.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 200;
    background-color: rgba($color: #000000, $alpha: 0.6);
    opacity: 0;
    transition:.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}
body {
    @media (max-width:1366px) {
        &.modal-open {
            .filter-col {
                bottom: 0;
            }
            .backdrop {
                opacity: 1;
                pointer-events: all;
            }
        }
    }
}

.seacrh-tabs-slider {
    width: auto;
    @media (max-width:767px) {
        width: 100%;;
        margin-bottom: 24px;
    }
}
.view-style-box {
    @media (max-width:1023px) {
        .list {
            display: none!important;
        }
    }
    @media (max-width:767px) {
        flex-direction: row-reverse;
        .btn{
            width: auto;
            height: auto;
            padding: 4px !important;
            background-color: transparent!important;
            &:hover {
                background-color: transparent!important;
            }
            &.active {
                background-color: transparent!important;
            }
            svg {
                g {
                    opacity: 1 !important;
                    rect {
                        fill: #8996ae!important;
                        
                    }
                }
               
            }
            .mobile-grid {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 32px;
                height: 32px;
                background-color: #f1f3f6!important;
                border-radius: 50%;
                flex-shrink: 0;
            }
        }
       
    }
}

.search-content.list   {
    @media (max-width:767px) {
        .box-item {
            padding: 4px 8px !important;
            // margin: 0 !important;
            font-size: 10px !important;
            font-family: $font-family-medium;
            &.percent {
                padding: 5px 7px !important;
                margin-right: 4px !important;
            }
        }
    }
}
.search-page {
    border-top: 2px solid transparent;
}
.seacrh-carrds-banner {
    img {
        object-fit: contain;
        height: 100% !important;
    }
}
.search-content {
    &.list {
        .seacrh-carrds-banner {
            @media (max-width:767px) {
                margin-bottom: 2px !important;
                padding: 0 !important;
            }
        }
    }
    &.grid {
        .seacrh-carrds-banner {
            @media (max-width:767px) {
            background-color: transparent !important;
            }
        }
      
    }
}

html {
    body {
        .toggle-fav {
            &:hover {
               @media (max-width:1024px) {
                   background-color: rgba(137, 150, 174, 0.15) !important;
               }
            }
           }
           .toggle-cart {
            &:hover {
                @media (max-width:1024px) {
                    background-color: rgba(137, 150, 174, 0.15) !important;
                }
            }
        }
    }
}
.search-top-info {
    .breadcrumb-item  {
        width: auto;
        max-width: 200px;
        @media(max-width:1300px) {
            max-width: 140px;
        }
    }
}