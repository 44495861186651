.finances {
    hr {
        border-top: 1px solid rgba(0, 0, 0, 0);
        @media only screen and (max-width: 576px) {
            display: none;
        }
    }

    select {
        border: 1px solid $gray;
    }

    button {
        height: 48px;
        // position: absolute !important;
        // bottom: 0 !important;
        @media only screen and (max-width: 576px) {
            height: 48px;
            // position: relative !important;
            // bottom: 0;
        }
        &.disabled {
            background-image: linear-gradient(72deg, #fec900, #ffd026) !important;
            opacity: 0.4 !important;
        }

        &.btn-fill {
            position: absolute;
            bottom: 0;
        }

        &.btn-transfer {
            border: solid 1px $gray;
        }
    }

    input {
        &::placeholder {
            color: $dark;
            font-family:$font-family-base !important;
           }
    }

    .finances-tabs {
        a {
            &:hover {
                color: $dark-text !important;
            }

            &.active {
                color: $dark-text !important;
            }
        }

        .swiper-container {
            margin-left: 0px !important;
        }

        .tabs-slide {
            .swiper-slide {
                width: auto;

                &.active {
                    .close-tags {
                        display: flex;

                        i {
                            transition: .3s ease-in-out;
                        }

                        &:hover {
                            i {
                                transform: scale(1.6);
                            }
                        }
                    }
                }
            }
        }
    }
.sg-selectbox__menu{
    width:200px !important;
}
    .notification-tabs {
        border-radius: $border-radius-lg;
        border: 1px solid #E4E7ED;
        @media only screen and (max-width: 576px) {
            border-radius: 0px !important;
            border: 1px solid transparent;
        }
        .form-control {
            .border-right {
                border-color: $gray-transparent !important;
            }
            @media only screen and (max-width: 576px) {
                padding: 18px 14px !important;
            }
        }
    }

    .transfer-tabs {
        border-radius: $border-radius-md;
        border: 1px solid #E4E7ED;
        @media only screen and (max-width: 576px) {
            border-radius: 0px !important;
            border: 1px solid transparent;
        }
        .border-right {
            border-color: $gray-transparent !important;
        }
        .form-control {
            @media only screen and (max-width: 576px) {
                padding: 18px 14px !important;
            }
        }
        &.border-10px {
            border-radius: 10px!important;
        }
    }


    .ps-tabs {
        .plus-amount {
            color: $dark-green;
        }
        &.transactions-tabs {
            .react-datepicker__input-container {
                input {
                    &::placeholder {
                        font-size: 14px !important;
                        color: $dark-text;
                    }
                }
                &::placeholder {
                    font-size: 14px !important;
                    color: $dark-text;
                }
                &:focus {
                    outline: transparent auto 1px !important;
                }
                input {
                    cursor: pointer;
                    &:focus {
                        outline: transparent auto 1px !important;
                    }
                }
            }

            .sg-selectbox-container {
                &:focus {
                    outline: transparent !important;
                }
                .sg-selectbox__value-container {
                    padding: 0;
                    line-height: 24px;
                    .sg-selectbox__single-value {
                        font-size: 14px !important;
                    }
                }

                .sg-selectbox__control {
                    border: 1px solid transparent !important;
                    box-shadow: none !important;
                    cursor:pointer;
                    &:focus {
                        outline: transparent !important;
                    }
                    .sg-selectbox__placeholder {
                        font-size: 14px !important;
                        color: $dark-text !important;
                        min-height: 20px !important;
                    }
                    .sg-selectbox__menu {
                        background-color: red !important;
                    }
                }
            }
        }
    }
}

#tempFix {
    position: relative !important;
}
