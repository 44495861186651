.header-top {
  height: 80px;

  .logo-content {
    // max-width: 732px;
    border-radius: 32px;
    height: 40px;
    position: relative;

    &>div {
      &:nth-child(2) {
        width: 100%;
      }
    }

    input {
      border-radius: 12px;
      padding: 14px 16px;
      outline: none;
      font-size: 14px;
      font-family: $font-family-medium;
      color: #222222;

      @media (max-width:767px) {
        padding: 14px 84px 14px 16px !important;
      }

      &::placeholder {
        font-family: $font-family-base;
        font-size: 14px;
        color: #222222;
      }

      &:disabled {
        background-color: white;
      }

    }

    .search-block {
      border-radius: 12px;
      background-color: white;
    }

    .search-icon {
      width: 20px;
      height: 20px;
      right: 20px;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      background-color: transparent;
      transition: .2s ease-in-out;

      @media (max-width: 767px) {
        width: 20px;
        height: 20px;
        right: 4px;
        background-color: transparent !important;
      }

    }


    .mobile-search-btn {
      width: 32px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      @media (min-width:800px) {
        display: none;
      }
    }

    .search-form {
      height: 40px;

      @media (max-width:800px) {
        position: absolute !important;
        left: -1px;
        top: 50%;
        width: 100%;
        opacity: 0;
        padding: 0;
        pointer-events: none;
        transform: scaleX(0) translateY(-50%);
        transform-origin: right;
        transition: .3s linear;

        &.active {
          transform: scaleX(1) translateY(-50%);
          opacity: 1;
          pointer-events: all;

          z-index: 2000;
        }
      }
    }

    form {
      &.search-block {
        @media (max-width:767px) {
          // position: initial !important;
        }
      }
    }

    .suggestions {
      position: absolute;
      top: 63px;
      padding: 12px 20px;
      width: 93%;
      border-radius: 14px;
      z-index: 9999;
      box-shadow: 0 2px 6px 0 rgba(39, 42, 55, 0.1);
      border: solid 1px #f1f3f6;
      background-color: #ffffff;

      @media (max-width:767px) {
        top: 48px;
      }

      li {
        padding: 12px;
        cursor: pointer;

        &.grey {
          border-radius: 16px;
          background-color: rgb(241, 243, 246);
          padding: 12px 0 12px 12px;
        }
      }

      @media (max-width:767px) {
        width: 100%;
        left: 0;
      }
    }


  }

  .menu-container {
    position: fixed;
    background: white;
    width: 100vw;
    height: 100vh;
    top: 0;
    z-index: 99999999;
    left: 0;
    transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);

    .close-button {
      .close-bg-i {
        background-color: #F2F2F6;
        border-radius: 100%;

        &:hover {
          opacity: .5;
        }
      }
    }
  }
}

.back-drop {
  width: 1000px;
  height: 100px;
  position: fixed;
  left: 0;
  top: 0;
  transform: scale(1000);
  background-color: rgba(39, 42, 55, .3);
  display: block;
  z-index: 110;
}

.navbar-nav {
  .add-statement-btn {
    border-radius: 12px;
    height: 42px;
    background-color: #FFF4CC;
    padding: 0 24px 0 24px;
    max-width: 138px;
    line-height: 17px;
    justify-content: center;

    &.auth-btn {
      max-width: 100%;
      padding: 0 14px;
    }

    &:hover {
      border-color: $market-color;
      background-color: #FFEFB2;
    }
  }

  .divider {
    width: 1px;
    height: 12px;
    border-radius: 2px;
    background-color: rgba($color: #8996AE, $alpha: .2);
  }

  .nav-item {
    display: flex;
    justify-content: center;
    align-items: center;

    & a {
      padding: 4px;
      border-radius: 50%;
      transition: .3s;

      @media (max-width:767px) {
        padding: 16px 8px;
      }

      &:hover {
        background-color: #f1f3f6;

        @media (max-width:767px) {
          background-color: transparent;
        }
      }
    }

    &.cart-icon-nav {
      padding: 8px;
      border-radius: 50%;
      transition: .3s;

      &:hover {
        background-color: #f1f3f6;
      }
    }

    &.right-menu-header {
      border-radius: 50%;
      transition: .3s;

      &:hover {
        background-color: #f1f3f6;
      }
    }
    &.hover {
      &:hover {
        background-color: #F2F3F6;
        border: 1px solid #BEBFC3;
      }
    }
  }
}
.authorised {
  &.hover {
    &:hover {
      background-color: #F2F3F6;
      border: 1px solid #BEBFC3;
    }
  }
}

.header-user {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  // border: solid 2px #fec900;
  border-radius: 50%;
  cursor: pointer;

  .dropdown-menu-right {
    right: inherit !important;
    left: 0 !important;
    box-shadow: 0 2px 6px 0 rgba(39, 42, 55, 0.1);
    top: 64px;
  }
}

.langs-switcher {
  a {
    padding: 8px;

    &.active {
      display: flex !important;
      background-color: #F1F3F6;
    }
  }
}

.badge {
  top: -2px;
  right: -2px;
  min-width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;

  // padding-bottom: 2px;
  @media (max-width:767px) {
    top: 6px;
    right: -4px;
  }

  &.badge-burger {
    top: -3px;
    right: -3px;
  }

  &.badge-sidebar {
    top: -8px;
    left: -8px;
    right: inherit;
  }
}

.notification-box {
  .nav-item {
    width: 32px;
    height: 32px;
    border-radius: 50%;

    &:hover {
      background-color: #f1f3f6;
    }
  }

  .notification-dropdown {
    width: 532px;
    top: 88px;
    right: 0;
    opacity: 0;
    pointer-events: none;
    z-index: 1000;
    overflow: hidden;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);

    &.show {
      opacity: 1;
      pointer-events: all;
    }

    .notification-li {
      border-bottom: solid 1px #e4e7ed;

      &:nth-child(4) {
        border: none;
      }

      p {
        margin: 0;

      }

      strong {
        font-weight: normal;
      }

      .icon-cont {
        width: 48px;
        height: 48px;
        padding: 12px;

        &.news {
          background-color: #ebfaed;

          svg {
            width: 16px;

            path {
              stroke: #0ec604;
            }
          }
        }

        &.danger {
          background-color: #ffe9e8;

          svg {
            width: 16px;

            path {
              stroke: $danger;
            }
          }
        }

        &.prohibition {
          background-color: #fff5e5;

          svg {
            width: 16px;

            path {
              stroke: $orange;
            }
          }
        }
      }

      .notification-date {
        color: #8996ae;
      }

      &.unread {
        .icon-cont:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 14px;
          height: 14px;
          border-radius: 25px;
          border: 2px solid white;
          background-color: #f25777;
          display: none
        }

        .notification-text {
          color: #272a37;
          font-weight: bold;

        }

        .notification-date {
          color: #4a6cfa;
        }
      }
    }

  }

}

header {
  .dropdown-menu-right {
    box-shadow: 0 2px 6px 0 rgba(39, 42, 55, 0.1);
    top: 64px;
    padding: 0;
    overflow: hidden;
    z-index: 1001;
  }
}

.img-upload {
  width: 32px;
  height: 32px;
  position: absolute;
  right: 62px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;

  @media (max-width: 767px) {
    right: 37px;
  }

  &::after {
    content: '';
    position: absolute;
    width: 32px;
    height: 32px;
    top: 0;
    left: 0;
    background-image: url('../../../img//icons/search-opload.svg');
    cursor: pointer;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: center;
  }

  &::before {
    content: '';
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 24px;
    border-radius: 2px;
    background-color: rgba(137, 150, 174, 0.25);

    @media (max-width: 767px) {
      display: none;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    width: 32px;
    height: 32px;
    cursor: pointer;

  }

  &:hover {
    background-color: #f1f3f6;
  }

  @media (max-width: 767px) {
    // display: none;
  }
}

.notification-lists {
  .see-all {
    a {
      &:nth-child(1) {
        border-right: 1px solid #ffff;
      }
    }
  }
}

.burger-mobile {
  flex-shrink: 0;
  transition: .3s;
  margin-left: 6px;

  &:hover {
    // background-color: #f1f3f6;

    @media (max-width: 767px) {
      background-color: transparent;
    }
  }
}

.header-profile-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.header-dropdown-menu {
  .main-list {
    a {
      color: $dark;

      &:hover {
        background-color: #f1f3f6 !important;
      }
    }
  }

  .dropdown-log-out {
    color: $blue-gray;
    line-height: 18px;

    &:hover {
      background-color: $gray;
    }
  }
}

.nm-ado-header {
  position: relative;
  width: 100%;
  max-width: 728px;
  padding: 0 0;
  overflow: hidden;
  margin: 8px auto;

  //@media (max-width:1100px) {
  //  max-width: 767px;
  //}

  @media (max-width:767px) {
    max-width: 320px;
  }

  .nm-ado-header-inner {
    width: 100%;
    height: 90px;
    border-radius: 10px;
    overflow: hidden;

    @media (max-width:767px) {
      height: 100px;
    }

    //&>div {
    //  overflow: hidden;
    //}
  }
}

.tnet-logo {

  svg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: .2s ease-in-out;

    circle {
      transition: .2s ease-in-out;
    }

    path {
      transition: .2s ease-in-out;
    }
  }

  &.active {
    svg {
      background-color: #3C74FF;

      circle {
        stroke: #3C74FF;
      }

      path {
        fill: $white;
      }
    }
  }

  &:hover {
    svg {
      circle {
        stroke: #3C74FF;
      }
    }
  }
}

.border-radius-5px {
  border-radius: 5px;
}

.max-width-380px {
  max-width: 380px;
}

.header-top {
  .header-user {
    max-width: 25px;
    max-height: 25px;
  }
}

.hover-info {
  &.active {
    .arrow-dropdown-i {
      transform: rotate(180deg);
    }
  }

  hr {
    &.hr {
      display: block !important;
      margin-right: -16px;
      margin-left: -16px;
    }
  }

  .avatar {
    img {
      max-width: 40px !important;
      max-height: 40px !important;
    }
  }

  .user-name {
    font-size: 14px !important;
    font-family: $font-family-base !important;
  }

  .user-id {
    font-size: 14px !important;
  }

  .active {
    .sidebar-hover {
      display: block !important;
    }
  }

  .sidebar-hover {
    transform: translateX(-33%);
    min-width: 260px;

    .shadow {
      box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;



      .list-group {
        &.py-20px {
          padding: 0 !important;
        }

        .pb-24px,
        .pb-16px {
          padding-bottom: 0 !important;
        }
      }

      a {


        &.pt-24px {
          padding-top: 16px !important;
        }
      }

      .sidebar-head {
        &.pt-20px {
          padding-top: 0px !important;
        }
      }

      .sidebar-container {
        padding: 16px;
        border: none;
      }

      .leave {
        display: none;
      }
    }

  }
}

.bottom-bar {
  &.active {
    position: relative;
  }
}

.langs-card {
  border: 1px solid #272A37 !important;
  background: #F2F3F6;
}



.user-profile-photo {
  height: 24px;
  width: 24px;
  border-radius: 100%;
}

.header-swiper {
  .swiper-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

}

.header-swiper-hover {
  a {
    transition: 0.3s ease-in-out;
  }

  a:hover {
    color: #FEC900 !important;
  }
}