// .faq-question {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     transition: .3s ease-in-out;
//     .faq-plus {
//         width: 16px;
//         height: 16px;
//         position: relative;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         padding:24px;
//         border-radius: 50%;
//         span {
           
//             height: 100%;
//             background-color: #8996ae;
//             display: flex;
//             position: absolute;
//             transition: .3s ease-in-out;
//             &:nth-child(1) {
//                 width: 16px;
//                 height: 4px;
//             }
//             &:nth-child(2) {
//                 width: 4px;
//                 height: 16px;
//             }
//         }
//     }
//     &.open {
//         .faq-plus {
//             span {
//                 &:nth-child(2) {
//                    transform: rotate(90deg);
//                 }
//                 background-color: #ffff;
//                 border-radius: 4px;
//             }
//             background-color: $market-color;
//         }
//     }
// }

// .faq-seacrh {
//     width: 100%;   
//     max-width: 624px;
//     input {
//         height: 64px;
//         border-radius:32px ;
//         background-color: #f1f3f6;
//         &::placeholder {
//             color: #8996ae;
//         }
//     }
//     .faq-seacrh-i {
//         width: 36px;
//         height: 36px;
//         position: absolute;
//         top: 50%;
//         right: 14px;
//         transform: translateY(-50%);
//         .search-i  {
//             width: 12px;
//             height: 12px;
//         }
//     }
//     .filtered-data {
//         position: absolute;
//         z-index: 10;
//         top: 100%;
//         width: 100%;
//         background-color: #f1f3f6;
//         border-radius: 32px;
//         border-top-left-radius: 0;
//         border-top-right-radius: 0;
//         height: auto;
//         border-top: 1px solid white;
//         display:none;    
//         box-shadow: 0 30px 90px #00000014;
//         .filter-value:hover{
//             background-color: $light-gray;
//             &:last-child{
//                 border-bottom-left-radius:32px;
//                 border-bottom-right-radius:32px;
//               }
//         }
//     }
//     &.filtered {
//         input {
//             border-bottom-left-radius: 0;
//             border-bottom-right-radius: 0;
//         }
//         .filtered-data  {
//             display: block;
//         }
//     }
// }

.filtered-data-box {
    filter: drop-shadow(0px 8px 24px rgba(149, 157, 165, 0.2));
    border-radius: 2px;
    margin-top: 6px;
    max-height: 253px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 3px;
        height: 48px;
        display:block;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #d7dce4;
        border-radius: 6px;
      }
    .filtered-data-item {
        transition: .3s ease-in-out;
        &:hover {
            background-color: #F8F9FB;
        }
    }
}
.faq-feedback  {
    border-radius: 8px !important;
    .form-control {
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
    }
    .input-group-text {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
    }
}