.selectbox {
   &.__control {
    height: 48px;
    max-height: 48px;
    border: solid 1px #e4e7ed;
    border-radius: 10px;
    &.css-1pahdxg-control{
        box-shadow: 0 0 0 1px  #9aadfc;
        border: none;
    }
    &:hover {
        border: solid 1px #e4e7ed;
    }
    .selectbox {
        &.__indicator-separator  {
            display: none;
        }
    }
   }
}
.css-tlfecz-indicatorContainer {
    display: none;
}

.custom-grpup {
    min-width: 164px;
 }
 .custom-grpup__control {
     width: 100%;
     border-radius: 10px!important;
     border-color:#e4e7ed!important;
     border-top-right-radius: 0!important;
     border-bottom-right-radius: 0!important;
     outline: none!important;
     &:focus{
         border: 1px solid #9aadfc!important;
         outline: none!important
     }
 
 }
 .custom-grpup-inp {
     border-left: transparent!important;
     margin-left: 1px;
 }
 .custom-grpup__indicator-separator {
     opacity: 0!important;
 }
 .css-1pahdxg-control {
    box-shadow: 0 0 0 1px  #9aadfc!important;
    border: none!important;
 }
 .input-group-append {
    //  margin-left: 0!important;
 }
 .adress-pin {
     transition: .3s ease-in-out;
 }
 .adress-adding-box {
     height: 145px;
     border-radius: 20px;
     border: 2px dashed $dark-market-color;
     position: relative;
     background-color: #ffffff;
     transition: .5s cubic-bezier(0.075, 0.82, 0.165, 1);
     &:hover {
         background-color:rgba($color: #ffff, $alpha: 0.4);
        //  box-shadow: 0px 8px 34px 0px rgba(254,201,0,0.1);
        .adress-pin {
            transform: translateY(-5px) scale(1.1);
        }
     }
     @media (min-width:1500px) {
        // width: 298px;
     }
     @media (max-width:767px) {
         width: 100%;
     }
     &::after {
         content: '';
         //background-image: url('../../../img/icons/adress-mask.svg');
         background-repeat: no-repeat;
         position: absolute;
         width: 300px;
         height: 147px;
         top: -1px;
         left: -1px;
     }
 }
//  

.adress-box-content .adress-box .adress-info-box {
    border-radius: 24px;
    border: solid 1px #e4e7ed;
    background-color: #ffffff;
    max-height: 145px;
    min-height: 145px;
    // margin-right: 24px;
    margin-bottom: 24px;
    cursor: pointer;
    @media (min-width:1500px) {
        // width: 300px;
     }
    @media(max-width:767px){
        width: 100%;
        margin-top: 8px;
    }
.adress-delete {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        border-radius: 50%;
        box-shadow: 0 2px 6px 0 rgba(39, 42, 55, 0.1);
        position: absolute;
        left: -10px;
        top: -10px;
        object-fit: contain;
        cursor: pointer;
        opacity: 0;
        transition: .2s ease-in-out;
        svg{
            display: flex;
        }
        @media (max-width: $breakpoint-md) {
            left: 0;
        }
    }
    &:hover {
        .adress-delete  {
            opacity: 1;
        }
    }
    .adress-info-title {
        max-width: 200px;
    }
}

.adress-box .adress-info-box {
    position: relative;
}


.adress-edit-pen{
    position: absolute;
    top: 20px;
    right: 17px;
    padding: 9px;
}


 .star-icon {
    position: absolute;
    right: 8px;
    top: 25px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
        width: 12px;
        height: 12px;
        path{
            transition: .5s;
        }
    }
    &:hover{
        path{
            fill: #FFD102;
            stroke: #FFD102;
        }
    }
}
.star-icon.active{
    svg{
        path{
            fill: #FFD102;
            stroke: #FFD102;
        }
    }
}



.adress-box-cont  {
    @media (max-width:767px) {
        width: 100%;
    }

}
.edit-address-head {
    @media (min-width:768px) {
        max-width: 624px;
    }
}