.mobile-flex {
    @media(max-width:767px) {
        background-color: hsla(240, 10%, 12%, 0.60);
        width: 100%;
        height: 100vh;
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;

        .mobile-flex-container {
            text-align: -webkit-center;
            display: block!important;
            transform: translateY(-50%);
            top: 50%;
            height: fit-content;
        }
        .d-grid-btn {
            display: grid;
            .btn {
                width: 100%;
            }
        }
    }
}