.product-slider-container {
    // height: 408px;
    @media (max-width:1365px) {
        height: auto;
        margin-bottom: 12px;
    }
    .product-ds-large{
        height:333px;
        @media (max-width:767px) {
            height: auto;
        }
        & > div{
            width: 100%;
        }
    }
    &.no-border {
        .product-lg-container {
            border: 0;
        }
    }
    .product-lg-container {
        height: 333px;
        border: 1px solid #e4e7ed;
        @media (max-width:767px) {
         height: 302px!important;
        }
        .swiper-slide {
            object-fit: cover;
            display: flex;
            align-items: center;
           & > div {
            height: 100% !important;
            display: flex;
           }
            img {
                width: 100%;
                height: 100% ;
                // max-width: 848px;
                // max-height: 636px;
                object-fit: contain;
            }
        }
    }

    @media (min-width:1850px) {
        width: 408px;
    }
    .product-slider-lg  {
        width: 100%;
        height: 408px;
        .swiper-slide {
            object-fit: cover;
            display: flex;
            align-items: center;
            img {
                object-fit: contain;
            }
        }
    }
    .product-details-thumbs-inner {
        .swiper-slide{
            margin-bottom: 8px;
        }
        @media (min-width:1024px) {
            .swiper-container {
                overflow: visible;
                .swiper-wrapper {
                    transform: none !important;
                    display: flex;
                    flex-wrap: wrap;
                    height: auto;
                }
            }
    
        }
        @media (min-width:1450px) {
            .swiper-slide {
                width: 72px !important;
                height: 72px !important;
                margin-bottom: 12px;
                img {
                    opacity: 1 !important;
                }
            }
        }
        img {
            opacity: 1 !important;
        }
        .swiper-slide {
            &::before {
                display: none !important;
            }
        }
        @media (max-width:767px) {
            position: absolute !important;
            bottom: 60px;
            width: 100%;
            left: 0;
            z-index: 1000;
        }
    }

    .product-details-thumbs {
        .swiper-slide {
            border-radius: 8px;
            overflow: hidden;
            cursor: pointer;
            border: 1px solid $gray;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                
            }
            &.custom-active {
                border: 1px solid $market-color;
            }
        }


    }
    .slider-nav-next {
        position: absolute;
        right: 0;
        &::after {
            content: '';
            font-family: initial;
            background-image: url('../../../img/icons/pr-right.svg');
            background-repeat: no-repeat;
            width: 10px;
            height: 10px;
            z-index: 20;
            position: absolute;
            background-position: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
    .slider-nav-prev {
        position: absolute;
        left: 0;
        &::after {
            content: '';
            font-family: initial;
            background-image: url('../../../img/icons/pr-left.svg');
            background-repeat: no-repeat;
            width: 10px;
            height: 10px;
            z-index: 20;
            position: absolute;
            background-position: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
}
.swiper-slide-video {
    & > div {
        width: 100%;
        height: 100% ;
        // max-width: 848px;
        // max-height: 636px;
        object-fit: contain;
        iframe {
            height: 95% ;
         @media (max-width:1023px) {
            padding-top:90px
         }
        }
    }
}
.thumb-nav-next  {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    &:hover {
        background-color: rgba(241, 243, 246, 0.7)!important;
    }
}
.thumb-nav-prev  {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    &:hover {
        background-color: rgba(241, 243, 246, 0.7)!important;
    }
}
.hover-btn {
    transition: 0ms;
    &:hover {
        background-color: #F1F3F6!important;
        .favorites-add-i {
            // background-image: url('../../../img/icons/favorites.svg');
        }
        .share-i {
            // background-image: url('../../../img/icons/share-h.svg');
        }
        .dots-i {
            // background-image: url('../../../img/icons/dots-h.svg');
        }
    }
}
.pr-toggle-btn {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-image: url('../../../img/icons/dots.svg');
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
        background-image: url('../../../img/icons/dots-h.svg'); 
    }
    &::after {
     display: none;
    }
    &:focus {
        box-shadow: none;
    }

}
.pr-toggle-option {
    width: 192px;
    border: 1px solid #E4E7ED;
    border-radius: 10px;
    padding: 15px 0 20px 0;
    &:focus {
        box-shadow: none;
        background-color: transparent;
    }
    &.acvtive {
        background-color: transparent!important;
    }
    .dropdown-item {
        margin-bottom: 5px;
        padding:8px 13px;
        display: inline-flex;
        font-size: 14px;
        color: $dark;
        font-family: $font-family-base;
        &:hover {
            background-color: rgba($color: #d7dce4, $alpha: .2);
            color: #4A6CFA;
        }
        &:active {
            background-color: transparent!important;
            color: #4A6CFA;
        }
    }
}
.review-section {
    .rate-back {
        .rate-bg {
            margin-right: 5px;
        } 
        .rate {
            top: 0;
            left: 0;
            .rate-active {
                margin-right: 5px;
            }
        }
    }
}

.shops-img{
    width: 64px;
    height: 64px;
    overflow: hidden;
    border-radius: 50%;
    background-color: rgba(241, 243, 246, 0.7);
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.location {
    border: solid 1px rgba($color: #e4e7ed, $alpha: .5);
    border-radius: $border-radius-st !important;
}
.buy-btns {
    .add-cart-count {
        width: 20px;
        height: 20px;
        right: -7px;
        top: -7px;
        opacity: 0;
        font-style: normal;
        &.active {
            border: solid 2px #ffffff;
            opacity: 1;
        }
    }
}
.banner-col {
    @media (max-width:1272px) {
        //  flex: 0 0 20%;
        //  max-width: 20%;
    }
    @media (max-width:767px) {
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 30px;
        padding: 0 15px!important;
        .banner-box {
            max-width: 100%;
            &:nth-child(2) {
                display: none!important;
            }
            img {
                width: 100%;
                object-fit: contain;
            }
        }
   }
}


.price-old {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        opacity: 0.15;
        border-radius: 2px;
        background-color: #272a37;
        top: 57%;
        left: -3px;
        transform: translateY(-50%);
    }
    &.card-old {
       &::after {
        top: 50%;
       }

    }
}
.user-mobile
{
    &.active {
        .show-number {
            display: none!important;
        }
        .opened-number {
            display: flex!important;
        }
    }
}
.hidden-number {
    line-height: 12px;
}
.product-price-box {
    background-color: rgba($color: #F1F3F6, $alpha: .7);
}

.close-feedback {
    position: absolute;
    right: 0;
    top: 0;

}
.feedback-box {
    display: none;
    background-color: rgba($color: #F1F3F6, $alpha: .7);
    border-radius: 16px;
    &.active  {
        display: flex;
        @media (max-width:767px) {
            flex-direction: column;
            align-items: center;
            border-radius: 0;
        }
    }
}
.close-feedback  {
    &:hover {
        .close-gray-i {
            background-image: url('../../../img/icons/close-bluegray.svg');
        }
    }
}
.hover-blue {
    &:hover {
        color: $dark !important;
    }
}
.btn-border-color {
    &:hover {
        border-color: rgba($color: $blue-gray, $alpha: .1) !important;
        background-color: rgba($color:  $blue-gray, $alpha: .1);
    }
}
.feedback-hide {
    &.remove {
        display: none;
    }
}
.slider-col {
    @media (min-width:1679px) {
        padding: 0;
    }
    @media (max-width:1300px) {
       
        .product-slider-lg  {
            height: auto;
        }
    }
    @media (max-width:1023px) {
        max-width: 100%;
        flex: 0 0 100%;
    }
}
.toggle-fav-pr {
    &.active {
        background-color: $market-color !important;
        .favorites-add-i  {
            background-image: url('../../../img/icons/favorites-white.svg');
        }
    }
}

.product-full-section {
    border-top: 2px solid #F1F3F6;
    @media (max-width: 767px) {
        .slider-col {
            max-width: 100% !important;
            padding: 0;
        }
    }
}

.product-group {
    .product-group-img {
       width: 54px; 
       height: 54px;
       flex-shrink: 0;
       border: 2px solid #E4E7ED;
       border-radius: 8px;
       overflow: hidden;
       transition: .3s ease-in-out;
       img {
           width: 100%;
           height: 100%;
           object-fit: contain;
       }
       &:hover {
           border-color: #FECA06;
       }
       &.active {
           border-color: #FECA06;
       }
    }
}
// product-sales-group
.product-sales-group {
    max-height: initial;
    overflow: hidden;
    .product-group-card  {
        width: 128px;
        position: relative;
        flex-shrink: 0;
     
    }
    .sales_plus {
        transform: translateY(55px);
        height: 11px;
        margin: 0 20px;
        @media (max-width: 1365px) {
           display: none;
        }
    }
    .percent {
        position: absolute;
        top: 10px;
        left: 10px;
    }
    .total-price-box {
        width: 52px;
        height: 52px;
        border-radius: 50%;
        border: 1px solid #E4E7ED;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateY(55px);
        @media (max-width:1365px) {
            display: none;
        }
    }
    .sales-pr-box {
        // max-width: 256px;
    }
}
.product-sales-group  {
    .swiper-slide {
        &:nth-last-of-type(4n+1) {
            .sales_plus {
                display: none;
             }
         
        }
    }
}


.row-style{
    &.two {
        .sales-pr-box   {
            display: flex;
            align-items: center;
        }
        .product-price-box {
            flex: 1;
            margin-bottom: 0!important;
            margin-right: 12px;
            max-width: 256px;
            margin-left: 40px;
         }
         .product-group-card  {
            display: flex;
            width: 100%;
            max-width: 272px;
            .group-img  {
                margin-right: 16px;
                max-width: 128px;
            }
        }
    }
    &.three {
        align-items: center;
        .sales-pr-box   {
            display: flex;
            align-items: center;
            flex-direction: column;
            max-width: 256px;
            margin-left: 60px;
            .product-price-box {
                width: 100%;
            }
        }
        .sales_plus {
            margin: 0 16px;
        }
        .sales-pr-card {
            align-items: flex-start;
        }
        .product-group-card  {
            display: flex;
            width: 100%;
            max-width: 264px;
         
            .group-img  {
                margin-right: 16px;
                max-width: 128px;
            }
        }
    }
    .total-price-box {
        display: none;
    }


}

.product-details-content {
    .buy-section {
        @media (max-width:767px) {
            flex-direction: column;
            .fast-buy  {
                width: 100%;
            }
        }
    }
    .pr-top-option  {
        @media (max-width:767px) {
            flex-direction: column;
            flex-wrap: wrap;
            align-items: flex-start!important;
            margin-top: 12px;
        }
    }
    .right-fav  {
        @media (max-width:767px) {
            margin-top: 12px;
        }
        .toggle-fav-pr  {
            width: 32px;
            height: 32px;
        }
    }
}
.magnifier  {
    height: 100%!important;
}
.pr-sales-group  {
    @media (max-width:1365px) {
        .product-sales-group {
            flex-direction: column;
            justify-content: center;
            .sales-pr-card {
                    // 
            }
        }
    }
    @media (max-width:1023px) {
        border-radius: 0!important;
    }
}
.sales-pr-box   {
    @media (max-width:767px) {
        margin-top: 12px;
    }
}


// gallery \

.SRLThumbnailsContainer {
    margin: 0 auto;
    bottom: 60px !important;
    height: 80px !important;
    display: flex;
    align-items: center!important;
    justify-content: center!important;
    @media (max-width:1600px) {
        bottom: 0px !important;
    }
    @media (max-width:1023px) {
        display: none !important;
        &.css-1g7dgnd {
            width: 100%!important;
            flex-wrap: wrap;
            height: auto!important;
            margin-top: 16px;
        }
    }
    .SRLThumb {
        width: 50px!important;
        height: 50px;
        border-radius: 8px;
        margin: 0 8px;
        @media (max-width:1023px) {
            margin-bottom: 16px;
        }
   
    }
}
.SRLElementWrapper  {
    max-width: 824px;
    max-height: 550px;
    border-radius: 16px;
    @media (max-width:767px) {
        border-radius: 0;
    }
    img {
        border-radius: 16px;
        @media (max-width:767px) {
            border-radius: 0;
        }
    }
  
}
.css-10yc0wa{
    background-color: rgba($color:  #272a37, $alpha: .95)!important;
    @media (max-width:767px) {
        background-color: $dark !important;
    }
}
.SRLPrevButton {
    svg {
        display: none !important;
    }
}
.SRLNextButton {
    svg {
        display: none !important;
    }
}
.SRLPrevButton {
    background-color: #272a37!important;
    border-radius: 16px!important;
    width: 84px;
    height: 84px;
    padding: 0!important;
    margin-left: -168px;
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 12px;
        height: 17px;
        background-image: url('../../../img/icons/lg-left.svg');
        background-repeat: no-repeat;
        background-position: center;
    }
    &:hover {
        background-color: #434652!important;
    }
   //@media (min-width:1800px) {
   // left: 325px!important;
   //}
   //@media (max-width: 1365px) {
   // left: 24px!important;
   //}
   @media (max-width: 1023px) {
    //display: flex!important;
    width: 40px;
    height: 40px;
    border-radius: 50% !important;
    top: calc(100vh - 100px) !important;
    left: 20% !important;
    background-color: #434652!important;
   }
}
.SRLNextButton {
    background-color: #272a37!important;
    border-radius: 16px!important;
    width: 84px;
    height: 84px;
    padding: 0!important;
    margin-right: -168px;
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 12px;
        height: 17px;
        background-image: url('../../../img/icons/lg-right.svg');
        background-repeat: no-repeat;
        background-position: center;
    }
    &:hover {
        background-color: #434652!important;
    }
/*    @media (min-width:1800px) {
        right: 325px!important;
       }
       @media (max-width: 1365px) {
        right: 24px!important;
       }*/
       @media (max-width: 1023px) {
        //display: flex!important;
        width: 40px;
        height: 40px;
        border-radius: 50% !important;
        top: calc(100vh - 100px) !important;
        right: 20% !important;
        background-color: #434652!important;
       }
}
.SRLThumb  {
    border: none!important;
    position: relative;
    overflow: visible!important;

    &::after {
        content: '';
        position: absolute;
        width: 16px;
        height: 5px;
        background-color: $market-color;
        border-radius: 3px;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%) scaleX(0);
        transform-origin: center;
        transition: .5s ease-in-out;
    }
    &.SRLThumbnailSelected  {
        &::after {
            transform: translateX(-50%) scaleX(1);
        }
    }
}



.SRLControls  {
    background-color: transparent!important;
    button.SRLCloseButton {
        width: 24px !important;
        height: 24px !important;
        margin-left: auto;
        
    }
    .SRLCloseButton {
        position: absolute;
        top: 24px;
        right: 24px;
 
    }
    svg.SRLCloseButton{
     
    }
}
.product-details-thumbs-controls  {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.review-filter {
    .dropdown-custom {
        height: 44px;
        padding-left: 12px;
        padding-right: 12px;
        // margin-right: 40px;
        border: solid 1px #e4e7ed;
        border-radius: 8px;
        display: flex;
        align-items: center;
        .dropdown-toggle {
            border: none;
            &:hover {
                background-color: transparent;
            }
            &:after {
                display: none;
            }
        }
    }
}

.btn-border-grey {
    border: 1px solid #e4e7ed;
    border-radius: 10px;
}

.loan_form {
    form {
        .form-group {
            input {
                min-height: 48px;
            }
        }
        .product-details-card {
            border-top: 1px solid #f3f4f6;
            border-bottom: 1px solid #f3f4f6;
        }
    }

    .payment-period {
        .sg-selectbox__control {
            height: 46px !important;
            border-radius: 10px;
            border: 0!important;
            background-color: white;
            cursor: pointer;
            &.sg-selectbox__control--is-focused {
                box-shadow: none !important;
                .sg-selectbox__value-container {
                    &.css-g1d714-ValueContainer {
                        padding: 0px 9px !important;
                    }
                }
            }
            .sg-selectbox__value-container {
                padding-left: 12px;
                &.css-g1d714-ValueContainer {
                    height: 46px !important;
                    padding: 0px 9px !important;
                }
            }
            .css-1okebmr-indicatorSeparator {
                width: 0px;
            }
        }
        .sg-selectbox__menu {
            z-index: 2 !important;
            cursor: pointer !important;
        }
    }
}
.magnifying-glass {
    @media (max-width:1023px) {
        display: none;
    }
}
.magnifier  {
    @media (max-width:1023px) {
        pointer-events:none;
    }
}
.pr-details-breadCrumbs {
    .breadcrumb-item {
        @media (max-width:767px) {
            display: none !important;
            &:nth-child(1) {
                display: block !important;
            }
            &:nth-child(2) {
                display: block !important;
            }
            &:last-child {
                display: block !important;
            }
        }
      
    }
}

.visa-gradient {
    background-image: linear-gradient(to left, rgba(177, 183, 253, 0.08), rgba(16, 25, 136, 0.08));
}

.questionnaire-box-outer {
    height: 72px;
    .questionnaire-box {
        transform: translateY(100%);
        transition: .3s ease-in-out;
    }
    &.active {
        .questionnaire-box {
            transform: translateY(0);
        }
    }
}

.gallery-tab {
    color: #8996AE;
    position: relative;
    cursor: pointer;
    &.active {
        color: #000000;
        &::after {
            content: '';
            position: absolute;
            height: 3px;
            border-radius: 10px;
            left: 0;
            bottom: -19px;
            width: 100%;
            background-color: $primary;
        }
    }

}
.gallery-border {
    @media (min-width:1024px) {
        border-radius: 12px;
    }
}
.media-content {
    @media (min-width:768px) {
        height: 100%;
    }
}
.SRLControls-tabs {
    @media (max-width:1023px) {
        position: absolute;
        top: 32px;
        left: 15px;
        z-index: 1000;
       }
}
.img-content  {
    @media (max-width:767px) {
     .media-content {
      height: 80vh;
     }
   
    }
    .swiper-container {
        height: 100% !important;
        width: 100%;
     }

    }

.zoom-and-magnify {
    object-fit: contain;
}
.gallery-outer-box {
    height: 100vh !important;
    @media (min-width:1024px) {
        max-height: 90vh !important;
    }
}

// new
.pr-editor-styles {
    p {
        margin: 0 !important;
    }
    * {
        //word-break: break-all;
    }
}
.mobile-transparent {
    @media (max-width:767px) {
        background-color: transparent !important;
        box-shadow: none !important;
    }
}