//responsive
.cards:not(.no-fixed-widths) {
  @media (max-width:425px) {
    min-width: 332px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width:320px) {
    min-width: 270px;
    margin-left: auto;
    margin-right: auto;
  }
}