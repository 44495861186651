.fade-out {
  opacity: 0;
  @include transition($transition-fade);
}
.fade-in {
  opacity: 1;
  @include transition($transition-fade);
}
.fade-out-hover {
  opacity: $opacity-100;
  @include transition($transition-fade);
  &:hover {
    opacity: $opacity-80;
  }
}
.fade-in-hover {
  @include transition($transition-fade);
  &:hover {
    opacity: $opacity-100 !important;
  }
}
/*targeted-hover*/
.hover-parent {
  .swiper-pagination-hover-target,
  .hover-lg-target,
  .hover-target {
    opacity: 0;
    pointer-events: none;
    transition: $transition-all;
    &.no-transition {
      transition: none;
    }
  }
  &:hover {
    .hover-lg-target,
    .swiper-pagination-hover-target,
    .hover-target {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
/*hovers*/
@each $color, $value in $theme-colors {
  @include path-fill(".path-fill-#{$color}", $value);
}

.hover-parent-underline {
  @include hover() {
    .hover-target-underline {
      text-decoration: underline !important;
    }
  }
}

@each $key,$val in (border:border-color,text:color,bg:background-color,svg:fill) {
  @each $name,$value in $theme-colors {
    //parent-hover
    button.hover-parent-#{$key}-#{$name},
    a.hover-parent-#{$key}-#{$name},
    .hover-parent-#{$key}-#{$name} {
      @include hover() {
        .hover-target-#{$key} {
          #{$val}: $value !important;
        }
      }
    }
    //hover
    button.hover-#{$key}-#{$name},
    a.hover-#{$key}-#{$name},
    .hover-#{$key}-#{$name} {
      @include hover() {
        #{$val}: $value !important;
      }
    }
    //Click
    button.click-#{$key}-#{$name},
    a.click-#{$key}-#{$name},
    .click-#{$key}-#{$name} {
      @include focus-active() {
        #{$val}: $value !important;
      }
    }
    //active
    button.active-#{$key}-#{$name},
    a.active-#{$key}-#{$name},
    .active-#{$key}-#{$name} {
      #{$val}: $value !important;
      .active-target {
        #{$val}: $value !important;
      }
    }
  }
}


.hover-scale-all {
  transition: $transition-all;
  transform: scale(1);
  transform-origin: center;
  @include hover() {
    transform: scale(1.05);
  }
}

.hover-parent-visibility{
  @include hover() {
    .hover-target-visibility {
      display: block !important;
    }
    .hover-target-visibility-flex {
      display: flex !important;
    }
  }
}


@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    button.reset-hovers#{$infix},
    a.reset-hovers#{$infix},
    .reset-hovers#{$infix}
    {
      @include hover() {
        opacity:initial !important;
        color:initial !important;
        background-color:initial !important;
        box-shadow:initial !important;
      }
    }

  }
}
.hover-bg-gray-450 {
  &:hover {
    background-color: #f5f7f8!important;
  }
}
