.video-cover-box {
    position: relative;
    padding-top: 35%;
    width: 100%;
    border-radius: 24px;
    overflow: hidden;
    @media (max-width:767px) {
        border-radius: 0;
        padding-top: 96%;
    }
    // & > div {
    //     width: auto !important;
    //     height: auto !important;
    // }
    .c2c-logo  {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 200;
        @media (max-width:767px) {
            max-width: 320px;
        }
    }
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 40;
        background-color: rgba($color: #000000, $alpha: .3);
    }
    video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
    }
}
.text-logo  {
    width: 280px;
}
.text-logo-text  {
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%,-50%) rotate(2deg);
    &.text-logo-right {
        transform: translate(-50%,-50%) rotate(-2deg);
    }
}
.c2c-text {
    ul {
        li {
            margin-left: 20px;
            list-style: initial;
        }
    }
}
.animation-text {
    ul  {
        li {
            svg {
               path {
                transition: .3s ease-in-out;
               }
            }
            span {
                transition: .3s ease-in-out;
            }
        }
    }
    
    &.active {
        li {
            span {}
            &:nth-child(1) {
                svg {
                    path {
                        stroke:#5BC13A ;
                        transition-delay: .2s;
                       
                    }
                }
                span {
                    opacity: 1 !important;
                     color: #5BC13A !important;
                      transition-delay: .2s;
                    }
            }
            &:nth-child(2) {
                svg {
                    path {
                        stroke:#5BC13A ;
                     transition-delay: .4s;
                }
                }
                span { 
                    opacity: 1 !important;
                    color: #5BC13A !important;
                    transition-delay: .4s;
                }
            }
            &:nth-child(3) {
                svg {
                    path {
                        stroke:#5BC13A ;
                        transition-delay: .6s;
                    }
                }
                span { 
                    opacity: 1 !important;
                    color: #5BC13A !important;
                    transition-delay: .6s;
                }
            }
            &:nth-child(4) {
                svg {
                    path {
                        stroke:#5BC13A ;
                        transition-delay: .8s;

                    }
                }
                span {
                     opacity: 1 !important;
                     color: #5BC13A !important;
                     transition-delay: .8s;

                    }
            }
        }
    }
}
.video-back-fragment {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.video-3 {
    .video-outer {
        border:14px solid #Fff;
        background-color: #Fff;
        border-radius: 36px;
        position: relative;
        z-index: 10;
        max-height: 698px;
    }
    video {
        // max-width: 340px;
        max-height: 670px;  
        border-radius: 32px;    
        border: 14px solid #F2F2F2;
        background-color: #Fff;
        object-fit: cover;
        @media (max-width:767px) {
            max-height: 450px;
        }
    }
}
.c2c-see-all {
    background-color: #3C434C;
}