.app-landing {
    background-image: linear-gradient(black ,#333439) !important;
    background-color: black !important;
        .btn {
            position: relative;
            z-index: 99;
        }

            overflow: hidden;
            .app-info-box {
                position: relative;
                z-index: 9999;
            }

}
.bg-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width:1023px) {
        top: -100%;
        left: 0;
        transform: none;
        width: 100%;
        // height: 100%;
        img {
    
            object-fit: contain;
        }
    }
}
.front-img {
    z-index: 99;
    position: relative;
    @media (min-width:1024px) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    @media (max-width:767px) {
      img {
          width: 222px;
      }
    }

}