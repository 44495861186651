.tradein-bg {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000000;
        z-index: 1;

    }
    .tarde-img  {
        width: 100%;
        position: relative;
        padding-top: 47.5%;
        z-index: 2;
        @media (max-width:767px) {
            padding-top: 83.5%;
        }
        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: contain;

        }
        .tarde-img-content {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            @media (max-width:767px) {
               transform: none;
               top: initial;
               bottom: 50px;
             }
            .trade-title {
                line-height: 36px;
                @media (max-width:767px) {
                   line-height: 28px;
                }
            }
        }
    }
}
.box-transform {
    position: relative;
    z-index: 1000;
    // @media (max-width:1100px) {
    //     transform: translateY(-45px);
    // }
    // @media (max-width:1023px) {
    //     transform: translateY(-25px);
    // }
    .row  {
        @media (min-width:768px)  {
            overflow: hidden;
        }
    }
}
.info-slider {
    background: white;
    @media (max-width:767px) {
        background-color: transparent;
        .swiper-slide {
            background-color: white;
            padding: 48px 32px 56px 32px;
            border-radius: 12px;
            max-height: 213px;
           
        }
    }
    .swiper-pagination {
        bottom: -30px;
        @media (min-width:1024px) {
            display: none;
        }
        .swiper-pagination-bullet {
            width: 6px;
            height: 6px;
            @media (max-width:767px) {
                background-color: $white;
                opacity: 1;
            }
            &.swiper-pagination-bullet-active  {
                background-color: $primary;
            }
        }
    }
}
.faq-title {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #F1F3F6;
        left: 0;
        bottom: 0;
        transform-origin: center;
        transform: scaleX(1);
        transition: 1s ease-in-out;
    }
    &.opened {
        &::after {
            transform: scaleX(0);
            transition: .5s ease-in-out;
        }
    }
}
.faq-content {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
    &.closed {
        max-height: 0;
        transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
    }
}
.faq-box {
    .plus {
        width: 16px;
        height: 16px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        // padding:24px;
        border-radius: 50%;
        span {
            height: 100%;
            background-color: #DBDFE6;
            display: flex;
            position: absolute;
            border-radius: 4px;
            transition: .3s ease-in-out;
            &:nth-child(1) {
                width: 16px;
                height: 4px;
            }
            &:nth-child(2) {
                width: 4px;
                height: 16px;
            }
        }
    }
    .faq-title {
        &.opened {
            .plus {
                span {
                    &:nth-child(2) {
                       transform: rotate(90deg);
                    }
                    border-radius: 4px;
                }
            }
        }
    }
}
.faq-item  {
    &:last-child {
        .faq-title {
            &::after {
                display: none;
            }
        }
    }
}
.tradein-tabs {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        border-radius: 4px;
        background-color: $dark;
        transform-origin: center;
        transform: scaleX(0);
        transition: .5s ease-in-out;
    }
    &.active {
        &::after {
            transform: scaleX(1);
        }
    }
    &:hover {
        &::after {
            transform: scaleX(1);
        }
    }
}
.tradein-video  {
    height: 361px;
}
.btn-start-tradein {
    width: 122px;
    height: 44px;
    transition: .3s ease-in-out;
    &:hover {
        background: rgba($color: #F1F3F6, $alpha: .5);
    }
}
.step-number {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #BBC8FD;
    &.active {
        border-color: transparent;
    }
}
.steps-info {
    padding-right: 48px;
    position: relative;
    &::after {
        content: '';
        position: absolute;
        width: 4px;
        height: 100%;
        border-radius: 8px;
        background-color: #F1F3F6;
        right: 0;
        top: 0;
    }
}
.btn {
    &.product-type {
        &:hover {
            border-color:$primary !important;
        }
    }
    &.btn-step {
        &:hover {
            background-color:$primary !important;
            color:white !important;
        }
    }
}
.tradein-actions {
    .info-img {
        border-radius: 20px;
    }
    .files-preview-thumbs   {
        width:84px !important;
        height:84px !important;
        &.add-more-img   {
            width:84px !important;
            height:84px !important;
            // flex:1 !important;
        }
        .uploaded-img {
            width:84px !important;
            height:84px !important;
            flex: 1 !important;
          
        }
        .remove-img  {
            // width: 100% !important;
        }
    }
  
}
.step-img-btn {
    &.disabled {
        cursor:default;
        &:hover {
            background-color:$primary !important;
        }
    }
}
.preview-img {
    height:227px;
    // user-select:none;
    overflow: hidden;
}
.preview-nav {
    // width:100%;
    // position:absolute;
    // top: 50%;
    // transform:translateY(-50%);
    // z-index:100;
    // height:64px;
    // display:flex;
    // align-items:center;
    // overflow: hidden;
    .control-btn {
        display:flex;
        justify-content: space-between;
        overflow: hidden;
        .btn {
            display:flex;
            justify-content:flex-end;
            position: absolute;
            width:64px;
            height:64px;
            left: -32px;
            top: 50%;
            transform:translateY(-50%);
            background-color: rgba($color: #1A1613, $alpha: .8) ;
            opacity: 1 !important;
            i {
                background-image: url('../../../img/icons/preview-arrow-left.svg');
                margin-right: 16px;
            }
            &.right-btn {
                right:-32px;
                left:initial;
                display:flex;
                justify-content:flex-start;
              
                i {
                    background-image: url('../../../img/icons/preview-arrow-right.svg');
                    margin-left: 16px;
                }
            }
            &:disabled {
                i {
                    opacity: .5;
                }
            }
        }
    }
}
.custom-adress-select-tradein-option{
    &:nth-child(1) {
        background-color: transparent !important;
        padding:0 24px !important;
        display:flex;
        align-items: center;
        &:hover{
            background-color:transparent  !important;
           }
    }
    &:hover{
        background-color:$gray  !important;
       }
}
.custom-adress-select-tradein {
    .sg-selectbox__menu  {
        overflow: hidden;
    }
    .sg-selectbox__menu-list {
        margin: 8px;
        &::-webkit-scrollbar  {
            width: 3px;
            display: block;
            border-radius: 8px;
        }
        &::-webkit-scrollbar-thumb  {
            width: 3px;
            border-radius: 8px;
            background-color: $market-color;
        }
        
    }
 
}
.btn-save-card {
    position: absolute;
    width:72px;
    height:32px;
    border-radius:24px;
    right:8px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content:center;

}
.info-box  {
    @media (min-width:1200px) {
        min-height: 572px;
    }
    @media (max-width:767px) {
        border-radius: 0 !important;
    }
}
.preview-title {
    line-height:19px;
}
.preview-info-item {
    line-height:15px;
}
.preview-sum  {
    line-height:17px
}
.courier-service {
    line-height: 15px;
}
.photos-descr {
    line-height: 18px;
}

.tradein-offer-tooltip {
        position: absolute;
        top: -70px;
        left: -20px;
        height: 56px;
        display: flex;
        align-items: center;
    &::after {
        content: '';
        width: 0; 
        height: 0; 
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid $blue;
        position: absolute;
        left: 24px;
        transform: translateX(-50%);
        bottom: -5px;
    }

}
.sg-selectbox-container-tradein  {
    cursor: pointer !important;
    .sg-selectbox__value-container  {
        line-height: 16px !important;
    }
}
