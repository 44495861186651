.sidebar-container {
     border: 1px solid #E7EAF3;
    @media (min-width:1679px) {
        max-width: 276px;
    }
    .sidebar-head {
        
        .avatar {
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            // border:2px solid #E4E7ED;
            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }
        }
    }
    .list-group-item {
        a {
            transition: .3s ease-in-out;
       
            svg {
                path {
                    transition: .3s ease-in-out;
                 
                }
                circle {
                    transition: .3s ease-in-out;
                  
                }
                line {
                    transition: .3s ease-in-out;
                  
                }
                rect {
                    transition: .3s ease-in-out;
                   
                }
                
            }
            &:hover {
        
                    color:  #FEC900 !important;
                    svg {
                        path {
                            fill: #FEC900;
                        }
                        circle {
                          stroke: #FEC900;
                        }
                        line {
                            stroke: #FEC900;
                          }
                          rect {
                            fill: #FFFBEB;
                          }
                    }
                .tradein-icon {
                    svg {
                        path {
                            fill:#FEC900;
                        }
                    }
                }
                .orders {
                    svg {
                        path {
                            stroke:#FEC900;
                            fill: none;
                        }
                    }
                }
            }
            @media (max-width:1023px) {
                font-weight: normal;
            }
        }
        &.active {
           a {
               color:  #FEC900 !important;
               @extend .font-bold;
               svg {
                   path {
                    fill: #FEC900;
                   }
                   circle {
                     stroke: #FEC900;
                   }
                   line {
                    stroke: #FEC900;
                  }
                  rect {
                    fill: #FFFBEB;
                  }
               }
               .tradein-icon {
                svg {
                    path {
                        fill:#FEC900;
                    }
                }
            }
            .orders {
                svg {
                    path {
                        stroke:#FEC900;
                        fill: none;
                    }
                }
            }
           }
        }
        
    }
}
.user_menu {
    @media (max-width:1199px) {
        flex: 0 0 250px;
        margin-right: 12px!important;
        .list-group-item {
            a {
                & >   span {
                    margin-right: 12px!important;
                }

            }
        }
    }
    @media (max-width:1023px) {
        display: none;
        flex: 0 0 0;
    }
    @media only screen 
        and (min-device-width: 320px) 
        and (max-device-width: 1023px)
        and (-webkit-min-device-pixel-ratio: 2) {
            display: none;
            flex: 0 0 0;
        }
}

.user_profile {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 100%;
    z-index: -1;
    opacity: 1;
    transition: .25s ease-in-out;
    @media (max-width:767px) {
        width: 100%;
    }
}
body {
    &.menu-open {
        .user_profile  {
            z-index: 10000;
            left: 0;
            opacity: 1;
        }
        max-height: 100vh;
        overflow: hidden;
    }
}
.sidebar-scroll {
    height: 100vh;
    overflow-y: auto;
}
.m-sidebar-close {
    position: absolute;
    top: 28px;
    right: 20px;
}
.mobile-auth  {
    .btn {
        width: 143px;
        &.w-100s {
            width: 100%;
        }
    }
}
.open-shop-img  {
    position: absolute;
    top: -50px;
    &.is-shop {
        top: -100px;
    }
}
.open-shop-box {
    border: 2px solid #e4e7ed;
}
.open-shop-container {
    .open-shop-img {
        @media (max-width:1023px) {
            display: none;
        }
    }
    .open-shop {
        @media (max-width:767px) {
            margin-top: 20px!important;
        }
    }
    .open-shop-box {
        @media (max-width:1023px) {
            border: 0;
            padding-top:0!important;
            padding-bottom:24px!important;
            .open-shop-descr {
                display: none!important;
            }
        }
    }
}
.sidebar-langbar  {
    border-bottom: 1px solid #EDEFF3;
    &.top {
        border-top: 1px solid #EDEFF3;
    }
}
// livecaller
.live-caller-outer {
    @media (max-width:1024px) {
        opacity: 0;
    }
}
.lc-frame-widget-open {
    @media (max-width:1024px) {
        right: 0;
        bottom: 0;
    }
}

body {
    .live-caller-outer,  .close_live_caller  {
        @media (max-width:1024px)  {
            pointer-events: none;
        }
      
    }
    &.menu-open {
        .live-caller-outer,  .close_live_caller  {
            @media (max-width:1024px)  {
                opacity: 1;
                z-index: 9999997;
                pointer-events: all;
            }
            @media (max-width:767px)  {
                display: none;
            }
          
        }
        .livecaller {
            z-index: 9999998;
        }
    }
}