* {
    box-sizing: border-box;

}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

.wrapper {
    background-color: #F1F3F6;

    * {
        ::-webkit-scrollbar {
            //    display: none;
        }
    }
}

main {
    background-color: #F1F3F6;
}

.container {

    @media (min-width:1300px) {
        max-width: 1272px !important;
        margin-left: auto;
        margin-right: auto;
    }

    @include media-breakpoint-down(lg) {
        padding: 0 30px;
        max-width: 100%;
    }

    @include media-breakpoint-down(sm) {
        padding: 0 15px;
        max-width: 100%;
    }
}

.mobile-100 {
    @include media-breakpoint-down(sm) {
        width: 100%;
    }
}

.mobile-radius-0 {
    @media (max-width:767px) {
        border-radius: 0 !important;
    }
}

.right-initial {
    right: initial !important;
}

.d-inherit {
    display: inherit;
}

.overflow-visible {
    overflow: visible;
}

.overflow-y-auto {
    overflow-y: auto;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

.tab-btn-width-limit {
    max-width: 200px;
}

.border-left-radius-none {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.border-right-radius-none {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.border-top-left-radius-none {
    border-top-left-radius: 0 !important;
}

.border-top-right-radius-none {
    border-top-right-radius: 0 !important;
}

.border-bottom-left-radius-none {
    border-bottom-left-radius: 0 !important;
}

.border-bottom-right-radius-none {
    border-bottom-right-radius: 0 !important;
}

.keep-text {
    word-break: keep-all;
}

.border-mobile-none {
    @media (max-width:767px) {
        border: none !important;
    }
}

.border-desktop-left {
    @media (min-width:767px) {
        border-left-width: 2px !important;
    }
}

.border-top-mobile {
    @media (max-width:767px) {
        border-top: 2px solid black !important;
        border-left: 0 !important;
        border-color:rgba(137, 150, 174, .15) !important;
    }
}

.z-index-999 {
    z-index: 99999 !important;
}

.z-index-11 {
    z-index: 11 !important;
}

.z-index-998 {
    z-index: 998;
}

.z-index-997 {
    z-index: 997;
}
.z-index-999991 {
    z-index: 999991;
}

.z-index-9999999999 {
    z-index: 9999999999 !important;
}

.z-index-99999999999 {
    z-index: 99999999999 !important;
}

ul {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &.list-style {

        padding: 10px;
        margin-left: 15px;

        li {
            list-style: outside;
        }


    }
}

li {
    list-style: none;
    margin: 0;
    padding: 0;
}

h1,
h2,
h3 {
    margin: 0;
    padding: 0;
}

.bg-rgba-blue-gray {
    background-color: rgba(137, 150, 174, 0.16);
}

.clamp {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &._2 {
        -webkit-line-clamp: 2;
    }

    &._3 {
        -webkit-line-clamp: 3;
    }

    &._4 {
        -webkit-line-clamp: 4;

        &.m_0 {
            @media (max-width:767px) {
                display: none;
            }
        }
    }

    &._5 {
        -webkit-line-clamp: 5;

        &.m_2 {
            @media (max-width:767px) {
                -webkit-line-clamp: 2;
            }
        }
    }

    &.m_0 {
        @media (max-width:767px) {
            display: none;
        }
    }

    &.clamp-sm-3 {
        @media (max-width:767px) {
            -webkit-line-clamp: 3;
        }
    }
}

.flex-1 {
    flex: 1;
}

.flex-mobile-1 {
    @media (max-width:767px) {
        flex: 1;
    }
}

.flex-shrink {
    flex-shrink: 0;
}

.flex-md-shrink {
    @media (min-width:768px) {
        flex-shrink: 0;
    }
}

.break-all {
    word-break: break-all;
}

// breadcrumbs
.breadcrumb-item+.breadcrumb-item::before {
    content: url('../../../img/icons/breadcrumbs-icon.svg');
    margin: 0 8px;
}

// gradient
.bg-gradient {
    background-image: $bg-gradient;
    transition: .5s ease-in-out;

    &:hover {
        background-image: linear-gradient(#fec900, #fec900);
    }
}

.bg-gradient-blue {
    background-image: linear-gradient(80deg, #728cfb, #7d94fa);
    transition: .5s ease-in-out;

    &:hover {
        background-image: linear-gradient(#728cfb, #728cfb);
    }
}

.bg-gradient-green-yellow-red {
    background-image: linear-gradient(to left, #0ec604, #ffe139 51%, #ff3b30);
    transition: .5s ease-in-out;
}

.bg-gradient-yellow-white {
    background: $gradient-yellow-white;
}

// banner
.banner-box {
    width: 100%;
    max-width: 192px;
    height: 278px;
    background-color: transparent;
    margin: 0 auto;

    a {
        text-decoration: underline;

        img {
            width: 100%;
            object-fit: contain;
        }
    }
}

.object-fit-cover {
    object-fit: cover;
}

.border-radius-10 {
    border-radius: 10px;
}

.user_menu {
    width: 100%;
    flex: 0 0 295px;
    // margin-left: 15px;
}

.box-shadow-none {
    box-shadow: none !important;
}

.border-2px {
    border-width: 2px !important;
}

.border-8px {
    border-width: 8px !important;
}

// logos
// tags
.tags {
    padding-bottom: 16px;
    // border-bottom: 1px solid #E4E7ED;
}

.tags-line {
    width: calc(100% - 16px);
    margin: 0 auto;
    height: 1px;
    background-color: #E4E7ED;
}

.search-tags {
    z-index: 0;


}

.slide-next,
.slide-prev {
    width: 34px;
    height: 34px;
    border-radius: 16px;
    box-shadow: 0 2px 6px 0 rgba(39, 42, 55, 0.1);
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 10;
    top: 1px;

}

.slide-next {
    right: -5px;
}

.slide-prev {
    left: -1px;
}

.product-top-banner {
    margin-left: auto !important;
    margin-right: auto !important;
    overflow: hidden;
    height: 90px;
    border-radius: 16px;
    max-width: 728px;
    //background-color: #e4e7ed;

    @media (max-width:767px) {
        height: 100px;
        max-width: 320px;
    }
}

.product-video-banner {
    border-radius: 16px;
    background-color: #ffffff;
}

.custom-control {
    margin-left: -24px;
}

.custom-control-label,
.custom-control-label.not-active {
    cursor: pointer;
    padding-left: 24px;

    &::after {
        left: 0;
    }

    &::before {
        left: 0;
        margin-right: -1.5rem;
    }
}

.custom-control-input:checked~.custom-control-label::before,
.custom-control-input.active~.custom-control-label::before {
    color: #ffffff;
    border-color: $market-color;
    background-color: $market-color;
}

// view style
.view-style-btn {
    width: 26px;
    height: 26px;
    //background-color: rgba($color: #8996ae, $alpha: 0.1);
    outline: none;
    box-shadow: none !important;

    &.active {
        background-color: $market-color;

        svg {
            g {
                opacity: 1;
            }

            rect {
                fill: white;
            }
        }
    }

    &:hover {
        background-color: $market-color;

        svg {
            g {
                opacity: 1;
            }

            rect {
                fill: white;
            }
        }
    }

    @media (max-width:767px) {
        // display: none!important;
    }
}

.object-fit-contain {
    object-fit: contain;
}

html {
    overflow-x: hidden;
    body {
        overflow-y: scroll;
        overflow-x: hidden;

        .toggle-cart {
            &.active {
                background-color: $market-color !important;

                &:hover {
                    background-color: $market-color !important;

                }

                &:focus {
                    background-color: $market-color !important;
                }

                .cart-i {
                    background-image: url('../../../img/icons/add-cart-white.svg') !important;
                }

                span {
                    color: #ffffff !important;
                }
            }

            &:hover {
                background-color: rgba($color: #ffd102, $alpha: 1) !important;

                @media (min-width: 1024px) {
                    .cart-i {
                        background-image: url('../../../img/icons/add-cart-white.svg') !important;
                    }
                }

                span {
                    color: #ffffff !important;
                }
            }

        }
    }
}

html {
    body {
        .toggle-fav {
            &.active {
                background-color: rgba($color: #ffd102, $alpha: 1) !important;

                &:hover {
                    background-color: $market-color !important;
                }

                &:focus {
                    background-color: $market-color !important;
                }

                .favorites-i {
                    background-image: url('../../../img/icons/favorites-white.svg');
                }
            }

            &:hover {
                background-color: $market-color !important;

                @media (min-width: 1024px) {
                    .favorites-i {
                        background-image: url('../../../img/icons/favorites-white.svg');
                    }
                }

                span {
                    color: #ffffff !important;
                }
            }
        }
    }
}

// vip status and discount
.vip-box {
    // top:22px;
    // left: 22px;
    z-index: 10;
}

.box-item {
    border-radius: 11px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.percent {
        padding: 0 8px;
    }

}

.hide-on-mobile {
    @media (max-width: 767px) {
        display: none !important;
    }
}

.hide-on-desktop {
    @media (min-width: 767px) {
        display: none !important;
    }
}

.max-width-100px {
    max-width: 100px !important;
}

.box-item-badge {
    border-radius: 11px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width:767px) {
        justify-content: flex-start;
    }
}

.swal-modal {
    border-radius: 32px;
    padding-top: 64px;
    width: 624px;

    @media (max-width:767px) {
        width: calc(100% - 20px);
        max-width: 624px;
    }

    .btn {
        outline: none !important;
        box-shadow: none !important;
        // border: 0!important;
    }

    .close-popup {
        position: absolute;
        padding: 12px;
        top: 20px;
        right: 20px;

        &.close-popup-feedback {
            top: 20px;
            right: 20px;
            padding: 0;
        }
    }
}

.swal-content {
    padding: 0;
    margin: 0 !important;

    textarea {
        min-height: 309px;
        outline: none !important;
        box-shadow: none !important;
        border: 1px solid #e4e7ed !important;

        &::placeholder {
            color: #272a37;
        }

        &.offer-textarea {
            min-height: 160px;
        }
    }
}

.feedback-popup {
    width: auto;
    position: fixed;
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 40px;
    border-radius: 24px;
    background-color: #ebfaed !important;
    transform: translateY(100px);
    animation: none !important;
    transition: .5s ease-out;

}

.swal-overlay {
    background-color: rgba($color: #272a37, $alpha: .95) !important;
}

.swal-overlay--show-modal {
    .feedback-popup {
        transform: translateY(0);
    }
}

.shops-phone {
    cursor: pointer;
}

.balance-sidebar {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    border-radius: 11px;
    background-color: rgba($color: #4a6cfa, $alpha: 0.1);
    transition: .5s ease-in-out;
    z-index: 99;
    cursor: pointer;

    .current-balance {
        display: flex;
        align-items: center;
        transition: .2s ease-in-out;
    }

    .add-balance {
        display: flex;
        transform: scale(0);
        position: absolute;
        transition: .2s ease-in-out;

    }

    &:hover {
        background-color: transparent;

        .current-balance {
            transform: scale(0);
        }

        .add-balance {
            transform: scale(1);
            transition-delay: .2s;
        }
    }

    &.null {
        background-color: transparent;

        .current-balance {
            transform: scale(0);
        }

        .add-balance {
            transform: scale(1);
        }
    }
}

.form-control {
    &:focus {
        color: #495057;
        background-color: #ffffff;
        outline: 0;
        box-shadow: none;
    }
}

label.error {
    font-size: 12px;
    color: #ff665e !important;
}

.error-text {
    font-size: 12px;
    color: #ff665e;
    margin-top: 5px;
}

.has-error input,
.has-error textarea {
    border-color: #ff3b30 !important;
}

.right-side {
    flex: 0 0 100%;

    &.right-side-profile {
        flex: 1 !important;
        max-width: 768px;
    }

    @media (min-width:1365px) {
        flex: 0 0 624px;
        max-width: 624px;
        padding: 0;

        &.notification-container {
            flex: 0 0 732px;
            max-width: 732px;

        }
    }

    @media (max-width:1365px) {
        margin-left: 0 !important;

    }

}

.modal-adress-from {
    .right-side {
        @media (min-width: 1025px) {
            max-width: 100%;
        }
    }
}

.btn {
    box-shadow: none !important;
    outline: none !important;
}

.card-col {
    &:hover {
        .card-arrows {
            opacity: 1;
        }
    }
}

.card-hover {
    &:hover {
        .card-arrows {
            opacity: 1;
        }
    }
}

.card-arrows {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 20;
    opacity: 0;
    transition: 0.3s ease-in-out;

    @media (max-width:1100px) {
        opacity: 1;
    }

    .card-arr {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($color: #000000, $alpha: .4);
        transition: .3s ease-in-out;

        &:hover {
            background-color: rgba($color: #000000, $alpha: .8);
        }
    }
}

.hover-none {
    background-color: transparent !important;

}

.all-check {
    .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
        // background-image: url('../../../img/icons/select-all.svg');
        background-color: transparent;
    }

    .custom-checkbox .custom-control-input:checked~.custom-control-label::before {
        border-color: #fec900;
        background-color: white;
    }
}

.border-radius-18 {
    border-radius: 18px;
}

.border-radius-3 {
    border-radius: 3px;
}

.text-decoration-underline {
    text-decoration: underline !important;
}



.control-btn {
    .btn {
        transition: .2s ease-in-out;
        border: 1px solid;
        border-color: transparent;

        &:hover {

            background-color: $white;
            opacity: 0.65;

        }
    }
}

.footer-container,
.rules-link {
    li {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        a {
            opacity: .7;

            &:hover {
                opacity: 1;
                color: #1B1D27 !important;
            }
        }
    }
}

.captcha-text {
    a {
        &:hover {
            color: #325afa !important;
            text-decoration: underline !important;
        }
    }
}

// responsive base class
.m-hidden {
    @media (max-width:1024px) {
        display: none !important;
    }
}

.mobile-hidden {
    @media (max-width:767px) {
        display: none !important;
    }
}

.m-show {
    display: block;

    @media (min-width:1025px) {
        display: none !important;
    }
}

.edit-profile-head,
.edit-address-head {
    @media (max-width:767px) {
        padding-top: 24px;
    }

    ul {
        @media (max-width:767px) {
            flex-direction: column;
            align-items: flex-start;
        }

        li {
            @media (max-width:767px) {
                margin-right: 0 !important;
            }

            &:nth-child(1) {
                @media (max-width:767px) {
                    margin-bottom: 12px;
                }
            }
        }
    }
}


// date picker
.react-datepicker__close-icon {
    display: none !important;
}

html {
    body {
        .react-datepicker {
            background-color: #ffffff;
            border: solid 1px #e4e7ed;
            border-radius: 20px;
            overflow: hidden;

        }

        .react-datepicker__header {
            padding-top: 24px;
            border-color: #f3f4f6;
            background-color: #ffffff;
        }

        .react-datepicker__triangle {
            display: none;
        }

        .react-datepicker__day {
            border-radius: 50%;
            background-color: transparent;
            border: none;
            outline: none;

            &:hover {
                border-radius: 50%;
                background-color: #454b57;
                color: #ffffff;
            }
        }

        .react-datepicker__day--keyboard-selected {
            background-color: #454b57;
        }

        .react-datepicker__navigation {
            border-radius: 50%;
            background-color: rgba($color: #8996ae, $alpha: .1);
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            top: 20px;

            &:hover {
                background-color: rgba($color: #8996ae, $alpha: .2);
            }

            &.react-datepicker__navigation--previous {
                left: 24px;

                &::after {
                    content: '';
                    width: 20px;
                    height: 15px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-image: url('../../../img/icons/left-arr.svg');
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                }

                span {
                    display: none;
                }
            }

            &.react-datepicker__navigation--next {
                right: 24px;

                &::after {
                    content: '';
                    width: 20px;
                    height: 15px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-image: url('../../../img/icons/right-arr.svg');
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                }

                span {
                    display: none;
                }
            }
        }

        .react-datepicker__month {
            margin: 24px;
        }

    }
}

.react-datepicker__header__dropdown {
    select {
        border: none;
        outline: none;
        box-shadow: none;
    }
}

.shops-drop {
    .dropdown-menu {
        max-height: 300px;
        overflow-y: auto;
    }
}

.sg-selectbox__indicators {
    cursor: pointer;
}

.sg-selectbox-container .sg-selectbox__control {
    border: solid 1px #e4e7ed !important;
    box-shadow: none !important;
    width: 100%;

    &:hover {
        border-color: #9aadfc !important;
        ;

    }

    &.sg-selectbox__control--menu-is-open {
        border-color: #9aadfc !important;
        ;
    }

    .sg-selectbox__value-container {
        padding-right: 8px;

    }

    @media (max-width:767px) {
        .sg-selectbox__value-container {
            text-align: left;
        }
    }

}


.sg-selectbox-container.select-borderless .sg-selectbox__control {
    .sg-selectbox__value-container {
        padding-left: 0;
        padding-right: 0;
        font-size: 14px;
    }
    .sg-selectbox__indicators {
        display: none;
    }
    border: none !important;
}

.proms-select {
        .sg-selectbox__value-container  {
            padding-right: 0!important;
            padding-left: 14px!important;
    
        }
    
}

.sg-selectbox-container.border-danger .sg-selectbox__control {
    border: solid 1px $danger !important;

    &:hover {
        border-color: $danger !important;
        ;

    }

    &.sg-selectbox__control--menu-is-open {
        border-color: $danger !important;
        ;
    }
}

.agreement-check {
    .custom-checkbox-2 .custom-control-label::before {
        top: 3px !important;
    }

    .custom-checkbox-2 .custom-control-label::after {
        top: 3px !important;
    }
}

.scroll-top {
    transition: .3s ease-in-out;

    &:hover {
        background-color: #FEC900 !important;
    }
}

.orders-header-finances {
    @media (max-width:767px) {
        position: fixed;
        width: 100%;
        height: 70vh;
        bottom: -100%;
        opacity: 0;
        transition: 1s;
        z-index: 999;
        display: flex;
        overflow-y: auto;
        flex-direction: column;
        justify-content: flex-start !important;
        align-items: flex-start !important;
        border-top-left-radius: 18px !important;
        border-top-right-radius: 18px !important;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        padding: 40px 30px 0 30px !important;

        &.active {
            bottom: 0;
            opacity: 1;

            &:before {
                content: '';
                position: absolute;
                top: -20px;
                left: 50%;
                transform: translateX(-50%);
                width: 39px;
                height: 6px;
                border-radius: 4px;
                background-color: $white;
            }
        }

        .date-picker-box {
            width: 100%;

            .react-datepicker-wrapper {
                width: 100%;

                input {
                    width: 100%;
                    // border: none!important;
                }

                &:nth-child(1) {
                    margin-right: 8px;
                }
            }
        }

        .filter-price {
            margin: 30px 0 0 0 !important;
        }

        .filter-cats {
            margin-top: 30px;
        }
    }

    .sg-selectbox-container {
        .sg-selectbox__control {
            height: auto;
        }
    }
}

.promitions-btn {
    .btn {
        &~ {
            .tooltip-info {
                position: absolute;
                top: -45px;
                left: 50%;
                transform: translateX(-50%);
                background: $white;
                border-radius: 10px;
                text-align: center;
                box-shadow: 0px 3px 6px 0px rgba(39, 42, 55, 0.26);
                -webkit-box-shadow: 0px 3px 6px 0px rgba(39, 42, 55, 0.26);
                -moz-box-shadow: 0px 3px 6px 0px rgba(39, 42, 55, 0.26);
                opacity: 0;
                pointer-events: none;
                z-index: 999;

                &::after {
                    content: '';
                    width: 0;
                    height: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid white;
                    position: absolute;
                    left: 48%;
                    transform: translateX(-50%);
                    bottom: -5px;
                }

                &.buy-tooltip {
                    min-width: 141px;
                }
            }
        }

        &:hover {
            &~ {
                .tooltip-info {
                    opacity: 1;
                }
            }
        }
    }
}

.btn {
    &:disabled {
        cursor: default !important;
    }
}

.similar-products {
    .card {
        border: 1px solid transparent !important;

        &:hover {
            border-color: $dark-purple-blue !important;
        }
    }

}

.hover-underline {
    &:hover {
        text-decoration: underline;
    }
}

.pr-action-btn {
    height: 48px;
}

.place-content-center {
    place-content: center;
}

.transformY-50 {
    top: 50%;
    transform: translateY(-50%);
}


.custom-modal-container {
    .tbc-connect {
        align-items: flex-end;
        margin: 0;
        min-height: 100%;
        flex-direction: row;
        -webkit-transform: none;
        transform: none;

        .custom-modal-inner {
            max-width: 100%;
            border-radius: 0;
            margin: 0;
            border-top: 2px solid $primary;

        }
    }

    &.tbc-connect-container {
        top: auto;

        &.full-height {
            height: 100%;
        }
    }
}

.custom-select-option {
    margin: 8px;
    padding: 20px 24px 20px 24px;
    cursor: pointer;
    background-color: rgba(241, 243, 246, 0.5);
    border-radius: 20px;
}

.questionnaire-box {
    width: 100%;
    max-width: 492px;

    @media (max-width:767px) {
        left: 50%;
        transform: translateX(-50%);
        max-width: 370px;
    }
}

.questionnaire-actions {
    .cancel-btn {
        &:hover {
            background-color: $rgba-white-30 ;
        }
    }
}

.react-checkbox-select {
    border: 0;

    .rcs-shown__menu-list {
        margin: 5px 0;

        &::-webkit-scrollbar {
            padding-right: 2px;
            width: 2px;
            background: none;
            border-radius: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #ffffff;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            padding-right: 2px;
            background: #d7dce4;
            border-radius: 10px;
            width: 2px;
        }
    }

    .rcs-shown__menu {
        position: relative;
        top: 0;
        margin: 5px 0;
        border: 0;
        box-shadow: none;
    }

    .rcs-shown__indicator-separator {
        display: none;
    }

    .rcs-shown__option {
        padding: 8px 20px;
    }

    .rcs-rcs-shown__option--is-focused {
        background-color: #EBEEF6;
    }

    .css-1n7v3ny-option:active {
        background-color: transparent;
    }

    .css-yt9ioa-option:active {
        background-color: transparent;
    }

    .rcs-hidden__menu-list {
        margin: 5px 0;

        &::-webkit-scrollbar {
            padding-right: 2px;
            width: 2px;
            background: none;
            border-radius: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #ffffff;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            padding-right: 2px;
            background: #d7dce4;
            border-radius: 10px;
            width: 2px;
        }
    }

    .rcs-hidden__menu,
    .rcs-show__menu {
        position: relative;
        top: 0;
        margin: 5px 0;
        border: 0;
        box-shadow: none;
    }

    .rcs-hidden__option--is-selected {
        background-color: transparent;
        color: rgba(38, 132, 255);
    }

    .css-9gakcf-option:active {
        background-color: transparent;
    }

    .rcs-hidden__control,
    .rcs-shown__control {
        padding: 11px 16px 10px 16px;
        margin: 0 20px;
        border-radius: 8px;
        border: 1px solid #E4E7ED;
    }

    .rcs-shown__control--is-focused,
    .rcs-shown__control--is-focused:hover {
        border: 1px solid #E4E7ED !important;
        box-shadow: none !important;
    }

    .rcs-hidden__clear-indicator {
        padding: 0;
    }

    .rcs-hidden__indicator-separator {
        display: none !important;
    }

    .rcs-hidden__dropdown-indicator {
        display: none !important;
    }

    .rcs-hidden__multi-value__remove {
        display: none !important;
    }

    .rcs-hidden__value-container {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 24px;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .rcs-hidden__multi-value {
        background-color: transparent !important;
        flex-shrink: 0;
    }

    .rcs-hidden__multi-value__label {
        background-color: transparent !important;
        overflow: visible;
        margin: 0px;
        padding-left: 3px !important;

        &::after {
            content: ',';
        }
    }

    .rcs-shown__value-container {
        padding: 0px;
    }

    .rcs-shown__placeholder {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #8996AE;
        font-family: TBC X;
        font-size: 12px;
    }

    .rcs-shown__indicators {
        display: none;
    }

    &.service-centers {
        .sg-selectbox__control {
            height: auto;

            .sg-selectbox__clear-indicator {
                display: none !important;
            }
        }

        .sg-selectbox__multi-value {
            border-radius: 7px;
        }
    }
}

.product-details-content {
    .user-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.product_slider_cont {
    .swiper-slide {
        height: initial !important;
    }
}

.snack-bar-index {
    z-index: 99999999 !important;
}

/*Callendar*/
.react-datepicker__day--selected {
    background-color: #000000 !important;
}

.notification-list-inner,
.notification-text {
    &>* {
        display: initial;
    }

}

.text-editor-styles {

    ul,
    ol {
        li {
            list-style: inherit;
        }
    }

    &.rules {

        ul,
        ol {
            margin-left: 24px;
        }
    }
}

.bg-drop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    background-color: #000;

    &.bg-opacity {
        background-color: hwb(240deg 11% 87% / 60%);
    }
}

@media screen and (min-width: 1024px) {
    .hidden-toggle {
        .hiddenelement {
            display: none !important;
        }

        &:hover {
            .nothiddenelement {
                display: none !important;
            }

            .hiddenelement {
                display: block !important;
            }
        }
    }
}

.hidden-toggler {
    .hiddenelement {
        display: none !important;
    }

    &:hover {
        .nothiddenelement {
            display: none !important;
        }

        .hiddenelement {
            display: block !important;
        }
    }
}


.tnet-modal {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
}

.gallery-image-zoom {
    transform: scale3d(1, 1, 1);
    backface-visibility: hidden;
}


.zoom-container {
    .react-transform-wrapper {
        height: 100% !important;
        background-color: white;
    }
}



// 
.no-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    white-space: nowrap;
}

.border-gray-250 {
    border-color: #dcdfe6 !important;
}

.text-gray-150 {
    color: #606266 !important;
}

.livecaller-shadow {
    box-shadow: 0 0 15px -4px #000;
}

.resize-none {
    resize: none;
}

.livecaller {

    input,
    textarea {
        &::placeholder {
            color: #a4aec1;
        }
    }
}

.lc-frame-launcher {
    display: none !important;
}

.captcha-badge {
    position: fixed;
    z-index: 99999999;
    bottom: 5px;
    left: 5px;

    .grecaptcha-badge {
        overflow: hidden !important;
        transition: all 0.3s ease !important;
        position: relative;
        left: -190px !important;
    }

    .grecaptcha-badge:hover {
        left: 0 !important;
    }
}

.box-shadow {
    box-shadow: 0px 4px 36px 0px #0000001A;
}

.pickupmodal {
    img {
        width: 100%;
    }
}

.captcha-badge {
    display: none !important;
}

.custom-modal-dialog {
    &.promos-modal {
        // max-width: 924px!important;

        .custom-modal-inner {
            max-width: 924px !important;
            max-height: 634px !important;
            border-radius: 20px;
        }
    }
}

.sg-selectbox-container {
    &.products-quantity {
        .sg-selectbox__control {
            height: 28px;
            min-height: inherit;
            padding: 0;
            border-radius: 4px;

            .sg-selectbox__value-container {
                padding: 0 8px;
            }

            .sg-selectbox__indicator {
                width: 16px;
                height: 16px;
                background-color: #F8F9FD;
                border-radius: 50%;
                margin-right: 8px;
                padding: 0;
                background-position: center;
            }
        }
    }
}

.custom-modal-dialog {
    &.promos-modal {
        // max-width: 924px!important;

        .custom-modal-inner {
            max-width: 924px !important;
            max-height: 634px !important;
            border-radius: 20px;
        }
    }
}

body {
    &.header-footer-none {
        .modal-center {
            height: 100vh;
            justify-content: center;
        }

        main {
            background-color: initial;
        }

        .header-top-content,
        footer {
            display: none !important;
        }

        .nm-ado-footer,
        .nm-ado-mobile {
            display: none;
        }

        ._hj_feedback_container {
            display: none;
        }
    }
}

.tnet-modal-mobile {
    @media (max-width:767px) {
        max-height: 550px;
        overflow-y: auto;
    }
}

.w-fit-content {
    width: -moz-fit-content !important;
    width: fit-content !important;
}

.small-info-tooltip {
    position: absolute;
    border-radius: 16px;
    width: 390px;
    min-height: 126px;
    z-index: 99999;
    padding: 20px;

    &.right {
        top: -12px;
        left: 105%;

        &:before {
            content: "";
            position: absolute;
            left: -8px;
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-right: 10px solid $nmbluedark;
        }
    }

    &.bottom {
        top: 150%;
        left: 0%;

        @media (max-width:767px) {
            top: 125%;
        }

        &:before {
            content: "";
            position: absolute;
            top: -14px;
            left: 20%;
            transform: rotate(90deg);
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-right: 10px solid $nmbluedark;
        }

        @media (max-width:767px) {
            width: auto;
            height: auto;

            &:before {
                left: 50%;
            }
        }
    }
}

.minor-tooltip-installment {
    .installment-tooltip {
        //width: 297px;
        background-color: #eaeefe;
        border-radius: 16px;
        right: 0;
        top: 56px;
        transform: translateY(10px);
        opacity: 0;
        transition: .3s ease-in-out;

        @media (max-width:767px) {
            left: 0;
        }
        &.up-arrow {
            @media (max-width:767px) {
                right: -13px!important;
                left: auto;
            }
            &::after {
                right: 7px;
                left: auto;
            }
        }
        @media (max-width:480px) {
            left: 0;
            width: 100%;
        }

        &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: -5px;
            left: 8%;
            transform: translateX(-50%);
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #eaeefe;
        }
    }

    &.show-tooltip {
        .installment-tooltip {
            opacity: 1;
            transform: translateY(0);
        }

    }

    &.hover-show:hover {
        .installment-tooltip {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.btn {
    &.btn-bg-hover:hover {
        background-color: #E9EAEB !important;
    }

}

.link-dark {
    a {
        color: $dark;
    }
}

.safety-slider {
    .swiper-pagination {
        bottom: 20px;
        left: 20px;
        font-size: 12px;
        width: auto !important;
        height: 20px !important;
    }
}

.swiper-safety-sldier {
    position: initial !important;
}

.return-tooltip {
    position: relative;

    .tooltip-text {
        visibility: hidden;
        width: 238px;
        background: #f2f3f6;
        opacity: 0.95;
        box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.05);
        border-radius: 12px;
        border-radius: 6px;
        padding: 18px 16px;
        position: absolute;
        z-index: 1;
        top: 40px;
        left: 0;
        transform: translateX(-7%);
        opacity: 0;
        transition: opacity 0.3s, visibility 0.3s;
    }

    &.active {
        visibility: visible;
        opacity: 1;
        border-radius: 12px;

        &:after {
            position: absolute;
            content: "";
            width: 0;
            top: -7px;
            left: 12px;
            z-index: -1;
            height: 0;
            border-left: 16px solid transparent;
            border-right: 16px solid transparent;
            border-bottom: 25px solid #f2f3f6;
        }
    }
}

.custom-modal-wrapper {
    &.Concurention-Agancy-mobile {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 19px);
    }
}

.Concurention {
    &.border-radius-12px-important {
        border-radius: 12px !important;
    }
}

.hover-tbc-btn {
    &:hover {
        background-color: #32BDF1 !important;
    }
}

.border-focus-red {
    position: relative;

    &.form-control:focus {
        border: 1px solid red !important;

    }
}

// 
.new-controll {
    &::placeholder {
        color: $rgba-dark-80 !important;
        font-size: $font-12 !important;
        font-family: $font-family-medium !important;
    }
}

.icon-add {
    background-image: url('../../../img/icons/icon-add.svg') !important;
    background-repeat: no-repeat;
    background-position: 7px;
}

.icon-checked {
    background-image: url('../../../img/icons/icon-checked.svg') !important;
    background-repeat: no-repeat;
    background-position: 7px;
}

.attrs-hover {
    .rcs-shown__control {
        padding: 0 16px !important;
        height: 37px !important;
        border-radius: 8px !important;
    }

    .css-1n7v3ny-option {
        background-color: #EBEEF6 !important;
    }
}

.switch {
    .switchs {
        position: relative;
        display: flex;
        align-items: center;
        width: 30px;
        flex-shrink: 0;
        height: 18px;
    }

    .checkbox {
        visibility: hidden !important;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;

    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: 0.4s;

        &:before {
            position: absolute;
            content: "";
            height: 13px;
            width: 13px;
            left: 3px;
            top: 50%;
            transform: translateY(-50%);
            background-color: white;
            transition: 0.4s;

        }
    }

    input:checked+.slider {
        background-color: #fec900;

    }

    input:focus+.slider {
        box-shadow: 0 0 1px #fec900
    }

    input:checked+.slider:before {
        transform: translate(calc((100% - 2px)) , -50%);
    }

    .slider.round {
        border-radius: 34px;

        &:before {
            border-radius: 50%;

        }
    }

}


.checkbox-switch {
    position: relative;
    display: flex;
    align-items: center;
    width: 30px;
    flex-shrink: 0;
    height: 18px;

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: 0.4s;

        &:before {
            position: absolute;
            content: "";
            height: 13px;
            width: 13px;
            left: 3px;
            top: 50%;
            transform: translateY(-50%);
            background-color: white;
            transition: 0.4s;

        }
    }

    input:checked+.slider {
        background-color: #26B753;

    }

    input:focus+.slider {
        box-shadow: 0 0 1px #26B753
    }

    input:checked+.slider:before {
        transform: translate(calc((100% - 2px)) , -50%);
    }

    .slider.round {
        border-radius: 34px;

        &:before {
            border-radius: 50%;

        }
    }
}

.overflow-wrap {
    overflow-wrap: anywhere;
}

.show-rate-modal-inner-with-images {
    .scroll-modal {
        @media (max-width:767px) {
            height: 80vh;
            overflow-y: scroll;
        }
        
    }
}



// .newyear-category {
//     background-image: url('../../../img/icons/newyear.svg') !important;
//     background-repeat: no-repeat;
//     background-position: 0 0;
//     border-radius: 12px;


//     .primary-cats li a {
//         color: white !important;
//     }

//     .primary-cats li:hover {
//         background: white !important;
//     }

//     .primary-cats-text {
//         color: white !important;
//     }
//     i {
//         opacity: 0;
//     }
//     &.active {
//         background-image: url('../../../img/icons/newyearhover.svg') !important;
//         background-repeat: no-repeat;
//         background-position: 0 0;
//         border-radius: 12px;
//         a {
//             &>div {
//                 white-space: nowrap;
//             }
//         }

//         svg {
//             path {
//                 &:last-child {
//                     fill: white !important;
//                 }
//             }

           
//         }
        
//         i {
//             opacity: 1;
//            background-image: url('../../../img/icons/maincat-arrwhite.svg');
//         }

//     }
// }

.chat-font-weight-600 {
    font-weight: 600;
}
.grecaptcha-badge {
    visibility:hidden !important;
}

.filter-sticky {
    position: sticky;
    top: -24px;
    z-index: 99;
    background: #f1f3f6;
}


.show-modal {
    .cat-header {
        display: block!important;
        &.cat-filter {
            background: var(--BLUE-BLUE-10, #EAF0FC);
            color: var(--BLUE-BLUE-100, #2B6ADE);
        }
    }
}
.tags-filter {
    .search-tags {
        margin-bottom: 0!important;
        padding-top: 16px!important;
        .close-tags {
            display: none;
        }
    }
}



@media (min-width:767px) {
    .w-min-md-100 {
        width: 100%!important;
    }
}