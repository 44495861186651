.progress-bar-custom {
    height: 6px;
    width: 100%;
    background-color: #e0e0de;
    border-radius: 50px;
    margin: auto;
    overflow: hidden;
    div {
        height: 100%;
        background-image: linear-gradient(to left, #4cd964 99%, #73e186);
        transition: 'width 1s ease-in-out';
        border-radius: 4px;
        text-align: right;
        span{
            padding: 5px;
            color: white;
            font-weight: bold;
        }
    }
}