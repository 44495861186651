.custom-choose-btn-type  {
    margin-right: 10px;
    margin-bottom: 6px;
    &:last-child {
        margin-right: 0;
    }
    .btn-check {
        position: absolute;
        clip: rect(0,0,0,0);
        pointer-events: none;
        & ~ {
            .form-btn-label {
                transition:none;
                background-color: $nm-gray;
                color: $dark;
                font-weight: normal;
                min-width: 90px;
            }
        }
        &.active ~ {
            .form-btn-label {
                background-color: $blue;
                color: $white;
            }
        }
    }
    
}
// box view
.online-types {
    .custom-choose-box-type {
        width: 100%;
        border:1px solid #EDF3FC;
        font-weight: normal;
        &.active  {
            border:1px solid $blue;
            background-color: #EDF3FC;
        }
    }
}

.custom-form-switch {
    .custom-control-switch-label {
        width: 46px;
        height: 24px;
        position: relative;
        background-color: rgba($color: #8996AE, $alpha: .1);
        cursor: pointer;
        &::after {
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            background-color: $blue-gray;
            top: 50%;
            transform: translateY(-50%);
            left: 3px;
            border-radius: 50%;
        }
    }
    .switch-input {
        &.active  ~ {
            .custom-control-switch-label {
                background-color: rgba($color: #4A6CFA, $alpha: .1);
                &::after {
                    left: initial;
                    right: 3px;
                    background-color: $blue;
                }
            }
        }
    }
}

.files-preview-thumbs {
    position: relative;
    overflow: hidden;
    .uploaded-img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            top: 0;
            left: 0;
            background-color: rgba($color: #272A37, $alpha: .6);
            opacity: 0;
            z-index: 0;
        }
    }
    .remove-img {
        position: absolute;
        width: 48px;
        height: 48px;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        margin: 0 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: .5s ease-in-out;
        z-index: 1;
        &:hover {
            background-color:rgba($color: #8996AE, $alpha: .4);
        }
    }
    .choose-primary  {
        position: absolute;
        width: 48px;
        height: 48px;
        top: 50%;
        left: 8px;
        transform: translateY(-50%);
        margin: 0 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: .5s ease-in-out;
        z-index: 1;
        &:hover {
            background-color:rgba($color: #8996AE, $alpha: .4);
        }
    }
    &:hover {
        .uploaded-img {
            &::after {
                opacity: 1;
            }
        }
        .remove-img {
            opacity: 1;

        }
        .choose-primary {
            opacity: 1;
        }
    }

    &:nth-child(2) {
        .remove-img {
            right: initial;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin: 0;
            display: flex;
            justify-content: center;
        }
    }
    .img-loader {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #f9f9fb;
        z-index: 20;
    }
}
.custom-col-select {
    @media (min-width:768px) {
        &.even {
            padding-left: 15px !important;
        }
        &.odd {
            padding-right: 15px !important;
        }
    }
}
.add-from-price  {
        .sg-selectbox__control {
            height: 48px;
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
            & > div {
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
            }
        }
}
.add-btns-custom {
    border: 2px solid #e4e7ed!important;
    transition: .3s ease-in-out;
    &:hover {
        border-color: #fec900!important;
    }
}
.back-to-main-category {
    color: $black;
    font-size: 14px;
    font-family: $font-family-bold;
}
.divider-line {
    width: 48px;
    height: 2px;
    background-color: #8996ae;
}
.modal-adress-from {
    .add-address-content  {
        border: 0!important;
        & > div {
            @media (max-width:767px)  {
                padding: 24px 0!important;
            }
        }
    }
}
.usd {
    font-family:$font-family-base;
}
.input-group {
    .input-group-text  {
        border-color:#9aadfc;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
     }
 .form-control {
     &:focus {
         & ~ {
             .input-group-append {
                 .input-group-text  {
                    border-color:#9aadfc;
                    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                 }
            
             }
         }
     }
 }   
}
.attrs-form-box {
    .special-persons-input:focus:not(:checked) ~ .custom-control-label::before {
        border-color: transparent!important;  
    }
    .special-persons-input:focus:not(.active) ~ .custom-control-label::before {
        border-color: #E4E7ED!important;  
    }
}

.instructions-box {
    position: absolute;
    left:0;
    width: 330px;
    z-index: 99999;
    bottom: calc(100% + 30px);
    box-shadow: 0px 3px 16px 0px rgba(44,63,88,0.7);
    -webkit-box-shadow: 0px 3px 16px 0px rgba(44,63,88,0.7);
    -moz-box-shadow: 0px 3px 16px 0px rgba(44,63,88,0.7);
    .boxarrow {
        left: 50%;
        bottom: -16px;
        transform: rotate(30deg) translateX(-50%);
    }
    &.step-1 {
        .boxarrow {
            left: 35px;
            transform: rotate(30deg) translateX(0);
        }
    }
    &.step-2 {
        width: 312px;
        bottom: 100%;
        bottom: calc(100% - 20px);
    }
    &.step-3 {
        width: 312px;
        bottom: 100%;
        bottom: calc(100% + 15px);
        &.whith-adress {
            bottom: calc(100% - 20px);
        }
    }
 @media (max-width:767px) {
    width: 300px;
 }
}

.custom-border {
    border: 1px solid #4A6CFA!important;
}