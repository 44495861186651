
.account-adding-box {
    height: 145px;
    border-radius: 20px;
    border: 2px dashed $dark-market-color;
    position: relative;
    background-color: #ffffff;
    transition: .5s cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
        background-color: rgba($color: #ffff, $alpha: 0.4);

        //  box-shadow: 0px 8px 34px 0px rgba(254,201,0,0.1);
        .account-pin {
            transform: translateY(-5px) scale(1.1);
        }
    }

    @media (min-width:1500px) {
        // width: 298px;
    }

    @media (max-width:767px) {
        width: 100%;
        .w-md-auto {
            width: auto!important;
        }
    }

    &::after {
        content: '';
        //background-image: url('../../../img/icons/account-mask.svg');
        background-repeat: no-repeat;
        position: absolute;
        width: 300px;
        height: 147px;
        top: -1px;
        left: -1px;
    }
}

//  

.account-info-box {
    border-radius: 12px;
    background-color: #ffffff;
    margin-bottom: 24px;
    &.active {
        border: 1px solid #FFF4CC;
    }

    @media (min-width:1500px) {
        // width: 300px;
    }

    @media(max-width:767px) {
        width: 100%;
        margin-top: 8px;
    }

    .account-info-title {
        max-width: 200px;
    }
    
    .font-w-500 {
        font-weight: 500;
    }
}



.account-box .account-info-box {
    position: relative;
}
@media (max-width:767px) {
    .w-md-100 {
        width: 100%!important;
    }
}
.account-info-box {
    .btn {
        &.active {
            background: transparent!important;
            text-decoration: underline !important;
            font-weight: 500;
            img {
                display: none;
            }
        }
    }
    
    .bg-market-color:focus {
        // background-color: #fec900 !important;
    }

    &.active {
        .make-basic-text {
            display: none;   
        }
        .next-text {
            display: block;
        }
        .bg-market-color:focus {
            background-color: #fec900 !important;
        }
    }
    .next-text {
        display: none;
    }
}
.cols-none {
    .account-box-cont {
        &.col-12 {
            max-width: 100%!important;
        }
    }
}


