.product-info {
    .product-img {
        width: 64px;
        height: 64px;
        flex-shrink: 0;
        background-color: #ECEDEF;
    }
}
.product-col {
    @media (min-width:1100px) {
    width: 100%;
    min-width: 185px;
    }
    width: 100%;
    max-width: 185px;
    @media (max-width:767px) {
       max-width: 125px;
     }
}
.price-col  {
    @media (min-width:768px) {
        width: 100%;
        max-width: 88px;
    }
    @media (max-width:600px) {
       position: absolute;
       right: 20px;
      }
}
.table-row {
    position: relative;
    @media (max-width:600px) {
       padding: 24px 0 60px 24px!important;
       width: 100%;
       margin: 0 auto;
       &::after {
           content: '';
           position: absolute;
           width: calc(100% - 40px);
           left: 50%;
           transform: translateX(-50%);
           height: 1px;
           background-color: #F3F4F6;
           bottom: 60px;
       }
    }
}
.qnt-box-mobile {
    @media (max-width:600px) {
        position: absolute;
        right: 20px;
        bottom: 10px;
        .qnt-box {
            margin-right: 0!important;
        }
    }
}
.cart-remove-mobile {
    @media (max-width:600px) {
        position: absolute;
        left: 16px;
        bottom: 10px;
        .btn  {
            background-color: #f1f3f6;
        }
    }
}
.product-info-mobile {
    // margin: 0 !important;
}
//.cart-footer {
//    @media (max-width:767px) {
//       display: flex;
//       flex-direction: column;
//       align-items: flex-start!important;
//       .cart_btn {
//           width: 100%;
//           a {
//               width: 100%;
//               margin: 0 24px!important;
//               button {
//                width: 100%;
//               }
//           }
//       }
//    }
//}
.row {
    &.after-0 {
        &::after {
            display: none;
        }
    }
}