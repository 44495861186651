.swiper-container {
    &.nav-tabs {
       overflow: visible;
    }
}
.tab-link {
    position: relative;
    cursor: pointer;
    &::after {
        content: '';
        position: absolute;
        width: 16px;
        height: 5px;
        background-color:#fec900;
        border-radius: 3px;
        bottom: -6px;
        left: 0;
        transform: translateX(20px);
        transition: .3s ease-in-out;
        opacity: 0;

    }
    &.active {
        color: #272a37 !important;
        &::after {
            transform: translateX(0);
            opacity: 1;
        }
    }

}

// table
.border-table {
    border: 1px solid #E4E7ED;
}
.check {
    width: 14px;
    height: 14px;
}
.custom-checkbox-2  {
    .custom-control-label  {
        &::before {
            border-radius: 4PX;
            border: 2px solid #E4E7ED;
        }
        &::after {
            border: none;
        }
    }
  
    .custom-control-input:checked ~ .custom-control-label {
        &::before {
            color: #ffffff;
            border-color: #fec900;
            background-color: #fec900;
        }
    }
 
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    color: transparent;
    background-color: transparent;
    border-radius: 4PX;
    border: 2px solid #E4E7ED;
}
.custom-control-input:focus~.custom-control-label::before {
    border-color: #fec900!important;
    box-shadow: none!important;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #E4E7ED!important;  
}
.product-info {
    .product-img {
        width: 64px;
        height: 64px;
        flex-shrink: 0;
        background-color: #ECEDEF;
    }
}
.table-row  {
    border-bottom: solid 1px #F3F4F6;
}
.table-list {
    thead {
        th {
          span {
              display: flex;
              margin-top: 16px;
              font-weight: normal;
          }
        }
    }
}
.qnt-box {
    width: 88px;
    .minus {
        background-color: #f7f9fc;
        height: 24px;
    }
    .plus {
        height: 24px;
        background-color: #f7f9fc;
       
    }
    button{
        &:disabled {
            opacity: 0.5;
        }
    }
}
.prom-qnt-btn {
    background-color: #f7f9fc !important; 
    &:hover {
       background-color: #f4f4f7 !important;
    }
}
.installment {
    width: 70px;
}
.remove-btn {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    transition: .2s ease-in-out;
    background-color: rgba($color: #f1f3f6, $alpha: .3);
    &:hover {
        background-color: rgba($color: #f1f3f6, $alpha: 1);
       
    }
}
//td max width 

.shops-col {
    width: 100%;
    max-width: 85px;
}
.table-actions-dropdown {
    min-width: 226px;
}
.group-actions-btn  {
    .tooltip-listing {
        position: absolute;
        box-shadow: 0 3px 6px 0 rgba(39, 42, 55, 0.25);
        border-radius: 16px;
        top: -40px;
        pointer-events: none;
        opacity: 0;
        transform: translateY(10px);
        transition: .2s ease-in-out;
        .tooltip-arrow {
            position: absolute;
            bottom: -21px;
            left: 50%;
            transform: translateX(-50%);
            width: 34px;
            height: 28px;
            background-size: cover;
          
        }
    }
    &:hover {
        .tooltip-listing {
            transform: translateY(0);
            pointer-events: all;
            opacity: 1;
        } 
    }
}
.my-groups {
    .table-list {
        .w-title {
            padding-right: 19%;
        }
    }
 
}
.mygroup-info {
    .item-img {
        width: 48px;
        height: 48px;
    }
    @media (max-width:767px) {
        max-width: 200px!important;
        flex: 0 0 200px!important;
    }
    .pr-title {
        @media (max-width:767px) {
            max-width: 140px!important;
            flex: 0 0 140px!important;
        }
    }
}
.t-head {
    border-bottom: 1px solid #F3F4F6;
  
}
.dropdown-group  {
    height: 52px;
    background-color: #ffffff;

}
.dropdown-group-item {
    background-color: #F3F4F6;
    border-top: 1px solid #E4E7ED;
    border-bottom: 1px solid #E4E7ED;
}
.group-actions {
    right: 24px;
    
}
.parent-group  {

.dropdown-group-item {
    display: none;
}
.groups-item {
    transform: translateY(100%);
    opacity: 0;
    transition: .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    &:nth-of-type(1) {
        transition-delay: .1s;
    }
    &:nth-of-type(2) {
        transition-delay: .2s;
    }
    &:nth-of-type(3) {
        transition-delay: .3s;
    }
    &:nth-of-type(4) {
        transition-delay: .4s;
    }
    &:nth-of-type(5) {
        transition-delay: .5s;
    }
}
.arr {
    transition: .3s ease-out;
}
    &.active {
        .dropdown-group-item {
            display: flex;
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin: 0 auto;
           
        }
        .arr {
            transform: rotateX(180deg);
        }
        .groups-item {
            transform: translateY(0);
            opacity: 1;
        }
    }
}
.add-group {
    &.btn {
        &::after {
            display: none;
        }
    }
}

.groups-modal {
    width: 100%;
    max-width: 840px;
}
.group-name  {
    width: 100%;
    max-width: 380px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.gr-line {
    height: 1px;
    background-color: #F3F4F6;
}
.product-filter-icon {
    position: absolute;
    top: 60px;
    right: 16px;
}
.product-group-card {
   margin-bottom: 12px;
    img {
        width: 100%;
        height: 128px;
        object-fit: contain;
        border: 1px solid #E4E7ED;
        border-radius: 12px;
    }
    .product-group-title {
        margin-top: 12px;
        transition: .3s ease-in-out;
    }
}
.selected-product-section  {
    .product-group-card {
        .group-img  {
            position: relative;
            overflow: hidden;
            border-radius: 12px;
            border: 2px solid transparent;
            transition: .3s ease-in-out;
            img {
                transition: .3s ease-in-out;
            }
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                border-radius: 12px;
                background-color: rgba($color: #272A37, $alpha: .3);
                opacity: 0;
                z-index: 1;
                transition: .3s ease-in-out;
            }
        }
        .delete-product {
            z-index: 1;
            svg {
                path {
                    stroke: #D7DCE4;
                    transition: .3s ease-in-out;
                }
                
            }
        }
        &:hover {
            .delete-product {
                svg {
                    path {
                        stroke: #FF665E;
                        transition: .3s ease-in-out;
                    }
                    
                }
            }
            .group-img  {
                border-color: #FF665E;
                &::after {
                    opacity: 1;
                }
                img {
                    transform: scale(1.1);
                }
            }  
        }
    }

}
.product-section {
    overflow-y: auto;
    max-height: 520px;
    margin-bottom: 32px;
        .product-group-card {
            .group-img  {
                position: relative;
                overflow: hidden;
                border-radius: 12px;
                border: 2px solid transparent;
                transition: .3s ease-in-out;
                img {
                  
                    transition: .3s ease-in-out;
                }
                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    border-radius: 12px;
                    background-color: rgba($color: #272A37, $alpha: .3);
                    opacity: 0;
                    z-index: 1;
                    transition: .3s ease-in-out;
                }
            }
      
            svg {
                // transform: scale(0);
                transition: .3s ease-in-out;
                path {
                    transition: .3s ease-in-out;
                    stroke: #B0B9C9;
                  
                }
                
            }
            &:hover {
                .group-img {
                    border-color: #FECB09;
                }
                img {
                    
                    transform: scale(1.1);
                }
          
    
          
                svg {
                    transform: scale(1);
                    path {
                        stroke: #FECB09;
                      
                    }
                    
                }
            }
        &.active {
            .group-img {
                border-color: #FECB09;
            }
            img {
                
                transform: scale(1.1);
            }
        
    
        
            svg {
                transform: scale(1);
                path {
                    stroke: #FECB09;
                  
                }
                
            }
        }
        &.hidden {
            .info-tooltip {
                position: absolute;
                width: 212px;
                z-index: 1000;
                top: 0;
                left: 50%;
                background-color: #4A6CFA;
                border-radius: 10px;
                opacity: 0;
                transition: .3s ease-in-out;
                transform: scale(.95);
            }
            &:hover {
                .group-img {
                    border-color: transparent;
                }
                img {
                 
                   transform: scale(1.1);
                }
          
                .product-group-title {
                    color: #B0B9C9;
                }
                .add-product {
                    opacity: 1;
                }
                svg {
                    transform: scale(1);
                    path {
                        stroke: #B0B9C9;
                    }
                    
                }
                .group-img {
                    &::after {
                      opacity: 1;
                    }
                }
                .info-tooltip {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }
    }
}
.group-actions-btn {
    transition: .3s ease-in-out;
    &:hover {
        background-color: #ffffff;
    }
}
.create-group-conatiner {
    .delete-product {
        position: absolute;
        top: -15px;
        right: 0;
        width: 32px;
        height: 32px;
        background-color: #ffffff;
        -webkit-box-shadow: 0px 2px 6px 0px rgba(39,42,55,0.1)!important;
        -moz-box-shadow: 0px 2px 6px 0px rgba(39,42,55,0.1)!important;
        box-shadow: 0px 2px 6px 0px rgba(39,42,55,0.1)!important;
    }
    .add-product {
        position: absolute;
        top: 15px;
        right: 0;
        width: 32px;
        height: 32px;
        z-index: 2;
        background-color: #ffffff;
        -webkit-box-shadow: 0px 2px 6px 0px rgba(39,42,55,0.1)!important;
        -moz-box-shadow: 0px 2px 6px 0px rgba(39,42,55,0.1)!important;
        box-shadow: 0px 2px 6px 0px rgba(39,42,55,0.1)!important; 
        transition: .3s ease-in-out;
    }
}
.product-section {
    .product-group-card {
        &.hidden {
            &:nth-child(even)  {
                .info-tooltip {
                    left: -50%;
                }
            }
        }
    }
}
// my-products-box
.my-products-box {
    .item-number {
        min-width: 80px;
    }
    .item-views {
        min-width: 60px;
       
    }
    // .btn {
    //     transition: .3s ease-in-out;
    //     svg {
    //         width: 14px;
    //         height: 14px;
    //         path {
    //             transition: .1s ease-in-out;
    //         }
    //     }
    // }
    .buy-btn  {
        &:hover:not(.inactive) {
            background-color: #f5ecca;
            svg {
                path {
                    fill: #fec900;
                    stroke: #fec900;
                }
            }
        }
    }
    
    .buy-color-btn {
        &:hover:not(.inactive) {
            background-color: #ebfaed;
            svg {
                path {
                    fill: #4a6cfa;
                    
                }
            }
        }
    
    }

    .update-btn {
        &:hover:not(.inactive) {
            background-color: #eaeefe;
            svg {
                path {
                    fill: #0ec604;
                }
            }
        }
    }
}
// th-sort 
th  {
  &.th-sort  {
    span {
        position: relative;
        padding-right: 24px;
        cursor: pointer;
        &:after {
            position: absolute;
            content: '';
            right: 0;
            top: 20%;
            display: block;
            width: 7px;
            height: 12px;
            background-image: url(../../../img/icons/sort-i.svg);
            background-repeat: no-repeat;
            background-position: center center;
        }
    }
    &.sorting-up {
       span {
           &::after {
            background-image: url(../../../img/icons/sort-up-i.svg);
           }
       }
    }
    &.sorting-down {
      span {
          &::after {
            background-image: url(../../../img/icons/sort-down-i.svg);
          }
      }
    }
    }
}

.pr-prom-action {
    .dropdown-item {
        @media (min-width:1024px) {
            &:hover:not(.slpr-delete){
                color: $blue !important;
                
            }
            a {
                &:hover {
                    color: $blue !important;
                }
            }
        }
    }
}

// 
.flex-mobile-1  {
    @media (max-width:800px) {
        width:100%;
    }
}